import { date_format } from '../global_constants';
import { currency_comparator, mm_dd_yyyy_comparator, number_comparator, text_comparator } from '../shared/component/grid/comparators';
import { get_header_template_with_navigation } from '../shared/component/grid/constants';
import { custom_date_format, is_null_or_white_space, strip } from '../shared/utility';
import { failed_charge_rule } from '../admin/constants/constants';
import { get_data } from '../admin/constants/action/constants_action';
import { reprocess_failed_charge_rule, save_billing_audit } from './action/report_action';

export interface ActionResponse {
    data: any,
    status: number,
    message: string,
    refresh: boolean
}
export interface AdditionalAction {
    id: string,
    type: string,
    on_click(token?: string, selected_items?: Array<Object>): Promise<ActionResponse>,
    disabled: boolean,
    content: string,
    sub_report_id: number,
    sequence: number,
    validate_items_selected: boolean,
    validate_items_selected_msg?: string,
    confirmation_alert?: string
}
export interface IRequestBodyForBillingAudit {
    reportId: string,
    reportEntityTypeId: number,
    reportEventActionId: number,
    reportDataEntry: any
}
export interface IPayloadForBillingAudit {
    reportId: string,
    contextTitle: string,
    eventActionId: ReportEventAction,
    userId: number,
    companyId: number,
    entityTypeId: ReportEntityTypeId,
    data?: any
}
// Custom formatting and styling for grid header 
export const headerTemplate = {
    template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

const headerCurrencyTemplate = {
    template:
        '<div class="ag-cell-label-container column-right" role="presentation">' +
        '<i class="small filter icon filter-ipad-amount filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};
export const headerDragTemplate = (field, name) => {
    return {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label" role="presentation" draggable="true" data-field="' + field + '" data-name="' + name + '">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    }
};
export const get_header_checkbox_template = (field) => {
    return {
        template:
            '<div class="ag-cell-label-container checkbox" role="presentation">' +
            '<span class="ag-header-select-all grid-checkbox" role="presentation" ref="cbSelectAll">' +
            '<input type="checkbox" id="' + field + '">' +
            '<span class="ag-checkbox-checked ag-hidden" role="presentation" id="checked_' + field + '">' +
            '<span class="ag-icon ag-icon-checkbox-checked"></span>' +
            '</span>' +
            '<span class="ag-checkbox-unchecked" role="presentation" id="unchecked_' + field + '">' +
            '<span class="ag-icon ag-icon-checkbox-unchecked"></span>' +
            '</span>' +
            '<span class="ag-checkbox-indeterminate ag-hidden" role="presentation" id="indeterminate_' + field + '">' +
            '<span class="ag-icon ag-icon-checkbox-indeterminate"></span>' +
            '</span>' +
            '</span>' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    }
}
/**
 * LINK_ROUTE_EDIT_PAGE used call page route.
 * Used to route report link drill-down edit page navigation 
 */
export const LINK_ROUTE_EDIT_PAGE = [
    '/patient',
    '/charge/procedure_code',
    '/admin/referring_physician',
    '/admin/insurance',
    '/admin/provider',
    '/admin/locations'
];

/**
 * AdminConstant used to call company info API 
 * Used to route report link drill-down edit page navigation
 */
export enum AdminConstant {
    insurance = 0,
    referring_physician = 1,
    procedure_code = 2,
    location_code = 3,
    provider_code = 4,
}

export enum ReportEventAction {
    Export = 3,
    Print = 9,
    Update = 6
};

export enum ReportEntityTypeId {
    D_Patient = 1,
    A_User = 2,
    D_Case = 3,
    D_Charge = 4,
    EOB_RELEASE = 5,
    ClaimProcessingRule = 6,
    ClaimProcessingResult = 7,
    ClaimProcessingProcedureCode_Not_Used = 8,
    ClaimProcessingCriteriaSourceDetail = 9,
    a_rpt_ver = 10,
    UpdatedCharges = 11,
    ChargeAudit = 12,
    D_CLAIM = 13,
    A_COMPANY = 14,
    D_PAYMENT_ADJUSTMENT = 15,
    C_INSURANCE_CODE = 16,
    C_INSURANCE_CODE_CONTRACT = 17,
    C_INSURANCE_CLASS = 18,
    C_PLACE_OF_SERVICE = 19,
    C_Medical_Group = 20,
    C_PRACTICE = 21,
    bat_task = 22,
    C_CONTRACT = 23,
    C_DIAGNOSIS_CODE = 24,
    E_ROLE = 25,
    E_PS = 26,
    E_MENU_MAPPING = 27,
    IntegrationPayment = 28,
    PaymentAudit = 29,
    PatientLetter = 30,
    PatientLetterCompanyMapping = 31,
    C_USER_PAYER_ALERTS = 32,
    C_PROVIDER = 33,
    claim_override = 34,
    C_REFERRING_PHYSICIAN = 35,
    c_special_program = 36,
    C_REFERRING_SOURCE = 37,
    C_REFERRING_SOURCE_TYPE = 38,
    PAYMENT = 39,
    C_PROCEDURE_MODIFIER = 40,
    EOB_CLAIMADJCODE_PAYER_OV = 41,
    C_PROCEDURE_CODE = 42,
    C_REVENUE_CENTER = 43,
    C_PAYMENT_CODE = 44,
    C_TRANSACTION_MESSAGE_CODE = 45,
    fmt_run = 46,
    fmt_sch2 = 47,
    A_provider_qualifier = 48,
    fmt_run_comp = 49,
    fmt_rule_reason = 50,
    EOB_ENHANCED_PAYER_RULE = 66,
    Error_835_Exclusions = 835
}

// Billing exception value 'detail_for_reason' used to identify drill-down last page grid.
export const billing_exception_detail_for_reason = 'detailforreason';

//Report API URLs
export const end_urls = {
    ledger_full_url: '/reports/ledger_full_report_data',
    ledger_patient_payment_adjustments_url: '/reports/ledger_payment_adjustment',
    ledger_charge_url: '/reports/ledger_charge',
    ledger_claim_url: '/reports/ledger_claim',
    demand_report_full_url: '/reports/demand_statement',
    report_metadata: '/reports/report_metadata',
    report_metadata_next_level: '/reports/report_metadata_next_level',
    get_updated_dropdown_data: '/reports/updated_dropdown_data',
    get_report_data: '/reports/report_data',
    get_report_data_post: '/reports/report_data_post',
    print_demand_report_full_url: '/reports/demand_statement_print',
    print_attorney_report_full_url: '/reports/attorney_statement_print',
    charge_audit_summary_url: '/reports/charge_audit_summary',
    close_eob_url: '/reports/close_eob',
    close_unrecognized_eob_url: '/reports/close_unrecognized_eob',
    update_unrecognized_eob_url: '/reports/update_unrecognized_eob',
    get_release_claims_references: '/reports/release_claims_references',
    zero_payments: '/zero_payment',
    payment_adjustment_summery: '/reports/payment_adjustment_summery',
    payment_adjustment_summery_by_pos: '/reports/payment_adjustment_summery_by_pos',
    summary_and_forecast: '/reports/summary_and_forecast',
    error_835_exclusions: '/reports/835_error_exclusions',
    patient_statement: '/patient_statement/patientStatementData'
};

//To Do, need to move this in utility
export const formatNumber = function (number) {
    // this puts commas into the number eg 1000 goes to 1,000,
    //return Math.floor(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // Change for B-23856 R6: Updates to Patient Payment Log
    // return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
    number = /^\d+?\.\d+?$/.test(number) ? parseFloat(number).toFixed(2) : number;
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

// Currency formatter for grid cell
//To Do, need to move this in utility
export const currencyFormatter = function (value) {
    if(is_null_or_white_space(value)) {
        value = 0;
    }

    return value < 0 ? '($' + formatNumber((parseFloat(value) * -1).toFixed(2)) + ')' : '$' + formatNumber(parseFloat(value).toFixed(2));
};

//Extract amount value
export const currency_value = (value) => {
    if (value.indexOf('(') !== -1) {
        return Number(-value.replace(/[^0-9.-]+/g, ""));
    }
    return Number(value.replace(/[^0-9.-]+/g, ""));
}

// Date formatter for grid cell
//To Do, need to move this in utility
export const dateFormatter = function (value) {
    return custom_date_format(value, date_format['mm/dd/yyyy']);
};
export const dateTimeFormatter = function (value) {
    return custom_date_format(value, date_format["mm/dd/yyyy h:MM:ss TT"]);
};

// report validation and info messages.
export const messages = {
    mandatory_fields: 'Please fill all the mandatory fields or check highlighted field values.',
    date_validation: '"Through Date" must be on or after the "From Date".',
    no_records_found: 'No Record Available',
    date_rage_validation: 'Date range cannot exceed 31 days.',
    remove_confirm: 'All selected lines will be permanently removed.',
    remove_error: 'Please select a record to remove.',
    reprocess_error: 'Please select a record to reprocess.',
    select_record_error: 'Please select at least one record.',
    numeric_allow: 'Only Numeric characters are allowed.'
};

export const const_column_width = 65;
export const const_data_width = 50;
export const financial_reports = [132, 131, 407, 30100, 30290];
export const report_totals_height = 30;
export const report_has_hidden_column = {
    '131': {
        columns: ['patient name'],
        hide: true
    },
    '30380': {
        columns: ['charge id param', 'fail id param', 'reason long param'],
        hide: true
    }
};

export const report_has_download_pdf_column = {
    '30266': {
        'Error Count': {
            header: 'Error Count',
            check_ff: false,
            auto_print: false,
            downloadPdf: true,
            custom_print: false
        },
        'Print EOB': {
            header: 'Print EOB',
            check_ff: false,
            auto_print: false,
            downloadPdf: true,
            custom_print: false
        }
    },
    '30290': {      
        'Preview': {
            header: 'Preview',
            check_ff: false,
            auto_print: false,
            downloadPdf: true,
            custom_print: false
        }
    },
    '30280': {
        'Print EOB': {
            header: 'Print EOB',
            check_ff: false,
            auto_print: false,
            downloadPdf: true,
            custom_print: false
        }
    },
    '30355': {
        'Print EOB': {
            header: 'Print EOB',
            check_ff: false,
            auto_print: false,
            downloadPdf: true,
            custom_print: false
        }
    },
    '30253': {
        'Check/EFT Number': {
            header: 'Check/EFT Number',
            check_ff: true,
            auto_print: false,
            downloadPdf: true,
            custom_print: false,
            features: 'rsiB30996LedgerVisitCheckEftNumber835ViewModal'
        }
    },
    '30380': {
        'Charge Record ID': {
            header: 'Charge Record ID',
            check_ff: false,
            auto_print: true,
            downloadPdf: false,
            custom_print: false,
            is_print_disabled: true,
            is_export_disabled: true
        }
    }
};

// Add report constant for Patient Statement Log
export const patient_statement_log = 30290;

// Add report constant for EOB & Attorney Statement Reports
export const eob_report = 30124;
export const eob_error_report = 30384;
export const attorney_statement = 30125;
export const manually_posted_eob = 30105;
export const failed_charges_reason = 30381;
export const patient_statement = 30290;
export const patient_credit_report = 30123;
export const unrecognized_eob = {
    updates_ff_on: 30300,
    updates_ff_off: 30259,
    empty_error: "Please select at least one record to process.",
};

//Provider Summary Report
export const provider_summary_report = {
    new: 30600,
    old: 508
};

//Failed Charges Report 30380
export const failed_charges_report_id_30380 = 30380;
export const failed_Charges_summary_report_id_30382 = 30382;
export const providerCredentialingListReportId = 503;
export const includeRowTotalReportIds: number[] = [providerCredentialingListReportId, failed_charges_report_id_30380, failed_Charges_summary_report_id_30382];
//Failed Charges Report
export const failed_charges_report_id_30286 = 30286;
export const failed_charges_name = 'Failed Charges';

// report constant for reducer and action for
export const CONSTANTS = {
    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
    REQUEST_FAILURE: 'REQUEST_FAILURE',
    COMPANY_CHANGE: 'COMPANY_CHANGE'
};

export const report_charges_grid_header_id = "report_charges_grid_header_id";

// Ledger Charge grid configuration
export const report_charges_configuration = {
    title: 'Charges',
    id: 'report_charges_id',
    column_defs: [
        {
            field: 'cpt',
            headerName: 'CPT Code',
            headerComponentParams: get_header_template_with_navigation(report_charges_grid_header_id),
            filter: 'textFilter',
            width: 130,
            minWidth: 130,
            type: 'string',
            unSortIcon: true
        },
        {
            field: 'mod1',
            headerName: 'Mod 1',
            headerComponentParams: headerTemplate,
            filter: 'textFilter',
            width: 95,
            minWidth: 95,
            type: 'string',
            unSortIcon: true
        },
        {
            field: 'mod2',
            headerName: 'Mod 2',
            filter: 'textFilter',
            width: 95,
            minWidth: 95,
            headerComponentParams: headerTemplate,
            type: 'string',
            unSortIcon: true
        },
        {
            field: 'mod3',
            headerName: 'Mod 3',
            filter: 'textFilter',
            width: 95,
            minWidth: 95,
            headerComponentParams: headerTemplate,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'description',
            headerName: 'Description',
            filter: 'textFilter',
            width: 130,
            minWidth: 130,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'insurance_name',
            headerName: 'Insurance',
            filter: 'textFilter',
            width: 130,
            minWidth: 130,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'units',
            headerName: 'Units',
            filter: 'textFilter',
            width: 95,
            minWidth: 95,
            type: 'textFilter',
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'expected_rate_amount',
            headerName: 'Expected Rate Amount',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            width: 120,
            minWidth: 120,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            allowBlank: true,
            unSortIcon: true,
            hide: false
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'charge_amount',
            headerName: 'Charge Amount',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            width: 120,
            minWidth: 120,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'patient_pay_amount',
            headerName: 'Patient Payments',
            width: 130,
            minWidth: 130,
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'insurance_pay_amount',
            headerName: 'Insurance Payments',
            width: 130,
            minWidth: 130,
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'insurance_adjustment_amount',
            headerName: 'Insurance Adjustments',
            width: 150,
            minWidth: 150,
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'balance',
            headerName: 'Balance',
            width: 120,
            minWidth: 120,
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'status',
            headerName: 'Billing Status',
            width: 110,
            minWidth: 110,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx1',
            headerName: 'Dx1',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx2',
            headerName: 'Dx2',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx3',
            headerName: 'Dx3',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx4',
            headerName: 'Dx4',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx5',
            headerName: 'Dx5',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx6',
            headerName: 'Dx6',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx7',
            headerName: 'Dx7',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'dx8',
            headerName: 'Dx8',
            filter: 'textFilter',
            width: 90,
            minWidth: 90,
            type: 'string',
            unSortIcon: true
        },
    ]
};

export const report_payment_adjustment_header_id = "report_payment_adjustment_header_id";

// Ledger Payment Adjustment report grid configuration
export const report_payment_adjustment_configuration = {
    title: 'Payments and Adjustments',
    id: 'report_payment_adjustment_id',
    column_defs: [
        {
            headerComponentParams: get_header_template_with_navigation(report_payment_adjustment_header_id),
            field: 'payment_code',
            headerName: 'Payment Code',
            filter: 'textFilter',
            width: 170,
            minWidth: 170,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'pay_amt',
            headerName: 'Payment Amount',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            width: 150,
            minWidth: 150,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'adj_code',
            headerName: 'Adjustment Code',
            filter: 'textFilter',
            width: 150,
            minWidth: 150,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'adj_amt',
            headerName: 'Adjustment Amount',
            width: 150,
            minWidth: 150,
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'deposit_date',
            headerName: 'Deposit Date',
            width: 120,
            minWidth: 120,
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerComponentParams: headerTemplate,
            field: 'ins_code',
            headerName: 'Insurance Code',
            filter: 'textFilter',
            width: 135,
            minWidth: 135,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: "xfer_amt",
            headerName: "Transfer Amount",
            filter: "currencyFilter",
            width: 130,
            minWidth: 130,
            type: "currency",
            cellStyle: { textAlign: "right" },
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'xfer_ins_code',
            headerName: 'Transfer to Insurance Code',
            filter: 'textFilter',
            width: 180,
            minWidth: 180,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'check_num',
            headerName: 'Check Number',
            filter: 'textFilter',
            width: 120,
            minWidth: 120,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'reason',
            headerName: 'CARC',
            filter: 'textFilter',
            width: 110,
            minWidth: 110,
            type: 'string',
            unSortIcon: true,
            cellRenderer: 'PopUpLinkRenderer',
            cellRendererParams: {
                is_tooltip: true,
                value_type: 'text',
                tooltip_feild: 'reason_description'
            }
        },
        {
            headerComponentParams: headerTemplate,
            field: 'posted_date',
            headerName: 'Date Posted',
            width: 150,
            minWidth: 150,
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        }
    ]
};

export const report_claims_header_id = "report_claims_header_id";
// Ledger Claims grid configuration
export const report_claims_configuration = {
    title: 'Claims',
    id: 'report_claims_id',
    column_defs: [
        //{
        //    headerComponentParams: get_header_template_with_navigation(report_claims_header_id),
        //    field: 'dos',
        //    headerName: 'Date of Service',
        //    minWidth: 250,
        //    filter: 'dateFilter',
        //    type: 'date',
        //    unSortIcon: true,
        //    comparator: mm_dd_yyyy_comparator
        //},
        {
            headerComponentParams: headerTemplate,
            field: 'claim_date',
            headerName: 'Claim Date',
            width: 100,
            minWidth: 100,
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerComponentParams: headerTemplate,
            field: 'ins_name',
            headerName: 'Insurance',
            filter: 'textFilter',
            width: 130,
            minWidth: 130,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'ins_code',
            headerName: 'Insurance Code',
            filter: 'textFilter',
            width: 135,
            minWidth: 135,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'cpt',
            headerName: 'CPT Code',
            filter: 'textFilter',
            width: 130,
            minWidth: 130,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'units',
            headerName: 'Units',
            filter: 'textFilter',
            width: 95,
            minWidth: 95,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            field: 'billed_amt',
            headerName: 'Billed Amount',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            width: 130,
            minWidth: 130,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'run_num',
            headerName: 'Run Number',
            filter: 'textFilter',
            width: 150,
            minWidth: 150,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'format_type',
            headerName: 'Format Type',
            filter: 'textFilter',
            width: 150,
            minWidth: 150,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'ins_type',
            headerName: 'Insurance Type',
            filter: 'textFilter',
            width: 130,
            minWidth: 130,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'claim_code',
            headerName: 'Claim Code',
            filter: 'textFilter',
            width: 145,
            minWidth: 145,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'claim_action',
            headerName: 'Claim Action',
            filter: 'textFilter',
            width: 160,
            minWidth: 160,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: 'late_reason',
            headerName: 'Late Reason',
            filter: 'textFilter',
            width: 155,
            minWidth: 155,
            type: 'string',
            unSortIcon: true
        },
        {
            headerComponentParams: headerTemplate,
            field: "format_num",
            headerName: "Format Number",
            filter: "textFilter",
            width: 180,
            minWidth: 180,
            type: "string",
            unSortIcon: true
        }
    ]
};

// Ledger Full Configuration Header ID
export const report_ledger_full_configuration_header_id = "report_ledger_full_configuration_header_id";

// Ledger Full report grid configuration
export const report_ledger_full_configuration = {
    title: 'Ledger Full',
    patient_report_grid_id: 'patient_report_grid_id',
    column_defs: [
        {
            headerName: 'Date of Service',
            headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
            field: 'dos',
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator,
            cellClass: 'href_link'
        },
        {
            headerName: 'Location',
            headerComponentParams: headerTemplate,
            field: 'pos',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Place of Service Identifier',
            headerComponentParams: headerTemplate,
            field: 'place_of_service_identifier',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Provider',
            headerComponentParams: headerTemplate,
            field: 'provider_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'CPT Code',
            headerComponentParams: headerTemplate,
            field: 'cpt',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            tooltipField: 'description'
        },
        {
            headerName: 'Mod 1',
            headerComponentParams: headerTemplate,
            field: 'mod1',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Mod 2',
            headerComponentParams: headerTemplate,
            field: 'mod2',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Mod 3',
            headerComponentParams: headerTemplate,
            field: 'mod3',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Insurance',
            headerComponentParams: headerTemplate,
            field: 'ins_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },

        {
            headerName: 'PreAuth',
            headerComponentParams: headerTemplate,
            field: 'pre_auth',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Dx1',
            headerComponentParams: headerTemplate,
            field: 'dx1',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Units',
            headerComponentParams: headerTemplate,
            field: 'units',
            filter: 'numberFilter',
            comparator: number_comparator,
            type: 'number',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Expected Rate Amount',
            headerComponentParams: headerTemplate,
            field: 'expected_rate_amount',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            allowBlank: true,
            unSortIcon: true,
            hide: false
        },
        {
            headerName: 'Charge Amount',
            headerComponentParams: headerTemplate,
            field: 'chrg_amt',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Patient Payments',
            headerComponentParams: headerTemplate,
            field: 'pat_pay_amt',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Patient Balance',
            headerComponentParams: headerTemplate,
            field: 'pat_balance',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Balance',
            headerComponentParams: headerTemplate,
            field: 'balance',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Insurance Payments',
            headerComponentParams: headerTemplate,
            field: 'ins_pay_amt',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Insurance Adjustments',
            headerComponentParams: headerTemplate,
            field: 'ins_adj_amt',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Insurance Balance',
            headerComponentParams: headerTemplate,
            field: 'ins_balance',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Primary Payments',
            headerComponentParams: headerTemplate,
            field: 'pri_pay',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Secondary Payments',
            headerComponentParams: headerTemplate,
            field: 'sec_pay',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Tertiary Payments',
            headerComponentParams: headerTemplate,
            field: 'ter_pay',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },
        {
            headerName: 'Charge ID',
            headerComponentParams: headerTemplate,
            field: 'charge_id',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Case ID',
            headerComponentParams: headerTemplate,
            field: 'case_id',
            filter: 'numberFilter',
            comparator: number_comparator,
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Status',
            headerComponentParams: headerTemplate,
            field: 'is_active',
            filter: 'textFilter',
            type: 'boolean',
            unSortIcon: true
        },
        // {
        //     headerName: 'Comment',
        //     headerComponentParams: headerTemplate,
        //     field: 'comment',
        //     filter: 'textFilter',
        //     minWidth: 130,
        //     type: 'string',
        //     unSortIcon: true
        // },
        //{
        //    headerName: 'Description',
        //    headerComponentParams: headerTemplate,
        //    field: 'description',
        //    filter: 'textFilter',
        //    minWidth: 160,
        //    type: 'string',
        //    unSortIcon: true
        //},
    ]
};

export const report_demand_statement_payment_log_header_id = "report_demand_statement_payment_log_header_id";
export const report_demand_statement_payment_log = {
    title: 'Patient Payment Log',
    grid_id: 'demand_statement_report_id_log',
    column_defs: [
        {
            headerName: 'Deposit Date',
            headerComponentParams: get_header_template_with_navigation(report_demand_statement_payment_log_header_id),
            field: 'deposit_date',
            ///filter: "textFilter",
            type: 'date',
            minWidth: 150,
            suppressFilter: true,
            suppressSorting: true,
            //unSortIcon: true,
            // cellClass: "cell-wrap-text"
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: 'Payment Type',
            headerComponentParams: headerTemplate,
            field: 'payment_type',
            //filter: "textFilter",
            minWidth: 160,
            type: 'string',
            suppressFilter: true,
            suppressSorting: true
            //unSortIcon: true,
            // cellClass: "cell-wrap-text"
        },
        {
            headerName: 'Payment Amount',
            headerComponentParams: headerCurrencyTemplate,
            field: 'payment_amount',
            //filter: "textFilter",
            type: 'currency',
            minWidth: 180,
            suppressFilter: true,
            suppressSorting: true,
            //unSortIcon: true,
            // cellClass: "demand_statement_payment_log_cell",
            cellStyle: { textAlign: 'right' }
        }
    ]
};

export const report_demand_statement_open_charges_header_id = "report_demand_statement_open_charges_header_id";
// demand Statement report grid configuration
export const report_demand_statement_open_charges = {
    title: 'Patient Open Charges',
    grid_id: 'demand_statement_report_id',
    grid_header_height: 45,
    column_defs: [
        {
            headerName: 'DOS',
            headerComponentParams: get_header_template_with_navigation(report_demand_statement_open_charges_header_id),
            field: 'dos',
            //filter: "textFilter",
            minWidth: 120,
            suppressSorting: true,
            suppressFilter: true,
            //unSortIcon: true,
            type: 'date',
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: 'Prov Name',
            headerComponentParams: headerTemplate,
            field: 'provider_name',
            //filter: "textFilter",
            minWidth: 140,
            suppressFilter: true,
            suppressSorting: true,
            //unSortIcon: true,
            type: 'string'
        },
        {
            headerName: 'CPT',
            headerComponentParams: headerTemplate,
            field: 'cpt',
            //filter: "textFilter",
            minWidth: 100,
            suppressFilter: true,
            suppressSorting: true,
            type: 'string'
            //unSortIcon: true,
        },
        {
            headerName: 'Description ',
            headerComponentParams: headerTemplate,
            field: 'description',
            // filter: "textFilter",
            minWidth: 140,
            suppressFilter: true,
            suppressSorting: true,
            cellClass: 'cell-wrap-text',
            type: 'string'
            //unSortIcon: true
        },
        {
            headerName: 'Ins Name',
            headerComponentParams: headerTemplate,
            field: 'insurance_name',
            //filter: "textFilter",
            minWidth: 130,
            suppressFilter: true,
            suppressSorting: true,
            type: 'string'
            //unSortIcon: true
        },
        {
            headerName: 'Chrg Amt',
            headerComponentParams: headerCurrencyTemplate,
            field: 'charge_amount',
            //filter: "textFilter",
            minWidth: 130,
            suppressFilter: true,
            suppressSorting: true,
            type: 'currency',
            cellStyle: { textAlign: 'right' }
            //unSortIcon: true
        },
        {
            headerName: 'Ins Pay Amt',
            headerComponentParams: headerCurrencyTemplate,
            field: 'insurance_pay_amount',
            //filter: "textFilter",
            minWidth: 150,
            suppressFilter: true,
            suppressSorting: true,
            type: 'currency',
            cellStyle: { textAlign: 'right' }
            //unSortIcon: true
        },
        {
            headerName: 'Ins Adj Amt',
            headerComponentParams: headerCurrencyTemplate,
            field: 'insurance_adjustment_amount',
            //filter: "textFilter",
            minWidth: 150,
            type: 'currency',
            suppressFilter: true,
            suppressSorting: true,
            cellStyle: { textAlign: 'right' }
            ///unSortIcon: true
        },
        {
            headerName: 'Pat Pay Amt',
            headerComponentParams: headerCurrencyTemplate,
            field: 'patient_pay_amount',
            //filter: "textFilter",
            minWidth: 150,
            suppressFilter: true,
            suppressSorting: true,
            type: 'currency',
            cellStyle: { textAlign: 'right' }
            //unSortIcon: true
        },
        {
            headerName: 'Ins Balance',
            headerComponentParams: headerCurrencyTemplate,
            field: 'insurance_balance',
            //filter: "textFilter",
            minWidth: 150,
            type: 'currency',
            suppressFilter: true,
            suppressSorting: true,
            cellStyle: { textAlign: 'right' }
            ////unSortIcon: true
        },
        {
            headerName: 'Pat Balance',
            headerComponentParams: headerCurrencyTemplate,
            field: 'patient_balance',
            minWidth: 150,
            suppressFilter: true,
            suppressSorting: true,
            type: 'currency',
            cellStyle: { textAlign: 'right' }
            //unSortIcon: true
        }
    ]
};

export const report_manually_posted_eob = {
    title: 'Manually Posted EOBs',
    report_grid_id: 'grid_manually_posted_eob',

    column_defs: [
        {
            headerName: '',
            // headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
            field: 'group',
            type: 'string',
            unSortIcon: true,
            maxWidth: 40,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            resizable: false,
            cellRendererParams: {
                innerRenderer: params => {
                    if (!params.node.group && !params.node.rowPinned && params.data) {
                        let eDiv = document.createElement('div');
                        eDiv.innerHTML = charge_details_tamplate(params.data, params.column.actualWidth);
                        return eDiv.firstChild;
                    }
                    // else{
                    //     return params.data.gpms_code;
                    // }
                }
            },
            colSpan: function (params) {
                if (!params.node.group && !params.node.rowPinned) {
                    return params.columnApi.getAllColumns().length;
                }
                return 1;
            },
            cellRenderer: 'agGroupCellRenderer',
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        },
        {
            headerName: 'Clinic Code',
            headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
            field: 'cliniccode',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'EOB Key',
            headerComponentParams: headerTemplate,
            field: 'eob_key',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Check Eft Num',
            headerComponentParams: headerTemplate,
            field: 'check_eft_num',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'EOB Date',
            headerComponentParams: headerTemplate,
            field: 'eob_date',
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: 'Check Amount',
            headerComponentParams: headerTemplate,
            field: 'check_amt',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true
        },

    ]
};


export const report_charge_audit_summary = {
    title: 'Charge Audit Summary',
    report_grid_id: 'accordian_grid_charge_audit_summary',
    child_column: [
        {
            headerName: 'CPT Code',
            field: 'proc_code',
            width: 5,
            align: 'left'
        },
        {
            headerName: 'Units',
            field: 'units',
            width: 5,
            align: 'right'
        },
        {

            headerName: 'Mod 1',
            field: 'proc_mod1',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Mod 2',
            field: 'proc_mod2',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Charge Amount',
            field: 'charge_amount',
            width: 5,
            align: 'right'
        },
        {
            headerName: 'Billing Provider',
            field: 'billing_provider',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Treating Provider',
            field: 'treating_provider',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Location',
            field: 'proc_code',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Primary Insurance',
            field: 'pri_insurance',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Secondary Insurance',
            field: 'sec_insurance',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Tertiary Insurance',
            field: 'ter_insurance',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Dx1',
            field: 'dx1',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Dx2',
            field: 'dx2',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Dx3',
            field: 'dx3',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Dx4',
            field: 'dx4',
            width: 5,
            align: 'left'
        },
        {

            headerName: 'Status',
            field: 'is_active',
            width: 5,
            align: 'left'
        }
    ],

    column_defs: [
        {
            headerName: '',
            field: 'group',
            type: 'string',
            unSortIcon: true,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            suppressMovable: false,
            colSpan: (params) => {
                if ((!params.node.group && !params.node.rowPinned || (params.data && params.data.group && params.data.group.length > 0))) {
                    return params.columnApi.getAllColumns().length;
                }
                return 1;
            },
            cellRendererSelector: (params) => {
                if (params && params.data && params.data.participants && params.data.participants.length == 0) {
                    return null;
                }
                if (params && params.node.group) {
                    return {
                        component: 'agGroupCellRenderer',
                    };
                }
                if (params && params.data && params.data.child_column) {
                    return {
                        component: 'InnerGridTableRendererComponent',
                        params: {
                            data: params.data,
                            grouped_column_width: params.column.actualWidth
                        }
                    };
                }
            },
            cellRendererParams: {
                suppressCount: true, // turn off the row count
                innerRenderer: function (params) {
                    if (params.data.group.length > 0) {
                        var eDiv = document.createElement('div');
                        eDiv.innerHTML =`<span>${params.data.group}</span>`;
                        return eDiv.firstChild;
                    }
                }
            },
            cellStyle: (params) => {
                if (params && params.data && params.data.child_column) {
                    return { 'padding-left': 0, 'padding-right': 0 };
                }
            },
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        },
        {
            headerName: 'Company',
            headerComponentParams: headerDragTemplate('gpms_code', 'Company'),
            field: 'gpms_code',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Patient ID',
            headerComponentParams: headerDragTemplate('patient_id', 'Patient ID'),
            field: 'patient_id',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Patient',
            headerComponentParams: headerDragTemplate('patient_name', 'Patient'),
            field: 'patient_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Date of Service',
            headerComponentParams: headerDragTemplate('date_of_service', 'Date of Service'),
            field: 'date_of_service',
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: 'Posting Date',
            headerComponentParams: headerDragTemplate('posting_date', 'Posting Date'),
            field: 'posting_date',
            filter: 'dateFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: 'Charge Amount',
            headerComponentParams: headerDragTemplate('charge_amount', 'Charge Amount'),
            field: 'charge_amount',
            filter: 'currencyFilter',
            comparator: currency_comparator,
            type: 'currency',
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            unSortIcon: true
        },
        {
            headerName: 'Units',
            headerComponentParams: headerDragTemplate('units', 'Units'),
            field: 'units',
            filter: 'numberFilter',
            comparator: number_comparator,
            type: 'number',
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            unSortIcon: true
        },
        {
            headerName: 'Charges',
            headerComponentParams: headerDragTemplate('charges', 'Charges'),
            field: 'charges',
            filter: 'numberFilter',
            comparator: number_comparator,
            type: 'number',
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            unSortIcon: true
        },
        {
            headerName: 'Visits',
            headerComponentParams: headerDragTemplate('visit_count', 'Visits'),
            field: 'visit_count',
            filter: 'numberFilter',
            comparator: number_comparator,
            type: 'number',
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            unSortIcon: true
        },
        {
            headerName: 'Location',
            headerComponentParams: headerDragTemplate('pos_name', 'Location'),
            field: 'pos_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'User Type',
            headerComponentParams: headerDragTemplate('user_type', 'User Type'),
            field: 'user_type',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'User',
            headerComponentParams: headerDragTemplate('user', 'User'),
            field: 'user',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        }
    ]
};

const charge_details_tamplate = (data, grouped_column_width) => {
    return `<div class="grid_inner_table">
            <div class="table-responsive custom-default-table" style="min-width: 1200px;">
                <table class="ui table">
                    <thead>
                        <tr id="inner_table">
                            ${charge_render_header(data, grouped_column_width)}
                        </tr>
                    </thead>
                    <tbody>
                        ${charge_render_rows(data)}
                    </tbody>
                </table>
            </div>
        </div>`;
};

export const charge_render_header = (data, grouped_column_width) => {
    let nodes = `<th style="min-width:${grouped_column_width}px"></th>`;
    var total_len = data.child_column.length;
    data.child_column.map((item, index) => {
        if (total_len - 1 == index) {
            nodes = `
        ${nodes}
        <th style="min-width:${100}px;text-align:${item.align};">${item.headerName}</th>
        `;
        } else {
            nodes = `
        ${nodes}
        <th style="max-width:${item.width}px;min-width:${item.width}px;width:${item.width}px;text-align:${item.align};">${item.headerName}</th>
        `;
        }
    });
    return nodes;
};

export const charge_render_rows = data => {
    let nodes = '';
    data.details &&
        data.details.map(value => {
            nodes = `
            ${nodes}
            <tr>
                <td></td>
                <td>${value.proc_code}</td>
                <td style="text-align: right">${value.units}</td>
                <td>${value.proc_mod1}</td>
                <td>${value.proc_mod2}</td>
                <td style="text-align: right">${value.charge_amount}</td>
                <td>${value.billing_provider}</td>
                <td>${value.treating_provider}</td>
                <td>${data.pos_name}</td>
                <td>${value.pri_insurance}</td>
                <td>${value.sec_insurance}</td>
                <td>${value.ter_insurance}</td>
                <td>${value.dx1}</td>
                <td>${value.dx2}</td>
                <td>${value.dx3}</td>
                <td>${value.dx4}</td>
                <td class="high" style="text-align: left">${value.is_active}</td>           
            </tr>`;
        });
    return nodes;
};

export enum enum_drop_down_type {
    company = "Companyid",
    practice = "PracticeId",
    location = "POSIdList",
    provider = "ProviderList",
    delayed_reason = "DelayedReason"
}

export const inner_grid_tamplate = (data: { inner_cols: any[]; details: any[]; }, grouped_column_width: any) => {
    return `<div class="grid_inner_table">
            <div class="table-responsive custom-default-table" style="min-width: 1200px;">
                <table class="ui table">
                    <thead>
                      <tr id="inner_table">
                        ${inner_grid_header(data, grouped_column_width)}
                      </tr>
                    </thead>
                    <tbody>
                      ${inner_grid_rows(data, grouped_column_width)}
                    </tbody>
                </table>
            </div>
          </div>`;
};

export const inner_grid_header = (data: { inner_cols: any[] }, grouped_column_width: any) => {
    let nodes = `<th style="min-width:${grouped_column_width}px"></th>`;
    let total_len = data.inner_cols.length;
    data.inner_cols.map((item: { field_name: string; header: any; }, index: any) => {
        const align =
            item.field_name.toLowerCase().indexOf("decimal") > -1 || item.field_name.toLowerCase().indexOf("int") > -1
                ? "right"
                : "left";
        nodes = `
        ${nodes}
        <th style="text-align:${align};">${item.header}</th>
        `;
    });
    return nodes;
};

export const inner_grid_rows = (data: { inner_cols: any[]; details: any[] }, grouped_column_width: any) => {
    let tr_nodes = "";
    data.details.map((value) => {
        tr_nodes = `
            ${tr_nodes}
            <tr>
               ${inner_grid_column(value, data.inner_cols, grouped_column_width)}
            </tr>`;
    });
    return tr_nodes;
};

export const inner_grid_column = (row: { [x: string]: any }, column: any[], grouped_column_width: any) => {
    let nodes = `<td style="min-width:${grouped_column_width}px"></td>`;
    column.map((item) => {
        let value = row[item.field_name];
        const column_type =
            item.header == "Payment ID"
                ? "string"
                : item.is_hyper_link
                    ? "hyper_link"
                    : item.field_name.toLowerCase().indexOf("per") > -1
                        ? "percentage"
                        : item.field_name.toLowerCase().indexOf("decimal") > -1
                            ? "currency"
                            : item.field_name.toLowerCase().indexOf("int") > -1
                                ? "number"
                                : item.field_name.toLowerCase().indexOf("string") > -1
                                    ? "string"
                                    : item.field_name.toLowerCase().indexOf("datetimeoffset") > -1
                                        ? "datetimeoffset"
                                        : item.field_name.toLowerCase().indexOf("datetime") > -1
                                            ? "datetime"
                                            : item.field_name.toLowerCase().indexOf("date") > -1
                                                ? "date"
                                                : "string";

        switch (column_type) {
            case "date":
            case "datetime":
                value = value != null || value != undefined ? dateFormatter(value) : "";
                break;
            case "datetimeoffset":
                value = value != null || value != undefined ? dateTimeFormatter(value) : "";
                break;
            case "currency":
                value = value != null || value != undefined ? currencyFormatter(value) : "$0.00";
                break;
            case "percentage":
                value = value != null || value != undefined ? (parseFloat(value) * 100).toFixed(2) + "%" : "0%";
                break;
            case "number":
                value = value != null || value != undefined ? formatNumber(value) : 0;
                break;
            default:
                value = value != null || value != undefined ? value.toString().trim() : "";
        }

        const align =
            item.field_name.toLowerCase().indexOf("decimal") > -1 || item.field_name.toLowerCase().indexOf("int") > -1
                ? "right"
                : "left";
        nodes = `
        ${nodes}
        <td style="text-align:${align};">${value}</td>
        `;
    });
    return nodes;
};

export const open_zero_notes_input = () => {
    return `
    <div class="ui form field">
       <input class="txt_open_zero_notes" maxLength="250" autocomplete="new-password" />
    </div>`;
};

export const error_messages = {
    select_single_eob: 'Please select at least one record to close.',
    closed_succesfully: 'Selected records were closed.'
}

export const open_zero_payments_setting_columns = [
    {
        headerComponentParams: get_header_template_with_navigation("open_zero_information_id"),
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: "checkbox",
        width: 40,
        maxWidth: 40,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        cellStyle: { marginLeft: '8px' }
    },
    {
        headerName: "Notes",
        headerComponentParams: headerTemplate,
        minWidth: 250,
        field: "notes",
        width: 250,
        type: 'string',
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
            if (!params.node.rowPinned) {
                let eDiv = document.createElement("div");
                eDiv.innerHTML = `${open_zero_notes_input()}`;
                let input_box = eDiv.querySelector("input");
                if (input_box) {
                    input_box.value = params.node.data['notes'];
                    input_box.addEventListener("blur", (event: any) => {
                        params.node.setDataValue("notes", event.target.value);
                    });
                }
                return eDiv;
            } else {
                return params.value
            }
        }
    },
    {
        headerName: 'Patient ID',
        headerComponentParams: headerTemplate,
        field: 'patient_id',
        filter: 'numberFilter',
        type: 'number',
        unSortIcon: true,
        cellClass: "href_link",
        minWidth: 120,
        width: 120,
    },
    {
        headerName: "Charge Number",
        headerComponentParams: headerTemplate,
        field: "chgno",
        minWidth: 120,
        width: 120,
        filter: "textFilter",
        unSortIcon: true,
        type: 'string',
        comparator: text_comparator
    },
    {
        headerName: "First Name",
        headerComponentParams: headerTemplate,
        field: "patfname",
        minWidth: 140,
        width: 140,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Last Name",
        headerComponentParams: headerTemplate,
        field: "patlname",
        filter: "textFilter",
        type: 'string',
        minWidth: 140,
        width: 140,
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "DOS",
        headerComponentParams: headerTemplate,
        field: "dos",
        filter: "dateFilter",
        minWidth: 140,
        width: 140,
        type: "date",
        unSortIcon: true,
        comparator: mm_dd_yyyy_comparator,
    },
    {
        headerName: "CPT Code",
        headerComponentParams: headerTemplate,
        field: "cpt",
        filter: "textFilter",
        minWidth: 120,
        width: 120,
        type: 'string',
        unSortIcon: true,
        comparator: number_comparator
    },
    {
        headerName: "Units",
        headerComponentParams: headerTemplate,
        field: "units",
        filter: "numberFilter",
        type: 'string',
        minWidth: 100,
        width: 100,
        unSortIcon: true,
        comparator: number_comparator
    },
    {
        headerName: "Billed Amount",
        headerComponentParams: headerCurrencyTemplate,
        field: 'billed_amt',
        filter: 'currencyFilter',
        comparator: currency_comparator,
        width: 150,
        minWidth: 150,
        type: 'currency',
        cellStyle: { textAlign: 'right' },
        unSortIcon: true,
    },
    {
        headerName: "Allowed Amount",
        headerComponentParams: headerCurrencyTemplate,
        field: 'allow_amt',
        filter: 'currencyFilter',
        comparator: currency_comparator,
        width: 150,
        minWidth: 150,
        type: 'currency',
        cellStyle: { textAlign: 'right' },
        unSortIcon: true,
    },
    {
        headerName: "Payment Amount",
        headerComponentParams: headerCurrencyTemplate,
        field: 'pay_amt',
        filter: 'currencyFilter',
        comparator: currency_comparator,
        width: 150,
        minWidth: 150,
        type: 'currency',
        cellStyle: { textAlign: 'right' },
        unSortIcon: true,
    },
    {
        headerName: 'Adjustment Amount',
        headerComponentParams: headerCurrencyTemplate,
        field: 'adj_amt',
        filter: 'currencyFilter',
        comparator: currency_comparator,
        width: 150,
        minWidth: 150,
        type: 'currency',
        cellStyle: { textAlign: 'right' },
        unSortIcon: true,
    },
    {
        headerName: "Qualifier",
        headerComponentParams: headerTemplate,
        field: "adj_qualifier",
        minWidth: 140,
        width: 140,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Adjustment Code",
        headerComponentParams: headerTemplate,
        field: "adj_code",
        minWidth: 150,
        width: 150,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Reason Code",
        headerComponentParams: headerTemplate,
        field: "hrc",
        filter: "textFilter",
        type: 'string',
        minWidth: 160,
        width: 160,
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Deduct Reason",
        headerComponentParams: headerTemplate,
        field: "deduct_reason",
        minWidth: 100,
        width: 100,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Group ID",
        headerComponentParams: headerTemplate,
        field: "group_id",
        minWidth: 100,
        width: 100,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Sequence",
        headerComponentParams: headerTemplate,
        field: "sequence",
        minWidth: 140,
        width: 140,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    }
];

export const open_zero_payments__remards_setting_columns = [
    {
        headerName: "Code",
        headerComponentParams: get_header_template_with_navigation("open_zero_remarks_id"),
        field: "code",
        minWidth: 50,
        width: 50,
        type: 'string',
        unSortIcon: true,
        filter: "textFilter",
        comparator: text_comparator,
        cellStyle: { paddingLeft: '15px' }
    },
    {
        headerName: "Description",
        headerComponentParams: headerTemplate,
        field: "description",
        minWidth: 200,
        width: 200,
        filter: "textFilter",
        unSortIcon: true,
        type: 'string',
        comparator: text_comparator
    }
];

export const get_header_section_for_failed_charge_drill_down = async (token: any, rulereasonid: number, company_name: string): Promise<{ header: string, title: string }> => {
    let url = failed_charge_rule.api.get.url(rulereasonid);
    let response = await get_data(token, url);
    let headerSection = { header: '', title: '' };
    if (response.data && response.data.data) {
        headerSection.header = `Failed Charges: ${response.data.data.reason_code} - ${response.data.data.reason_short_description}<br>`;
        headerSection.title = encodeURIComponent(`${company_name} <br> ${response.data.data.reason_long_description}<br>`);
    }
    return headerSection;
}

export const reprocess_failed_charge_rule_items = async (token, selected_items = []): Promise<ActionResponse> => {
    let request_body = selected_items.map(item => ({ Charge_id: item.int32Col0, Rule_failed_id: item.int32Col1 }))
    let { data: { data, messages, status } } = await reprocess_failed_charge_rule(request_body, token)
    let response: ActionResponse = { data, status, message: '', refresh: true };
    if (status) response.refresh = true;
    return response
}

export const additionalActions: Array<AdditionalAction> = [
    {
        id: "failed_charges_reprocess_btn",
        type: "submit",
        on_click: (token, selected_items) => { return reprocess_failed_charge_rule_items(token, selected_items) },
        disabled: false,
        content: "Reprocess",
        sub_report_id: failed_charges_report_id_30286,
        sequence: 1,
        validate_items_selected: true,
        validate_items_selected_msg: 'Please select at least one record to Reprocess.'
	},
	{
		id: "failed_charges_reprocess_btn",
		type: "submit",
		on_click: (token, selected_items) => { return reprocess_failed_charge_rule_items(token, selected_items) },
		disabled: false,
		content: "Reprocess",
		sub_report_id: failed_charges_report_id_30380,
		sequence: 1,
		validate_items_selected: true,
		validate_items_selected_msg: 'Please select at least one record to Reprocess.'
	}
];

export const get_additional_actions_with_report_id = (report_id: number): Array<AdditionalAction> => {
	let addActions = []
	//Filter actions depends by each report's logic
	if (report_id === failed_charges_report_id_30286 || report_id === failed_charges_report_id_30380) {
		addActions = additionalActions.filter((action) => action.sub_report_id === report_id).sort((a, b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
	}
	return addActions;
}

export const get_additional_actions = (report_id: number, sub_report_id: number): Array<AdditionalAction> => {
    let addActions = []
    //Filter actions depends by each report's logic
    if (sub_report_id === failed_charges_report_id_30286 || sub_report_id === failed_charges_report_id_30380) {
        addActions = additionalActions.filter((action) => action.sub_report_id === sub_report_id).sort((a, b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
    }
    return addActions;
}


export const payment_adjustment_summary_configuration = {
    title: "Payment/Adjustment Summary",
    pos_title: "Payment Adjustment Breakdown by Location",
    payment_summery_grid: "payment_summery_grid",
    adjustment_summery_grid: "adjustment_summery_grid",
    payment_summery_header_id: "payment_summery_header_id",
    adjustment_summery_header_id: "adjustment_summery_header_id",
    payment_summery_column_defs: [
        {
            headerName: "Payment Code",
            headerComponentParams: get_header_template_with_navigation('payment_summery_header_id'),
            field: "payment_code",
            unSortIcon: true,
            type: "hyper_link",
            filter: "linkFilter",
            comparator: (link1, link2) => text_comparator(strip(link1), strip(link2)),
            cellRenderer: "LinkRendererComponent",
            cellRendererParams: {
                r4_to_r6_routes: [
                    {
                        has_parameter: false,
                        parameters: null,
                        r4_route: "ReportSelection.aspx",
                        r6_route: "/report/sub_report_data?param"
                    }
                ],
                is_print_disabled: true,
                is_export_disabled: true
            }
        },
        {
            headerName: "Payment Description",
            headerComponentParams: headerTemplate,
            field: "payment_description",
            type: "string",
            filter: "textFilter",
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            headerName: 'Payments',
            field: 'payments',
            filter: 'currencyFilter',
            width: 120,
            minWidth: 120,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true,
            comparator: currency_comparator,
        }
    ],

    adjustment_summery_column_defs: [
        {
            headerName: "Adjustment Code",
            headerComponentParams: get_header_template_with_navigation('adjustment_summery_header_id'),
            field: "adjustment_code",
            unSortIcon: true,
            type: "hyper_link",
            filter: "linkFilter",
            comparator: (link1, link2) => text_comparator(strip(link1), strip(link2)),
            cellRenderer: "LinkRendererComponent",
            cellRendererParams: {
                r4_to_r6_routes: [
                    {
                        has_parameter: false,
                        parameters: null,
                        r4_route: "ReportSelection.aspx",
                        r6_route: "/report/sub_report_data?param"
                    }
                ],
                is_print_disabled: true,
                is_export_disabled: true
            }
        },
        {
            headerName: "Adjustment Description",
            headerComponentParams: headerTemplate,
            field: "adjustment_description",
            type: "string",
            filter: "textFilter",
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerComponentParams: headerCurrencyTemplate,
            headerName: 'Adjustments',
            field: 'adjustments',
            filter: 'currencyFilter',
            width: 120,
            minWidth: 120,
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            unSortIcon: true,
            comparator: currency_comparator,
        }
    ],
};

// #region Summary and Forecast report
export const summary_and_forecast_constants = {
    title: 'Summary and Forecast',
    component_path: 'report/summary_and_forecast_report',
    session_key: 'summary_and_forecast_search_criteria',
    search_field: {
        company: 'company',
        month: 'month',
        year: 'year'
    },
    look_back_years: 10,
    default_month: 1
}
// #endregion Summary and Forecast report

export const summary_forecast_clinic_stats_configuration = {
    title: "Clinic Summary Statistics",
    new_patients_label: "New Patients",
    actual_collections_pct_label: "Actual Collections %",
    visits_label: "Visits",
    est_payments_label: "Estimated Payments",
    charges_label: "Charges",
    est_payments_per_visit_label: "Estimated Payments/Visits",
    charges_per_visit_label: "Charges/Visits"
};

export const summary_forecast_calculate_configuration = {
    title: "Forecast",
    est_payments_label: "Estimated Payments",
    est_pay_per_visit_label: "Estimated Payments/Visits",
    total_expenses_label: "Total Expenses",
    expense_per_visit_label: "Expenses/Visits",
    total_p_l_label: "Total P(L)",
    est_p_l_per_visit_label: "Estimated P(L)/Visits"
};

export const display_credit_card_card_type = ["CC"]

export enum advance_search_enum {
    patient = 'patientID',
    insurance_class = 'InsuranceClassID',
    insurance = 'InsuranceID'
};

export const reports_advance_control_config = (id_for_navigation = 'test') => {
    return {
        patientID: {
            quick_search_url: '/patients/quick_search?',
            advanced_search_url: '/patients/search',
            controlId: 'PATIENT',
            search_type: 'PATIENT_REPORT',
            column_defs: [
                {
                    headerName: 'First Name',
                    field: 'first_name',
                    filter: 'textFilter',
                    minWidth: 175,
                    headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                    unSortIcon: true
                },
                {
                    headerName: 'Last Name',
                    field: 'last_name',
                    filter: 'textFilter',
                    headerComponentParams: headerTemplate,
                    minWidth: 175,
                    unSortIcon: true
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    filter: 'textFilter',
                    headerComponentParams: headerTemplate,
                    minWidth: 150,
                    unSortIcon: true
                },
                {
                    headerName: 'Date of Birth',
                    headerComponentParams: headerTemplate,
                    field: 'date_of_birth',
                    filter: 'dateFilter',
                    minWidth: 200,
                    unSortIcon: true
                }
            ]
        },
        InsuranceClassID: {
            quick_search_url: '/insurance_class/quick_search',
            advanced_search_url: '/insurance_class/search',
            controlId: 'INSURANCE_CLASS',
            search_type: 'INSURANCE_CLASS_REPORT',
            column_defs: [
                {
                    headerName: 'Ins Class Code',
                    field: 'insurance_class_code',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 175,
                    unSortIcon: true,
                    headerComponentParams: get_header_template_with_navigation(id_for_navigation)
                },
                {
                    headerName: 'Ins Class Desc',
                    field: 'insurance_class_description',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 175,
                    unSortIcon: true,
                    headerComponentParams: headerTemplate
                }
            ]
        },
        InsuranceID: {
            quick_search_url: '/subscriber/insurance/code/quick_search',
            advanced_search_url: '/subscriber/insurance/search',
            controlId: 'SEARCH_INSURANCE',
            search_type: 'SEARCH_INSURANCE_REPORT',
            column_defs: [
                {
                    headerName: 'Ins Code',
                    field: 'insurance_code',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 130,
                    headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                    unSortIcon: true
                },
                {
                    headerName: 'Insurance Name',
                    field: 'insurance_name',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 195,
                    headerComponentParams: headerTemplate,
                    unSortIcon: true
                },
                {
                    headerName: 'Address 1',
                    field: 'address1',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 155,
                    headerComponentParams: headerTemplate,
                    unSortIcon: true
                },
                {
                    headerName: 'City',
                    field: 'city',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 100,
                    headerComponentParams: headerTemplate,
                    unSortIcon: true
                },
                {
                    headerName: 'Phone',
                    field: 'phone_customer',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 120,
                    headerComponentParams: headerTemplate,
                    unSortIcon: true
                },
                {
                    headerName: 'Insurance Class',
                    field: 'insurance_class',
                    filter: 'textFilter',
                    comparator: text_comparator,
                    minWidth: 190,
                    headerComponentParams: headerTemplate,
                    unSortIcon: true
                }
            ]
        }
    };
};

export const row_selection_format = (selected_row, item, control_data) => {
    control_data[item.sequence] = selected_row.id;
    selected_row = formate_data(selected_row, item.control_name);
    return { selected_row, control_data };
};

export const prepare_quick_search_suggestions = (search_data, quick_search_type) => {
    const formattedList = [];
    let data = search_data.data;
    let data_length = data.length;
    if (data_length > 0) {
        for (let i = 0; i < data_length; i++) {
            const item = data[i];
            formattedList.push(formate_data(item, quick_search_type));
        }
    }
    return formattedList;
};

export const formate_data = (item, quick_search_type) => {
    switch (quick_search_type) {
        case advance_search_enum.insurance:
            const label =
                (item.insurance_code || '') +
                ' - ' +
                (item.insurance_name || '') +
                ', ' +
                (item.address1 || '') +
                ', ' +
                (item.city || '') +
                ', ' +
                (item.state || '') +
                ', ' +
                (item.zip || '');
            return {
                address1: item.address1,
                city: item.city,
                state: item.state,
                zip: item.zip,
                insurance_name: item.insurance_name,
                //
                title: `${item.id}`,
                name: item.id,
                label,
                insurance_code: item.insurance_code,
                insurance_class_code: item.insurance_class_code,
                balance_type: item.balance_type,
                insurance_type: item.insurance_type,
                insurance_class: item.insurance_class,
                id: item.id
            };
        case advance_search_enum.insurance_class:
            return {
                insurance_class_code: item.insurance_class_code,
                insurance_class_description: item.insurance_class_description,
                is_active: item.is_active,
                //
                title: `${item.id}`,
                code: item.insurance_class_code,
                description: item.insurance_class_description,
                label:
                    item.insurance_class_code +
                    ' - ' +
                    item.insurance_class_description +
                    ' ' +
                    (item.is_active ? '' : '(I)'),
                id: item.id
            };
        default:
            return {
                last_name: item.last_name,
                middle_initial: item.middle_initial,
                first_name: item.first_name,
                //
                label:
                    (item.last_name == null ? '' : item.last_name + ' ') +
                    (item.middle_initial == null ? '' : item.middle_initial + ' ') +
                    (item.first_name == null ? '' : item.first_name),
                title: item.id.toString(),
                id: item.id
            };
    }
};

export const report_allow_pagination = (report_id) => {

    const reportList = [30376, 30375];

    return reportList.indexOf(report_id) > -1;

}


export const error_excusion_835__configuration = {
    id: "exclusion_report_grid",
    header: "Exclusion Report",
    report_grid_id: 'accordian_grid_835_error_exclusions',
    grid_header_height: 35,
    api: {
        get: {
            type: 'GET',
            url: '/payments/835_error_exclusions'
        }
    },
    child_column: [
        {
            headerName: 'Payer Code',
            field: 'payer_code',
            //width: 5,
            type: 'string',
            align: 'left',
        },
        {
            headerName: 'Description',
            field: 'description',
            type: 'string',
            //width: 5,
            align: 'left',
        },
        {

            headerName: 'Outbound ID',
            field: 'outbound_id',
            type: 'string',
            //width: 5,
            align: 'left',
        }
    ],
    column_defs: [
        //{
        //    headerName: '',
        //    field: 'group',
        //    unSortIcon: true,
        //    maxWidth: 40,
        //    width: 40,
        //    suppressSorting: true,
        //    suppressFilter: true,
        //    cellRendererParams: {
        //        innerRenderer: params => {
        //            if (!params.node.group && !params.node.rowPinned && params.data) {
        //                let eDiv = document.createElement('div');
        //                eDiv.innerHTML = error_details_tamplate(params.data, params.column.actualWidth);
        //                return eDiv.firstChild;
        //            }
        //        }
        //    },
        //    colSpan: function (params) {
        //        if (!params.node.group && !params.node.rowPinned) {
        //            return params.columnApi.getAllColumns().length;
        //        }
        //        return 1;
        //    },
        //    cellRenderer: 'agGroupCellRenderer',
        //    valueGetter: function chainValueGetter(params) {
        //        return '';
        //    }
        //},
        {
            headerName: '',
            field: 'angle_icon',
        },
        {
            headerName: 'Error Description',
            //headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
            textAlign: 'left',
            field: 'error_description',
            type: 'string',
            filter: 'textFilter',
            unSortIcon: true
        },
        {
            headerName: 'Exclusions',
            //headerComponentParams: headerTemplate,
            textAlign: 'left',
            field: 'exclusions',
            type: 'number',
            filter: 'textFilter',
            unSortIcon: true
        },
        {
            headerName: 'Last Updated By',
            //headerComponentParams: headerTemplate,
            textAlign: 'left',
            field: 'last_updated_by',
            type: 'string',
            filter: 'textFilter',
            unSortIcon: true,
        },
        {
            headerName: 'Last Updated Date',
            //headerComponentParams: headerTemplate,
            textAlign: 'left',
            field: 'last_updated_date',
            type: 'date',
            filter: 'dateFilter',
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        }
    ]

};

const error_details_tamplate = (data, grouped_column_width) => {
    return `<div class="grid_inner_table">
            <div class="table-responsive custom-default-table" style="min-width: 1200px;">
                <table class="ui table">
                    <thead>
                        <tr id="inner_table">
                            ${error_render_header(data, grouped_column_width)}
                        </tr>
                    </thead>
                    <tbody>
                        ${error_render_rows(data)}
                    </tbody>
                </table>
            </div>
        </div>`;
};

export const error_render_header = (data, grouped_column_width) => {
    let nodes = `<th style="min-width:${grouped_column_width}px"></th>`;
    var total_len = data.child_column.length;
    data.child_column.map((item, index) => {
        if (total_len - 1 == index) {
            nodes = `
        ${nodes}
        <th style="min-width:${100}px;text-align:${item.align};">${item.headerName}</th>
        `;
        } else {
            nodes = `
        ${nodes}
        <th style="max-width:${item.width}px;min-width:${item.width}px;width:${item.width}px;text-align:${item.align};">${item.headerName}</th>
        `;
        }
    });
    return nodes;
};

export const error_render_rows = data => {
    let nodes = '';
    data.payers &&
        data.payers.map(value => {
            nodes = `
            ${nodes}
            <tr>
                <td></td>
                <td>${value.payer_code}</td>
                <td>${value.description}</td>
                <td>${value.outbound_id}</td>         
            </tr>`;
        });
    return nodes;
};

export const saveAuditPrintExport = (reqBody: IRequestBodyForBillingAudit, access_token: any) => {
    save_billing_audit(reqBody, access_token);
};

export interface PatientStatementReport {
    patientId: number,
    versionNumber: string,
    statementHeader: {
        company: {
            name: string,
            address: string,
            city: string,
            state: string,
            zip: string
        },
        invoice: {
            invoiceDate: string,
            accountNumber: string,
            balanceDue: number
        }
    },
    billingAddresses: {
        patient: {
            name: string,
            address: string,
            city: string,
            state: string,
            zip: string
        },
        company: {
            name: string,
            address: string,
            city: string,
            state: string,
            zip: string
        }
    },
    patientCharges: [
        {
            dateOfService: string,
            provider: string,
            locationOfService: string,
            chargeAmount: number,
            adjustmentAmount: number,
            patientBalance: number
        }
    ],
    patientPaymentLogs: [
        {
            depositDate: string;
            paymentType: string;
            paymentAmount: number;
        }
    ],
    balances: {
        totalBalance: number,
        claimsInProcess: number,
        payThisAmount: number
    },
    statementFooter: {
        onlinePaymentUrl: string,
        companyPhoneNumber: string,
        statementDate: string
    }
}