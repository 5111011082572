import { currency_comparator, number_comparator, text_comparator, mm_dd_yyyy_comparator } from './../shared/component/grid/comparators';
import { headerCurrencyTemplate } from './../admin/constants/constants';
import { HEADER_TEMPLATE_FIRST_COLUMN, get_header_template_with_navigation } from '../shared/component/grid/constants';
import { header_template_with_navigation } from '../admin/constants/constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { addClass, currency_formatter, custom_date_format, removeClass } from '../shared/utility';
import { cell_checkbox_renderr, update_header_checkbox_status } from '../billing/utility';
import { get_header_checkbox_template } from '../reports/report_constants';
import { date_format } from '../global_constants';
export enum enum_type_of_search {
    ins_code_enum = 'ins_code',
    ins_class_enum = 'ins_class',
    procedure_code = 'procedure_code',
    patient_id = 'patient_id'
}
export enum enum_page_name{
    charge = 'charge',
    audit = 'audit',
    review = 'review'

}
const headerCenterTemplate = {
    template:
        '<div class="ag-cell-label-container column-center" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const amount_headerTemplate = {
    template: '<div class="ag-cell-label-container amt-text-right" role="presentation">' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<i class="small filter icon filter-ipad-amount filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const header_template = {
    template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const headerNoFilterIconTemplate = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '</div>' +
        '</div>'
};

const headerNoFilterIconTemplateWithNavigation = (id) => {
    return {
        template: '<div class="ag-cell-label-container" role="presentation">' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label" role="presentation" id="' + id + '" tabindex="0">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '</div>' +
            '</div>'
    }

};

export const charge_messages = {
    added_successfully: 'Charges Saved.',
    charges_deleted: 'Charges Deleted.',
    save_confirmation: 'The Date of Service is not less than 90 days after the Plan of Care Date.Are you sure you want to continue?',
    no_thru_date: 'NO THROUGH DATE',
    no_from_date: 'NO FROM DATE',
    no_visit: 'NO VISIT RESTRICTION',
    month_close:'Month already closed, Please use a current date.',
    req_atleast_1dx: 'All charges must have at least 1 diagnosis.',
    req_atleast_1dx_for_charge: 'Charge must have at least 1 diagnosis.',
    functional_reporting: 'Functional reporting is required on or before the 10th visit.',
    dos_out_of_range: 'The Date of Service must be between the from and through dates of the Pre-Authorization.',
    future_dos: 'Date of Service cannot be in the future.',
    required_all_fields: 'Please fill all the mandatory fields or check highlighted field values.',
    no_primary_insurance: 'The case selected does not have a Primary Insurance.',
    md_code_req: 'The case selected does not have a Referring Physician.',
    invalid_dx_sequence: ' is not a valid diagnosis sequence. You cannot select the same diagnosis more than once.',
    invalid_dx_no: ' is not a valid diagnosis sequence number.',
    no_charges_to_save: 'There are no charges to save.',
    delete_charge: "This will cause the charge to be deleted.  Are you sure you want to delete this charge?",
    delete_charge_confirm: "Are you sure you want to delete selected charge(s)?",
    no_pending_reason: 'You must select a pend reason for each pended visit.',
    charge_status_warning: 'This visit includes charges that have previously been billed and may require a corrected claim. Please visit the Claim Indicator page to generate a corrected claim if required. Pressing Save will release the visit.',
    charge_status_warning_title: 'Corrected Claim Required?',
    select_note_code: 'Please Select a note code.',
    unit_req: 'All charges must have at least 1 unit.',
    kx_modifier_warning1: 'Based on the estimated Medicare allowable amount of charges entered this calendar year, the patient may have met the therapy cap. Do you want to apply the KX modifier?',
    kx_modifier_warning2: 'Adding KX Modifiers would cause existing Modifier 2 fields to be replaced.  Do you still want to add the KX Modifiers?',
   cp_insurance_check: 'This charge is assigned to a cash patient case. Do you want to count it as a visit?',
    from_date: 'From Date is required.',
    no_valid_auth_unit: 'The Authorization selected is not valid. There are no available units left on the Authorization.',
    added_diagnosis: 'Diagnosis saved successfully.',
    req_for_billing_dx: 'At least one diagnosis code is required',
    //info messages
    select_single_charge: 'Please select a single charge to edit.',
    select_single_or_all_charge: 'Please select a single charge to edit or select all charges to edit a visit.',
    no_charge_selected: 'Please select a charge.',
    no_charge_exists_for_edit_visit: 'There are no available charges on the visit to edit.',
    no_audit_selected: 'You have not made a selection that would cause charges to be released.',
    merge_charge_data: 'The number of units on charges has been changed by the system. Please verify units and click on Save.',
    merge_charge_data_with_proc_type_mismatch: 'Procedure Code replacements have been made and the Procedure Code Types do not match. The number of units on charges has been changed by the system. Please verify units and click on Save.',
    proc_type_mismatch: 'Procedure Code replacements have been made and the Procedure Code Types do not match. Please verify units and click on Save.',
    mandatory_fields: "You must complete all required fields.",
    payment_added: 'Credit(s) have been saved successfully.',
    release_claim_added: 'Charges have been successfully saved',
    no_release_claim_case_available: 'No Case Available',
    charge_released: 'Charges have been released.',
    show_multiple_procode_for_same_revenue_confirm_messge: "You have indicated the services provided during this visit were NOT delivered during separate and distinct time periods. You must remove one of the CPT codes from this page to resolve this problem.",
    note_added: 'Note added successfully.',
    note_required: 'Please enter in a Note',
    unit_exceeded: "You've entered more than 9 units. Do you want to continue?",
    diagnosis_inappropriate: "Diagnosis(es) are not appropriate for current insurance. Are you sure you want to save the current DX codes?",
    select_at_least_one_charge: 'Please select a Case to move the Charges to.',
    select_single_visit: 'Please select at least one charge to transfer.'
}
export enum charge_status {
    NEW = 0,
    EDIT = 1,
    LOCKED = 2
}

export enum charge_amount_status {
    RECALCULATED = "Recalculated",
}

export enum enum_date_type {
    from_date = "from_date",
    through_date = "through_date"
}

export const get_audit_action_name = (auditActionId: any): string => {
    if (auditActionId === '0')
        return 'Release or Pend';
    return audit_action_list.find(action => action.action_id === auditActionId).action_desc;
}

export const action_list = [
    { action_id: 1, action_desc: "Release" },
    { action_id: 2, action_desc: "Pend" }
]
export const audit_action_list = [
    { action_id: 'B', action_desc: "Release" },
    { action_id: 'P', action_desc: "Pend" }
]
export const release_claim_delay_id = "release_claim_delay_id";

export const strip = (value) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText || "";
}

const release_claim_delay_link_renderer = (params, navigate_to_patient) => {
    let eDiv = document.createElement("span");
    eDiv.setAttribute('id', `${params.colDef.field}_${params.value}`);
    eDiv.setAttribute('class', 'href_link');
    eDiv.innerHTML = params.value;
    eDiv.addEventListener("click",        
        (para) => {
            navigate_to_patient(params.value);
        }
    );
    return eDiv;
};
export const release_claim_delay = (navigate_to_patient) => {
    return {
        grid_header_height: 35,
        api: {
            release_claim_delay: '/charge/release_claims'
        },
        claim_delay_column_def: [
            {
                headerName: "",
                headerComponentParams: header_template,
                field: "charge_id",
                headerCheckboxSelection: true,
                checkboxSelection: true,
                maxWidth: 40,
                minWidth: 40,
                width: 40,
                suppressSorting: true,
                suppressFilter: true,
                suppressSizeToFit: true,
                cellStyle: { 'padding-left': '6px' },
                cellRenderer: params => charge_status_cell_renderer(params),
                valueGetter: function chainValueGetter(params) {
                    return '';
                }
            },
            {
                headerName: 'Patient ID',
                headerComponentParams: header_template,
                field: 'patient_id',
                filter: "linkFilter",
                type: "link",
                comparator: number_comparator,
                cellStyle: { textAlign: "right" },
                cellRenderer: (params) => release_claim_delay_link_renderer(params, navigate_to_patient),

                //comparator: (link1, link2) => text_comparator(strip(link1), strip(link2)),  
                //cellRenderer: 'LinkRendererComponent',
                //cellRendererParams: {
                //    r4_to_r6_routes: [
                //        {
                //            has_parameter: true,
                //            parameters: 'Id',
                //            r4_route: "patinfo.aspx",
                //            r6_route: "/patient/Id"
                //        }
                //    ]
                //},
                minWidth: 120,
                width: 120,
                unSortIcon: true,
                //suppressSizeToFit: true,
            },
            {
                headerName: 'Patient',
                headerComponentParams: header_template,
                field: 'patient_name',
                filter: 'textFilter',
                type: 'string',
                minWidth: 120,
                width: 120,
                unSortIcon: true,
                //suppressSizeToFit: true,
                comparator: text_comparator
            },
            {
                headerName: 'Case',
                headerComponentParams: header_template,
                field: 'case_name',
                filter: 'textFilter',
                type: 'string',
                minWidth: 120,
                width: 120,
                unSortIcon: true,
                //suppressSizeToFit: true,
                comparator: text_comparator
            },
            {
                headerName: 'Date of Service',
                headerComponentParams: header_template,
                field: 'date_of_service',
                filter: 'textFilter',
                type: 'string',
                minWidth: 120,
                width: 120,
                unSortIcon: true,
                //suppressSizeToFit: true,
                comparator: text_comparator
            },
            {
                headerName: 'Provider',
                headerComponentParams: header_template,
                field: 'provider',
                filter: 'textFilter',
                type: 'string',
                minWidth: 130,
                width: 130,
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: 'Location',
                headerComponentParams: header_template,
                field: 'location',
                filter: 'textFilter',
                type: 'string',
                minWidth: 150,
                width: 150,
                unSortIcon: true,
                //suppressSizeToFit: true,
                comparator: text_comparator
            },
            {
                headerName: ' CPT',
                headerComponentParams: header_template,
                field: 'cpt_code',
                filter: 'textFilter',
                type: 'string',
                minWidth: 100,
                width: 120,
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: ' Units',
                headerComponentParams: header_template,
                field: 'units',
                filter: 'numberFilter',
                minWidth: 110,
                width: 120,
                unSortIcon: true,
                comparator: number_comparator
            },
            {
                headerName: 'Insurance Class',
                headerComponentParams: header_template,
                field: 'insurance_class_description',
                filter: 'textFilter',
                type: 'string',
                minWidth: 120,
                width: 120,
                unSortIcon: true,
                //suppressSizeToFit: true,
                comparator: text_comparator
            },
            {
                headerName: ' Claim Delay Reason',
                headerComponentParams: header_template,
                field: 'claim_delay_reason',
                filter: 'textFilter',
                type: 'string',
                minWidth: 250,
                width: 250,
                unSortIcon: true,
                comparator: text_comparator
            },
        ],
        page_size: 5000
    }
}
export enum money_type {
    cash = 1,
    check = 2,
    creditCard = 3,
    debitCard = 4,
    HSA=5
}
export const default_values = {
    comp_date: '07/01/2013'
}

const formatNumber = (number) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    //return Math.floor(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const currencyFormatter = (value) => {
    var format_number = '';
    if (value < 0) {
        format_number = '($' + formatNumber((parseFloat(value) * -1).toFixed(2)) + ')';
    } else {
        format_number = '$' + formatNumber(parseFloat(value).toFixed(2));
    }
    return format_number;
};


function charge_status_cell_renderer(params) {
    var icon = '';
    if (params.data.allow_edit == "Total" || !params.data.allow_edit) {
        if (params.data.allow_edit == false) {
            if (params.eGridCell && params.eGridCell.querySelector('.ag-selection-checkbox')) {
                //params.eGridCell.querySelector('.ag-selection-checkbox').remove()
                var elem = params.eGridCell.querySelector('.ag-selection-checkbox');
                elem.parentNode.removeChild(elem);
            }
            icon = '<i aria-hidden="true" class="lock icon" style="color: rgb(0, 112, 151);"></i>'
        }
        else if (params.data.allow_edit == "Total") {
            if (params.eGridCell && params.eGridCell.querySelector('.ag-selection-checkbox')) {
                //params.eGridCell.querySelector('.ag-selection-checkbox').remove()
                var elem = params.eGridCell.querySelector('.ag-selection-checkbox');
                elem.parentNode.removeChild(elem);
            }
            icon = 'Total'
        }
        var eDiv = document.createElement('div');
        eDiv.innerHTML = `<span>${icon}</span>`;
        return eDiv;
    }
}
export const view_charge_grid_header_id = "view_charge_grid_header_id"
export const charge_review_summary_id = "charge_review_summary_id"

export const patient_charge_configuration = {
    grid_header_height: 45,
    column_defs: [
        {
            headerName: "",
            suppressFilter: true, suppressSorting: true,
            children: [
                {
                    headerName: "",
                    headerComponentParams: headerNoFilterIconTemplate,
                    field: "allow_edit",
                    width: 50,
                    suppressSorting: true,
                    suppressFilter: true,
                    suppressSizeToFit: true,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    cellRenderer: params => charge_status_cell_renderer(params),

                    valueGetter: function chainValueGetter(params) {
                        return '';
                    }
                }
            ]
        },
        {
            headerName: "",
            suppressFilter: true, suppressSorting: true,
            children: [
                {
                    headerName: "Charge ID",
                    headerComponentParams: headerNoFilterIconTemplate,
                    field: "charge_id",
                    minWidth: 130,
                    suppressFilter: true, suppressSorting: true,
                },
                {
                    headerName: "Case",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 300,
                    field: "injury_disc",
                    cellClass: "cell-wrap-text", suppressFilter: true, suppressSorting: true,
                    autoHeight: true,
                },
                {
                    headerName: "Date of Service",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 120,
                    field: "date_of_service", suppressFilter: true, suppressSorting: true,
                },
                {
                    headerName: "Start of Care",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 120,
                    field: "start_of_care_date", suppressFilter: true, suppressSorting: true,
                },
                {
                    headerName: "CPT Code",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 110,
                    field: "proc_code.proc_code", suppressFilter: true, suppressSorting: true,
                }
                ,
                {
                    headerName: "Units",
                    headerComponentParams: amount_headerTemplate,
                    minWidth: 60,
                    field: "units", suppressFilter: true, suppressSorting: true,
                    cellStyle: { textAlign: 'right' },
                }
                ,
                {
                    headerName: "Modifier 1",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 100,
                    autoHeight: true,
                    field: "proc_mod_code1", suppressFilter: true, suppressSorting: true,
                }
                ,
                {
                    headerName: "Modifier 2",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 100,
                    autoHeight: true,
                    field: "proc_mod_code2", suppressFilter: true, suppressSorting: true,
                }
                ,
                {
                    headerName: "Modifier 3",
                    headerComponentParams: headerNoFilterIconTemplate,
                    minWidth: 100,
                    autoHeight: true,
                    field: "proc_mod_code3",suppressFilter: true, suppressSorting: true,
                }

            ]
        },
        {
            headerName: "Diagnosis Codes",

            headerClass: 'col-group-gray',
            suppressFilter: true, suppressSorting: true,
            children: [
                {
                    headerName: "1",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx1",
                    minWidth: 100,
                    suppressFilter: true, suppressSorting: true,
                },
                {
                    headerName: "2",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx2",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                }, {
                    headerName: "3",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx3",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                }, {
                    headerName: "4",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx4",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                }, {
                    headerName: "5",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx5",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                }, {
                    headerName: "6",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx6",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                }, {
                    headerName: "7",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx7",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                }, {
                    headerName: "8",
                    headerComponentParams: headerNoFilterIconTemplate,
                    headerClass: 'col-gray',
                    field: "dx8",
                    minWidth: 100, suppressFilter: true, suppressSorting: true,
                },
            ]
        },
        {
            headerName: "",
            suppressFilter: true, suppressSorting: true,
            children: [
                {
                    headerName: "Insurance Preauthorization",
                    headerComponentParams: headerNoFilterIconTemplate,
                    field: "insurance_pre_authorization",
                    minWidth: 150, suppressFilter: true, suppressSorting: true,
                    cellClass: "cell-wrap-text",
                    autoHeight: true,
                },
                {
                    headerName: "Current Insurance",
                    headerComponentParams: headerNoFilterIconTemplate,
                    field: "current_insurance",
                    minWidth: 140, suppressFilter: true, suppressSorting: true,
                    cellClass: "cell-wrap-text",
                    autoHeight: true,

                },
                {
                    headerName: "Charge Amount",
                    headerComponentParams: amount_headerTemplate,
                    field: "total_amount",
                    minWidth: 120, suppressFilter: true, suppressSorting: true,
                    cellStyle: { textAlign: 'right' },

                },
                {
                    headerName: "Payment Amount",
                    headerComponentParams: amount_headerTemplate,
                    field: "payment_amount",
                    minWidth: 120, suppressFilter: true, suppressSorting: true,
                    cellStyle: { textAlign: 'right' },
                },
                {
                    headerName: "Adjustment Amount",
                    headerComponentParams: amount_headerTemplate,
                    field: "adjusted_amount",
                    minWidth: 120, suppressFilter: true, suppressSorting: true,
                    cellStyle: { textAlign: 'right' },
                },
                {
                    headerName: "Date Last Billed",
                    headerComponentParams: headerNoFilterIconTemplate,
                    field: "last_billed_date",
                    minWidth: 120, suppressFilter: true, suppressSorting: true,
                }
            ]
        }
    ],
    footer_def: [
        {
            headerName: "",
            field: "allow_edit",
            type: 'skip'
        },
        {
            headerName: "Charge ID",
            field: "charge_id",
        },
        {
            headerName: "Case",
            field: "injury_disc",
        },
        {
            headerName: "Date of Service",
            field: "date_of_service",
        },
        {
            headerName: "Start of Care",
            field: "start_of_care_date",
        },
        {
            headerName: "CPT Code",
            field: "proc_code.proc_code",
        },
        {
            headerName: "Units",
            field: "units",
            type: 'number'
        },
        {
            headerName: "Modifier 1",
            field: "proc_mod_code1",
        },
        {
            headerName: "Modifier 2",
            field: "proc_mod_code2",
        }
        ,
        {
            headerName: "Modifier 3",
        },
        {
            headerName: "1",
            field: "dx1",
        },
        {
            headerName: "2",
            field: "dx2",
        },
        {
            headerName: "3",
            field: "dx3",
        },
        {
            headerName: "4",
            field: "dx4",
        },
        {
            headerName: "5",
            field: "dx5",
        },
        {
            headerName: "6",
            field: "dx6",
        },
        {
            headerName: "7",
            field: "dx7",
        },
        {
            headerName: "8",
            field: "dx8",
        },
        {
            headerName: "Insurance Preauthorization",
            field: "insurance_pre_authorization",
        },
        {
            headerName: "Current Insurance",
            field: "current_insurance",

        },
        {
            headerName: "Charge Amount",
            field: "total_amount",
            type: 'currency'
        },
        {
            headerName: "Payment Amount",
            field: "payment_amount",
            type: 'currency'
        },
        {
            headerName: "Adjustment Amount",
            field: "adjusted_amount",
            type: 'currency'
        },
        {
            headerName: "Date Last Billed",
            field: "last_billed_date",
        }
    ]
}
export const charge_edit_rule_configuration = {
    grid_header_height: 45,
    column_defs: [
        {
            headerName: "Code",
            headerComponentParams: header_template,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            field: "cpt_code",
            minWidth: 80,
        },
        {
            headerName: "Codes",
            headerComponentParams: header_template,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            minWidth: 300,
            field: "cpt_combination",
        },
        {
            headerName: "Modifier",
            headerComponentParams: header_template,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            minWidth: 120,
            field: "modifier",
        }
    ]
}


export const updated_charges_summary = {
    component_path: 'updated_charges',
    session_key:'charge_audit_summary_sesion',
    page_name: 'Charge Updates',
    search_button_text: 'Search',
    clear_button_text: 'Clear',
    parent_company_dd_name: 'Parent Company',
    user_company_dd_name: 'Company',
    export_button_text: 'Export',
    print_button_text: 'Print',
    report_grid_id: 'accordian_grid_updated_charges',
    updated_charge_summary_configuration_header_id: "updated_charge_summary_configuration_header_id",
    update_button_text: 'Remove',
    updated_charge_summary_checkbox_id: "updated_charge_summary_checkbox_id",
    remove_modal_title: "Remove Charge Updates",
    remove_modal_message: "An Account Note will not be added to the patient's chart. Do you want to continue?",
    remove_modal_ok_text: "Yes",
    remove_modal_cancel_text: "No",
    remove_successful_text: "The selected entries were removed.",
    remove_error_text: "One or more entries could not be removed. Try again.",
    empty_error: "Please select at least one record to remove.",
    inner_cols: [    
         {
            headerName: 'CPT Code',
            field: 'cpt_code',
            align: 'left'
        },
        {
            headerName: 'Charge ID',
            field: 'charge_id',
            align: 'left'
        },
        {
            headerName: 'Charge Status',
            field: 'charge_status',
            align: 'left'
        },
        {
            headerName: 'Item Name',
            field: 'item_name',
            align: 'left'
        },
        {

            headerName: 'Previous',
            field: 'previous_value',
            align: 'left'
        },
        {

            headerName: 'New',
            field: 'new_value',
            align: 'left'
        }
    ],

    column_defs: [
        {
            headerName: '',
            field: 'group',
            type: 'string',
            unSortIcon: true,
            maxWidth: 40,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            resizable: false,
            cellRendererParams: {
                innerRenderer: params => {
                    if (!params.node.group && !params.node.rowPinned && params.data) {
                        let eDiv = document.createElement('div');
                        eDiv.innerHTML = updated_charge_summary_details_tamplate(params.data, params.column.actualWidth);
                        return eDiv.firstChild;
                    }
                }
            },
            colSpan: function (params) {
                if (!params.node.group && !params.node.rowPinned) {
                    return params.columnApi.getAllColumns().length;
                }
                return 1;
            },
            cellRenderer: 'agGroupCellRenderer',
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        }, 
        {
            headerName: "",
            headerComponentParams: get_header_template_with_navigation("updated_charge_summary_checkbox_id"),
            // headerComponentParams: headerTemplate,
            field: "charge_id",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            maxWidth: 40,
            minWidth: 40,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressMovable: false,
            cellStyle: { 'padding-left': '6px' },
        },
        {
            headerName: "",
            field: "update_action",
            suppressSorting: true,
            suppressFilter: true,
            maxWidth: 30,
            width: 30,
            resizable: false,
            cellRenderer: function (param) {
                return '<span class="update-charge-check ag-row-group-indent-0"><span class="ag-group-contracted" ref="eContracted"><i aria-hidden="true" class="circle check icon"></i></span></span>';
            }
        },
        {
            headerName: 'Date Of Service',
            headerComponentParams: header_template,
            field: 'date_of_service',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Clinic Code',
            headerComponentParams: header_template,
            field: 'company_code',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Account #',
            headerComponentParams: header_template,
            field: 'patient_id',
            filter: 'numberFilter',
            type: 'number',
            unSortIcon: true,
            cellClass: "href_link"
        },
        {
            headerName: 'First Name',
            headerComponentParams: header_template,
            field: 'patient_first_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'Last Name',
            headerComponentParams: header_template,
            field: 'patient_last_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true
        }
    ]
};

const updated_charge_summary_details_tamplate = (data, grouped_column_width) => {      
    return `<div class="grid_inner_table">
            <div class="table-responsive custom-default-table" style="min-width: 1200px;">
                <table class="ui table">
                    <thead>
                        <tr id="inner_table">
                            ${updated_charge_summary_render_header(data, grouped_column_width)}
                        </tr>
                    </thead>
                    <tbody>
                        ${updated_charge_summary_render_rows(data)}
                    </tbody>
                </table>
            </div>
        </div>`;
};

export const updated_charge_summary_render_header = (data, grouped_column_width) => {
    let nodes = `<th style="min-width:${grouped_column_width}px"></th>`;
    var total_len = data.inner_cols.length;
    data.inner_cols.map((item, index) => {
        if (total_len - 1 == index) {
            nodes = `
        ${nodes}  
        <th style="min-width:${100}px;text-align:${item.align};">${item.headerName}</th>
        `;
        } else {
            nodes = `
        ${nodes}
        <th style="max-width:${item.width}px;min-width:${item.width}px;width:${item.width}px;text-align:${item.align};">${item.headerName}</th>
        `;
        }
    });
    return nodes;
};

export const updated_charge_summary_render_rows = data => {
    let nodes = '';
    data.updated_charge_details &&
        data.updated_charge_details.map(value => {
            nodes = `
            ${nodes}
            <tr>
                <td style="border-right:0;"></td>
                <td>${value.cpt_code || ''}</td>
                <td>${value.charge_id || ''}</td>
                <td>${value.charge_status || ''}</td>
                <td>${value.item_name || ''}</td>
                <td>${value.previous_value || ''}</td>
                <td>${value.new_value || ''}</td>         
            </tr>`;
        });
    return nodes;
};

export const charge_audit_configuration_grid_header_id = "charge_audit_configuration_grid_header_id";
export const charge_audit_configuration = (props) => {
    return {
        id: "charge_audit_grid",
        header: "Charge Audit",
        grid_header_height: 35,
        column_def: [
            {
                headerName: "",
                headerClass: "col-group-gray",
                groupId: "company",
				children: [
                    {
                        headerName: "Company Code",
                        headerComponentParams: get_header_template_with_navigation(
                            charge_audit_configuration_grid_header_id
                        ),
                        headerClass: "col-gray",
                        field: "company_code",
                        type: "string",
                        suppressFilter: true,
                        comparator: text_comparator,
                        width: 165,
                        suppressSizeToFit: true,
                        unSortIcon: true
                    },
                    {
                        headerName: "Company",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        field: "company",
                        type: "string",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 220,
                        cellClass: "cell-wrap-text",
                        comparator: text_comparator
                    }
                ]
            },
            {
                headerName: "Charge Review (T)",
                groupId: "charge_review",
                children: [
                    {
                        headerName: "Charge Count",
                        headerComponentParams: header_template,
                        field: "charge_review_count",
                        type: "link",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 170,
                        comparator: number_comparator,
                        cellStyle: { textAlign: "right" },
                        cellRenderer: (params) => charge_audit_link_renderer(params, props, 'T')
                    },
                    {
                        headerName: "Amount",
                        headerComponentParams: header_template,
                        field: "charge_review_amount",
                        type: "currency",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 115,
                        comparator: currency_comparator,
                        cellStyle: { textAlign: "right" }
                    },
                    {
                        headerName: "Oldest Date",
                        headerComponentParams: header_template,
                        field: "charge_review_oldest_date",
                        type: "date",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 145,
                        comparator: mm_dd_yyyy_comparator
                    }
                ]
            },
            {
                headerName: "Pended Charges (P)",
                headerClass: "col-group-gray",
                groupId: "charge_audit",
                children: [
                    {
                        headerName: "Charge Count",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        field: "charge_audit_count",
                        type: "link",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 170,
                        comparator: number_comparator,
                        cellStyle: { textAlign: "right" },
                        cellRenderer: (params) => charge_audit_link_renderer(params, props, 'P')
                    },
                    {
                        headerName: "Amount",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        field: "charge_audit_amount",
                        type: "currency",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 115,
                        comparator: currency_comparator,
                        cellStyle: { textAlign: "right" }
                    },
                    {
                        headerName: "Oldest Date",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        type: "date",
                        field: "charge_audit_oldest_date",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 145,
                        comparator: mm_dd_yyyy_comparator
                    }
                ]
            },
            {
                headerName: "Delayed Charges (L)",
                groupId: "delayed_claims",
                children: [
                    {
                        headerName: "Charge Count",
                        headerComponentParams: header_template,
                        field: "delayed_claims_count",
                        type: "link",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 170,
                        comparator: number_comparator,
                        cellStyle: { textAlign: "right" },
                        cellRenderer: (params) => charge_audit_link_renderer(params, props, 'L')
                    },
                    {
                        headerName: "Amount",
                        headerComponentParams: header_template,
                        field: "delayed_claims_amount",
                        type: "currency",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 115,
                        comparator: currency_comparator,
                        cellStyle: { textAlign: "right" }
                    },
                    {
                        headerName: "Oldest Date",
                        headerComponentParams: header_template,
                        field: "delayed_claims_oldest_date",
                        type: "date",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 145,
                        comparator: mm_dd_yyyy_comparator
                    }
                ]
            },
            {
                headerName: "Released Charges (B)",
                headerClass: "col-group-gray",
                groupId: "released_charges",
                children: [
                    {
                        headerName: "Charge Count",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        field: "released_charges_count",
                        type: "link",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 170,
                        comparator: number_comparator,
                        cellStyle: { textAlign: "right" },
                        cellRenderer: (params) => charge_audit_link_renderer(params, props,'B')
                    },
                    {
                        headerName: "Amount",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        field: "released_charges_amount",
                        type: "currency",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 115,
                        comparator: currency_comparator,
                        cellStyle: { textAlign: "right" }
                    },
                    {
                        headerName: "Oldest Date",
                        headerComponentParams: header_template,
                        headerClass: "col-gray",
                        field: "released_charges_oldest_date",
                        type: "date",
                        suppressFilter: true,
                        unSortIcon: true,
                        minWidth: 145,
                        comparator: mm_dd_yyyy_comparator
                    }
                ]
            }
        ]
    };
};

export const claim_hold_summary_configuration_grid_header_id = "claim_hold_summary_configuration_grid_header_id";
export const claim_hold_summary_configuration = (props, group_by_header) =>{
	return {
		id: 'accordian_grid_claim_hold_summary_grid',
		header: 'Claim Hold Summary',
		grid_header_height: 35,
		column_def:[
			{
				headerName: '',
				headerClass: "col-group-gray",
                groupId: 'company',
                children: [
                    {
                        headerName: '',
                        headerClass: 'col-gray',
                        field: 'group',
                        type: "string",
                        width: 50,
                        suppressSizeToFit: true,
                        filter: false,
                        suppressFilter: true
                    },
					{
						headerName: group_by_header,
						headerClass: 'col-gray',
                        field: 'row_label',
                        type: "string",
                        filter: false,
                        suppressFilter: true,
						width: 200,
						suppressSizeToFit: true
					},
					{
						headerName: '# of Impacted Visits',
						headerComponentParams: header_template,
						headerClass: 'col-gray',
						field: 'number_impacted_visits',
						type: "string",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 180,
						cellClass: 'cell-wrap-text',
						comparator: text_comparator,
						cellStyle: { textAlign: "center" }
					}
				]
			},
			{
				headerName: "< 3 Days",
				groupId: "less_than_three_days_charges",
				children: [
					{
						headerName: "Total Charge Amt",
						headerComponentParams: header_template,
						field: "three_days_total_charge_amount",
						type: "link",
						filter: 'textFilter',
						unSortIcon: true,
						minWidth: 170,
						comparator: number_comparator,
                        cellStyle: { textAlign: "center" },
						//cellRenderer: (params) => charge_audit_link_renderer(params, props, 'T')
					},
					{
						headerName: "Total Charges",
						headerComponentParams: header_template,
						field: "three_days_total_charges",
						type: "currency",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 115,
                        comparator: currency_comparator,
                        cellStyle: { textAlign: "center" }
					}
				]
			},
			{
				headerName: "4-7 Days",
				headerClass: "col-group-gray",
				groupId: "7_days_charges",
				children: [
					{
						headerName: "Total Charge Amt",
						headerComponentParams: header_template,
						headerClass: "col-gray",
						field: "seven_days_total_charge_amount",
						type: "link",
						filter: 'textFilter',
						minWidth: 170,
						comparator: number_comparator,
                        cellStyle: { textAlign: "center" },
						//cellRenderer: (params) => charge_audit_link_renderer(params, props, 'P')
					},
					{
						headerName: "Total Charges",
						headerComponentParams: header_template,
						headerClass: "col-gray",
						field: "seven_days_total_charges",
						type: "currency",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 115,
						comparator: currency_comparator,
                        cellStyle: { textAlign: "center" }
					}
				]
			},
			{
				headerName: "8-14 Days",
				groupId: "fourteen_days_charges",
				children: [
					{
						headerName: "Total Charge Amount",
						headerComponentParams: header_template,
						field: "fourteen_days_total_charge_amount",
						type: "link",
						filter: 'textFilter',
						unSortIcon: true,
						minWidth: 170,
						comparator: number_comparator,
                        cellStyle: { textAlign: "center" }
						//cellRenderer: (params) => charge_audit_link_renderer(params, props, 'P')
					},
					{
						headerName: "Total Charges",
						headerComponentParams: header_template,
						field: "fourteen_days_total_charges",
						type: "currency",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 115,
						comparator: currency_comparator,
                        cellStyle: { textAlign: "center" }
					}
				]
			},
			{
				headerName: "15-21 Days",
				headerClass: "col-group-gray",
				groupId: "twentyone_days_charges",
				children: [
					{
						headerName: "Total Charge Amount",
						headerComponentParams: header_template,
						headerClass: "col-gray",
						field: "twentyone_days_total_charge_amount",
						type: "link",
						filter: 'textFilter',
						unSortIcon: true,
						minWidth: 170,
						comparator: number_comparator,
                        cellStyle: { textAlign: "center" }
						//cellRenderer: (params) => charge_audit_link_renderer(params, props, 'P')
					},
					{
						headerName: "Total Charges",
						headerComponentParams: header_template,
						headerClass: "col-gray",
						field: "twentyone_days_total_charges",
						type: "currency",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 115,
						comparator: currency_comparator,
                        cellStyle: { textAlign: "center" }
					}
				]
			},
			{
				headerName: "> 21 Days",
				groupId: "greater_than_21_days_charges",
				children: [
					{
						headerName: "Total Charge Amount",
						headerComponentParams: header_template,
						field: "twentyone_plus_days_total_charge_amount",
						type: "link",
						filter: 'textFilter',
						unSortIcon: true,
						minWidth: 170,
						comparator: number_comparator,
                        cellStyle: { textAlign: "center" }
						//cellRenderer: (params) => charge_audit_link_renderer(params, props, 'P')
					},
					{
						headerName: "Total Charges",
						headerComponentParams: header_template,
						field: "twentyone_plus_days_total_charges",
						type: "currency",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 115,
						comparator: currency_comparator,
                        cellStyle: { textAlign: "center" }
					}
				]
			},
			{
				headerName: "Total",
				headerClass: "col-group-gray",
				groupId: "total_amount_owed",
				children: [
					{
						headerName: "Total Amount Owed",
						headerComponentParams: header_template,
						headerClass: "col-gray",
						field: "total_amount_owed",
						type: "string",
						filter: 'textFilter',
						comparator: text_comparator,
						width: 165,
						suppressSizeToFit: true,
						unSortIcon: true,
                        cellStyle: { textAlign: "center" }
					},
					{
						headerName: "Total Charges",
						headerComponentParams: header_template,
						headerClass: "col-gray",
						field: "total_charges",
						type: "string",
						filter: 'numberFilter',
						unSortIcon: true,
						minWidth: 110,
						cellClass: "cell-wrap-text",
						comparator: text_comparator,
                        cellStyle: { textAlign: "center" }
					}
				]
			}
		],
		
	}
}

export const claim_hold_summary_configuraton_child_column = {
	child_column: [
		{
			headerName: '',
            field: 'row_label',
			width: 150,
            align: 'center',
			type: 'string'
		},
		{
			headerName: '',
			field: 'number_impacted_visits',
			width: 110,
            align: 'center',
			type: 'number'
		},
		{

			headerName: '',
			field: 'three_days_total_charge_amount',
			width: 55,
            align: 'center',
			type: 'currency'
		},
		{
			headerName: '',
			field: 'three_days_total_charges',
			width: 55,
            align: 'center',
			type: 'number'
		},
		{

			headerName: '',
			field: 'seven_days_total_charge_amount',
			width: 55,
            align: 'center',
			type: 'currency'
		},
		{

			headerName: '',
			field: 'seven_days_total_charges',
			width: 55,
            align: 'center',
			type: 'number'
		},
		{

			headerName: '',
			field: 'fourteen_days_total_charge_amount',
			width: 55,
            align: 'center',
			type: 'currency'
		},
		{

			headerName: '',
			field: 'fourteen_days_total_charges',
			width: 55,
            align: 'center',
			type: 'number'
		},
		{

			headerName: '',
			field: 'twentyone_days_total_charge_amount',
			width: 55,
            align: 'center',
			type: 'currency'
		},
		{

			headerName: '',
			field: 'twentyone_days_total_charges',
			width: 55,
            align: 'center',
			type: 'number'
		},
		{

			headerName: '',
			field: 'twentyone_plus_days_total_charge_amount',
			width: 55,
            align: 'center',
			type: 'currency'
		},
		{

			headerName: '',
			field: 'twentyone_plus_days_total_charges',
			width: 55,
            align: 'center',
			type: 'number'
		},
		{

			headerName: '',
			field: 'total_amount_owed',
			width: 55,
            align: 'center',
			type: 'currency'
		},
		{

			headerName: '',
			field: 'total_charges',
			width: 55,
            align: 'center',
			type: 'number'
		}

	]
}

export const status_mapping = {
    'T': 'Charge Review',
    'P': 'Pended Charges',
    'L': 'Delayed Charges',
    'B': 'Released Charges'
}

const charge_audit_link_renderer = (params, props,status) => {
    if (!params.node.rowPinned) {
        let eDiv = document.createElement("span");
        eDiv.setAttribute('id', `${params.colDef.field}_${params.value}`);
        if (params.colDef.field == "location_name") {
            if (params.data) {
                eDiv.innerHTML = `${params.data.location_name} (${params.data.location_code})`;
            }
        } else {
            eDiv.setAttribute('class', 'href_link');
            eDiv.innerHTML = params.value;
            eDiv.addEventListener("click", (para) => {
                props.history.push({
                    pathname: '/charge/charge_audit_detail',
                    search: '',
                    state: { charge_status: status, company_id: params.data.company_id, restrict_pos: params.data.restrict_pos_flag, user_type_id: params.data.user_type_id }
                });
            });
        }
        return eDiv;
    } else {
        return params.value
    }    
};
export const charge_audit_ff_based_col_header = {
    header: [
        {
            name: `Patient #`,
            field: "patient_full_name"
        },
        {
            name: "Date of Birth",
            field: "dob"
        },
        {
            name: "Age",
            field: "age",
            align: 'right'
        },
        {
            name: "Primary Insurance",
            field: "primary_insurance_code"
        },
        {
            name: "Date of Service",
            field: "date_of_service"
        },
        {
            name: "Provider",
            field: "billing_provider_name"
        },
        {
            name: "Location",
            field: "place_of_service"
        },
        {
            name: "Posted",
            field: "posted_date"
        }
    ],
    subHeader: [
        {
            name: 'CPT Code',
            field: "procedure_code"
        },
        {
            name: "Units",
            field: "units",
            align: 'right'
        },
        {
            name: "Modifier 1",
            field: "procModCode1"
        },
        {
            name: "Modifier 2",
            field: "procModCode2"
        },
        {
            name: "Modifier 3",
            field: "procModCode3"
        },
        {
            name: "Diagnosis Codes",
            field: "dx"
        },
        {
            name: "POS Indicator",
            field: "pos_identifier"
        },
        {
            name: "Insurance Preauthorization",
            field: "insurance_pre_authorization"
        },
        {
            name: "Charge Status",
            field: "charge_status"
        },
        {
            name: "Referring Physician",
            field: "ref_physician"
        },
        {
            name: "Case",
            field: "injury_disc"
        },
        {
            name: "Date of Injury",
            field: "injury_date"
        },
        /*
        {
            name: "Pending Reason",
            field: "reason_code"
        }
        */
    ],
}
export const charge_audit_col_header = {
    header: [
        {
            name: `Patient #`,
            field: "patient_full_name"
        },
        {
            name: "Date of Birth",
            field: "dob"
        },
        {
            name: "Age",
            field: "age",
            align: 'right'
        },
        {
            name: "Primary Insurance",
            field: "primary_insurance_code"
        },
        {
            name: "Date of Service",
            field: "date_of_service"
        },
        {
            name: "Provider",
            field: "billing_provider_name"
        },
        {
            name: "Location",
            field: "place_of_service"
        },
        {
            name: "Posted",
            field: "posted_date"
        }
    ],
    subHeader: [
        {
            name: 'CPT Code',
            field: "procedure_code"
        },
        {
            name: "Units",
            field: "units",
            align: 'right'
        },
        {
            name: "Modifier 1",
            field: "procModCode1"
        },
        {
            name: "Modifier 2",
            field: "procModCode2"
        },
        {
            name: "Modifier 3",
            field: "procModCode3"
        },
        {
            name: "Diagnosis Codes",
            field: "dx"
        },
        {
            name: "POS Indicator",
            field: "pos_identifier"
        },
        {
            name: "Insurance Preauthorization",
            field: "insurance_pre_authorization"
        },
        {
            name: "Referring Physician",
            field: "ref_physician"
        },
        {
            name: "Case",
            field: "injury_disc"
        },
        {
            name: "Date of Injury",
            field: "injury_date"
        },
        /*
        {
            name: "Pending Reason",
            field: "reason_code"
        }
        */
    ],
}
export const charge_audit_detail_col_header = {
    header: [
        {
            name: `Patient #`,
            field: "patient_full_name"
        },
        {
            name: "Date of Birth",
            field: "dob"
        },
        {
            name: "Age",
            field: "age",
            align: 'right'
        },
        {
            name: "Primary Insurance",
            field: "primary_insurance_code"
        },
        {
            name: "Date of Service",
            field: "date_of_service"
        },
        {
            name: "Provider",
            field: "billing_provider_name"
        },
        {
            name: "Location",
            field: "place_of_service"
        },
        {
            name: "Posted",
            field: "posted_date"
        }
    ],
    subHeader: [
        {
            name: 'CPT Code',
            field: "proc_code"
        },
        {
            name: "Units",
            field: "units",
            align: 'right'
        },
        {
            name: "Modifier 1",
            field: "proc_mod_code1"
        },
        {
            name: "Modifier 2",
            field: "proc_mod_code2"
        },
        {
            name: "Diagnosis Codes",
            field: "dx"
        },
        {
            name: "POS Indicator",
            field: "pos_identifier"
        },
        {
            name: "Insurance Preauthorization",
            field: "insurance_pre_authorization"
        },
        {
            name: "Referring Physician",
            field: "ref_physician"
        },
        {
            name: "Case",
            field: "injury_disc"
        },
        {
            name: "Date of Injury",
            field: "injury_date"
        },
        /*
        {
            name: "Pending Reason",
            field: "reason_code"
        }
        */
    ],
}

export const search_grid_id = 'search_grid_id';

export const procedure_code_configuration = {
    column_defs: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: "checkbox",
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            cellRenderer: params => {
                return `<div style="text-align:center"><input type='checkbox' ${
                    params.is_incomplete === false ? 'checked' : ''
                    } disabled/></div>`;
            }
        },
        {
            headerName: "Procedure Code",
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: "procedure_code",
            filter: "textFilter",
            type: 'string',
            minWidth: 172,
            width: 200,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Description",
            headerComponentParams: header_template,
            field: "description",
            filter: "textFilter",
            type: 'string',
            minWidth: 215,
            width: 700,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerCenterTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'status',
            cellClass: "text-center",
            unSortIcon: true
        },
        {
            headerName: "Complete",
            headerComponentParams: headerCenterTemplate,
            field: "is_incomplete",
            minWidth: 50,
            width: 50,
            filter: "checklistFilter",
            type: 'boolean',
            cellClass: "text-center",
            unSortIcon: true,
            cellRenderer: function (params) {
                var eAnc = document.createElement('div');
                if (params.value === true) {
                    eAnc.innerHTML = `<i class="icon check"></i>`
                }
                return eAnc;
            }
        }
    ]
};
export const pagination = {
    page_size: 20,
    lazy_load_page_size:5
};

export const charge_case_transfer_setting_columns = [
    {
        headerComponentParams: get_header_template_with_navigation("search_grid_id"),
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: "checkbox",
        width: 35,
        maxWidth: 35,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
    },
    {
        headerName: "Date of Service",
        headerComponentParams: header_template,
        field: "date_of_service",
        minWidth: 150,
        width: 150,
        type: 'date',
        unSortIcon: true,
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator
    },
    {
        headerName: "Location",
        headerComponentParams: header_template,
        field: "pos_name",
        minWidth: 150,
        width: 150,
        filter: "textFilter",
        unSortIcon: true,
        type: 'string',
        comparator: text_comparator
    },
    {
        headerName: "Diagnoses",
        headerComponentParams: header_template,
        field: "dx_codes",
        minWidth: 150,
        width: 150,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: 'Amount',
        headerComponentParams: headerCurrencyTemplate,
        field: 'total_chg_amt',
        minWidth: 150,
        width: 150,
        filter: 'currencyFilter',
        type: 'currency',
        unSortIcon: true,
        comparator: currency_comparator,
        cellStyle: { textAlign: 'right' }
    }
];

export const reactivate_charges_configuration = {
        column_defs : [
        {
            headerName: "Charge",
            field: "is_charge",
            width: 115,
            maxWidth: 125,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            cellRenderer: (params: any) => {
                return handle_reactivate_cell_checkbox(params, params.data.is_active, "is_charge");
            }
        },
        {
            headerName: "Visit",
            field: "is_visit",
            width: 170,
            maxWidth: 180,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            cellRenderer: (params: any) => {
                return handle_reactivate_cell_checkbox(params, params.data.is_active, "is_visit");
            }
        },
        {
            headerName: "Charge ID",
            field: "charge_id",
            minWidth: 130,
            suppressFilter: true, 
            suppressSorting: true,
        },
        {
            headerName: "Patient ID",
            field: "patient_id",
            minWidth: 130,
            suppressFilter: true, 
            suppressSorting: true,
            type: "string",
        },
        {
            headerName: "First Name",
            field: "patient_first_name",
            minWidth: 130,
            suppressFilter: true, 
            suppressSorting: true,
        },
        {
            headerName: "Last Name",
            field: "patient_last_name",
            minWidth: 130,
            suppressFilter: true, 
            suppressSorting: true,
        },
        {
            headerName: "Date of Service",
            field: "date_of_service",
            minWidth: 130,
            suppressFilter: true, 
            suppressSorting: true,
            cellRenderer: params => {
                return custom_date_format(params.value, date_format["mm/dd/yyyy"]);
            }
        },
        {
            headerName: "Procedure Code",
            field: "proc_code",
            minWidth: 130,
            suppressFilter: true, 
            suppressSorting: true,

        },
        {
            headerName: "Charge Amount",
            field: "total_chg_amt",
            minWidth: 130,
            maxWidth: 145,
            suppressFilter: true, 
            suppressSorting: true,
            cellClass: "text-right",
            cellRenderer: params => {
                return currency_formatter(params.value);
            }
        },
        {
            headerName: "Active",
            field: "is_active",
            minWidth: 80,
            width: 80,
            type: 'boolean',
            cellClass: "text-center",
            suppressFilter: true, 
            cellRenderer: function (params) {
                var eAnc = document.createElement('div');
                if (params.value === true) {
                    eAnc.innerHTML = `<i class="icon check"></i>`
                }
                return eAnc;
            }
        }
    ] 
};

export const reactivate_cell_checkbox_render = (params, is_disabled: string, type: string) => {
    let id = is_disabled ? `disabled_${type}` : `checkb_${type}`,
        spn = is_disabled ? `disabled_span${type}` : `span_${type}`,
        isVisit = type === 'is_visit',
        isCharge = type === 'is_charge',
        isFirstLoad = typeof(params.value) === 'undefined',
        isChecked = false;

    if (isVisit && isFirstLoad) {
        params.value = !is_disabled;
    } else if (isCharge && isFirstLoad) {
        params.value = false;
    }

    isChecked = params.value;

    return `<input type='checkbox' ${isChecked ? 'checked' : ''} ${is_disabled ? 'disabled' : ''} id="${id}" />`;
};

export const handle_reactivate_cell_checkbox = (
    params,
    disabled: string,
    type: string
  ) => {
    let eDiv = document.createElement('div');
    eDiv.innerHTML = `${reactivate_cell_checkbox_render(params, disabled, type)}`;
    let eCheckbox = eDiv.querySelector('input[type=checkbox]');

    if (eCheckbox) {
        eCheckbox.addEventListener('change', (event: any) => {
            params.node.setDataValue(type, event.target.checked);
        });
    }

    return eDiv;
};

// Intefaces
export interface claimhold_dropdown_data {
    insurance_classes: any;
    payers: any;
    locations: any;
    practices: any;
}

export interface claimhold_summary_reponse {
    ChargeID: number;
    CompanyID: number;
    DateOfService: Date;
    UnitCount: number;
    ChargeAmount: number;
    HoldReason: number;
}

export interface claim_holds_summary_row_data {
    parent_id: number;
    row_id: number;
    row_label: string;
    number_impacted_visits: number;
    company_id: number;
    three_days_total_charge_amount: string;
    three_days_total_charges: number;
    seven_days_total_charge_amount: string;
    seven_days_total_charges: number;
    fourteen_days_total_charge_amount: string;
    fourteen_days_total_charges: number;
    twentyone_days_total_charge_amount: string;
    twentyone_days_total_charges: number;
    twentyone_plus_days_total_charge_amount: string;
    twentyone_plus_days_total_charges: number;
    total_amount_owed: string;
    total_charges: number;
    group?: string;
}

export interface claim_holds_summary_row_data_state {
    parent_id: number;
    row_id: number;
    row_label: string;
    number_impacted_visits: number;
    company_id: number;
    three_days_total_charge_amount: string;
    three_days_total_charges: number;
    seven_days_total_charge_amount: string;
    seven_days_total_charges: number;
    fourteen_days_total_charge_amount: string;
    fourteen_days_total_charges: number;
    twentyone_days_total_charge_amount: string;
    twentyone_days_total_charges: number;
    twentyone_plus_days_total_charge_amount: string;
    twentyone_plus_days_total_charges: number;
    total_amount_owed: string;
    total_charges: number;
    group?: string;
    visible: boolean;
}
