import { isSafariMobileDevice, is_safari } from '../../shared/utility';
import * as _ from 'lodash';

export const get_patient_statement_print_data = (data: any) => {

    var newWindow = window.open('', '_blank');


    // add global css to header
    var createGlobalCSSLink = newWindow.document.createElement('link');
    createGlobalCSSLink.rel = 'stylesheet';
    createGlobalCSSLink.type = 'text/css';
    createGlobalCSSLink.href = '/src/assets/css/main.css?ver=1234';
    newWindow.document.head.appendChild(createGlobalCSSLink);

    //add custom css to header
    var createCustomCSSLink = newWindow.document.createElement('link');
    createCustomCSSLink.rel = 'stylesheet';
    createCustomCSSLink.type = 'text/css';
    createCustomCSSLink.href = '/src/assets/css/custom.css';
    newWindow.document.head.appendChild(createCustomCSSLink);

    //add print css to header
    var createPrintCSSLink = newWindow.document.createElement('link');
    createPrintCSSLink.rel = 'stylesheet';
    createPrintCSSLink.type = 'text/css';
    createPrintCSSLink.href = '/src/assets/css/printable_report_new_tab.css';
    newWindow.document.head.appendChild(createPrintCSSLink);

    //main div
    var createMainDiv = newWindow.document.createElement('div');
    createMainDiv.id = 'printElement';

    // statement body div
    var createBodyDiv = newWindow.document.createElement('div');
    createBodyDiv.innerHTML = data;

    createMainDiv.appendChild(createBodyDiv);

    newWindow.document.body.appendChild(createMainDiv);
    //page footer with print and close button
    var createFooterDiv = newWindow.document.createElement('div');
    createFooterDiv.className = 'footer_section';

    var createCloseButton = newWindow.document.createElement('button');
    createCloseButton.id = 'print_report_close';
    createCloseButton.type = 'submit';
    createCloseButton.className = 'ui basic button';
    createCloseButton.onclick = () => newWindow.close();
    createCloseButton.innerHTML = 'Close';

    var createPrintButton = newWindow.document.createElement('button');
    createPrintButton.id = 'print_report_new';
    createPrintButton.type = 'submit';
    createPrintButton.className = 'ui primary button';
    createPrintButton.onclick = () => {
        const print_report_button = newWindow.document.getElementById('print_report_new');
        if (newWindow.print) {
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                setTimeout(function () {
                    if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                        if (isSafariMobileDevice()) {
                            newWindow.document.execCommand('print')
                        } else {
                            newWindow.print();
                        }
                    } else {
                        if (is_safari()) {
                            newWindow.document.execCommand('print')

                        } else {
                            newWindow.print();
                        }
                    }
                }, 100);
                setTimeout(function () {
                    print_report_button.blur();
                }, 4000);
            } else {
                if (newWindow.matchMedia) {
                    let mediaQueryList = newWindow.matchMedia('print');
                    mediaQueryList.addListener(function (mql) {
                        if (mql.matches) {
                        } else {
                            afterPrint();
                        }
                    });
                }
                let afterPrint = function afterPrint() {
                    print_report_button.blur();
                };
                // These are for Firefox
                newWindow.onafterprint = afterPrint;
                if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                    if (isSafariMobileDevice()) {
                        newWindow.document.execCommand('print')
                    } else {
                        newWindow.print();
                    }
                } else {
                    if (is_safari()) {
                        newWindow.document.execCommand('print')

                    } else {
                        newWindow.print();
                    }
                }
            }
        }
    };
    createPrintButton.innerHTML = 'Print';

    createFooterDiv.appendChild(createCloseButton);
    createFooterDiv.appendChild(createPrintButton);
    createMainDiv.appendChild(createFooterDiv);
    
};