import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Table, Input } from "semantic-ui-react";
import { clearTimeout } from 'timers';
import Selection from '../../../shared/component/selection_component';
import CurrencyFormatter from '../../../shared/component/currency_formatter';
import { enum_amount_type, enum_posting_type, enum_selection_type, enum_page_type, payment_field_type } from './../batch_payment_constants';
import * as constants from '../../../admin/constants/constants';
import { currency_formatter, try_parse_currency_float, try_parse_float, get_lauch_darkley_key_value, is_null_or_white_space } from './../../../shared/utility';
import { kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page } from '../../../feature_flip_constant';

export class BatchPaymentRowDetailComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            row_index:this.props.row_count,
            adjustment_code: this.props.adjustment_data,
            posting_type: this.props.posting_type || 1,
            batch_payment_detail_item: { ... this.props.data },
            yes_no_confirm_message: "",
            show_yes_no_confirm_message:false,            
            //total_adjustments: total_adjustment(this.props.posting_type)
        }
    }
    UNSAFE_componentWillMount = () => {
        //this.get_adjustment_data();
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this._is_mounted = true;
        if (this._is_mounted) {
            if (this.state.batch_payment_detail_item !== this.props.data) {
                this.setState({
                    batch_payment_detail_item: { ... this.props.data },
                     //total_adjustments:: total_adjustment(this.props.posting_type)
                })
            }
        }
    }

    componentWillUnmount = () => {
        this.props.onRef(undefined)
        this._is_mounted = false;
        //if (this.current_setTime_out) {
        //    clearTimeout(this.current_setTime_out);
        //}
    }

    componentDidUpdate(previousProps, previousState) {

        if (previousProps.data != this.props.data) {
            if (this._is_mounted) {
                this.setState({
                    batch_payment_detail_item: this.props.data
                });
            }
        }
        else if (previousProps.posting_type != this.props.posting_type)
        {
            if (this._is_mounted)
            {
                this.setState({
                    posting_type: this.props.posting_type,
                    batch_payment_detail_item: { ... this.props.data }
                });
            }
        }
        else if (previousProps.adjustment_data != this.props.adjustment_data)
        {
            if (this._is_mounted)
            {
                this.setState({
                    adjustment_code: this.props.adjustment_data
                });
            }
            
        }
        else if (previousProps.update_child_force != this.props.update_child_force && this.props.update_child_force == true)
        {
            if (this._is_mounted)
            {
                this.setState({
                    batch_payment_detail_item: { ... this.props.data },
                });
            }
            
            
         }
         else if (previousState.batch_payment_detail_item && previousState.batch_payment_detail_item.crossover && previousState.batch_payment_detail_item.crossover != this.props.data.crossover)
        {
            //Enforce the Crossover value on the previous state, else it clears changes from state edits in the row.
            let stateUpdateItem = previousState.batch_payment_detail_item;
            stateUpdateItem.crossover = this.props.data.crossover;
            if (this._is_mounted)
            {
                this.setState({
                    batch_payment_detail_item: { ...stateUpdateItem },
                });
            }
            
             
         }

    }

    get_updated_data = () => {
        var data = { ...this.state.batch_payment_detail_item };        
        if (data.xfer_ins_code_id != undefined || data.xfer_ins_code_id != null) {
            data.xfer_ins_code_id = data.xfer_ins_code_id.trim();
        }
        return data;
    }

    set_updated_data = (data) => {
        this.setState({
            batch_payment_detail_item: data
        }, () => {
            this.props.update_total();
            this.check_insurance_required();
        });
    }

    onFocus = (e) => {
        var element = document.getElementById("charge_details_proc_table");
        element.className = element.className.replace(/\btable-responsive\b/g, "");
        if (this.current_setTime_out) {
            clearTimeout(this.current_setTime_out);
        }
    }


    on_ins_change = (value) => {
        var data = { ...this.state.batch_payment_detail_item };
        data.ins = value
        if (this._is_mounted) {
            this.setState({
                batch_payment_detail_item: data,
            });
        }
    }
    //control_creation = (type) => {}

    select_on_focus = (event, type = null, index = 0, row_count = 0) => {
        let isPaymentsPage = this.props.page_type == enum_page_type.batch_payments;

        if (event && event.currentTarget) {
            event.currentTarget.select();
        }

        // Depending on type calculate amounts only for payments page
        if (isPaymentsPage && type == enum_amount_type.adjustment && event.currentTarget.value == "") {
            let batch_payment_state = { ...this.state.batch_payment_detail_item };
            batch_payment_state.pay_amount = try_parse_float(batch_payment_state.pay_amount);
            batch_payment_state.allow_amount = try_parse_float(batch_payment_state.allow_amount);

            this.calculate_row_data(batch_payment_state, index, row_count);
        }
    }

    on_crossover_text_change = (e) =>
    {
        if (!this._is_mounted) return;

        var unicode = e.keyCode ? e.keyCode : e.charCode;
        var key = e.key == null || e.key == undefined ? '' : e.key;

        if (unicode == 13) {
            e.preventDefault();
        }


        if (unicode == 38)//up key
        {
            var el = document.getElementById("crossover_txt_" + (this.props.row_count - 1))

            if (el) {
                el.focus();
            }

        } else if (unicode == 40)// dow key
        {
            var el = document.getElementById("crossover_txt_" + (this.props.row_count + 1))

            if (el) {
                el.focus();
            }
        }
       

        let value;
        if (unicode == 89 || key.toUpperCase()=='Y') value = 'Y';
        if (unicode == 78 || key.toUpperCase() == 'N') value = 'N';
        if (unicode == 13) value = 'N';


        //Handle non Y/N keys
        if (value != 'Y' && value != 'N') return;


        if (this.props.row_count == 0)
            this.props.update_sibling_crossover_txt(value); //update all
        else 
            this.props.update_crossover_txt(value, this.props.row_count);//update the row only
        
            

    }

    on_text_change = (e) => {
        var unicode = e.keyCode ? e.keyCode : e.charCode;
        if (unicode == 13) {
            e.preventDefault();
        }

        if (unicode == 38)//up key
        {
            var el = document.getElementById("yes_no_txt_" + (this.props.row_count - 1))

            if (el) {
                el.focus();
            }

        } else if (unicode == 40)// dow key
        {
            var el = document.getElementById("yes_no_txt_" + (this.props.row_count + 1))

            if (el) {
                el.focus();
            }
        }

        var batch_payment_state = { ...this.state.batch_payment_detail_item };
        let value = batch_payment_state.yes_no;
        if (unicode == 89) value = 'Y';
        if (unicode == 78) value = 'N';
        if (unicode == 13) value = 'N';
        var transaction_type = this.props.parent_state.transaction_type;
        batch_payment_state.yes_no = value;
        if (batch_payment_state.yes_no.toUpperCase() == 'Y') {
            var old_value = try_parse_float(batch_payment_state.pay_amount);
            if (transaction_type == 3) {
                batch_payment_state.pay_amount = 0;
                batch_payment_state.xfer_amount = batch_payment_state.owed_amount;
            } else {
                batch_payment_state.pay_amount = batch_payment_state.owed_amount;
                //batch_payment_state.xfer_amount = 0;
                batch_payment_state = this.calculate_row_data(batch_payment_state, 0);
            }
            var diff = try_parse_float(batch_payment_state.pay_amount) - try_parse_float(old_value);
            var shortage = (try_parse_float(this.props.charge_total.pay_amount) + diff) - try_parse_float(this.props.parent_state.payment_amount);

            if (shortage > 0) {
                this.props.show_yes_no_confirm_message_popup(shortage, this.props.row_count, false);
                if (this._is_mounted) {
                    this.setState({
                        batch_payment_detail_item: batch_payment_state
                    })
                }
            } else {
                if (this._is_mounted) {
                    this.setState({
                        batch_payment_detail_item: batch_payment_state
                    }, () => { this.props.update_total() })
                }
            }
            //  RecalcRow(rowIndex, false);
           
        } else {
            batch_payment_state.pay_amount = 0;
            //batch_payment_state.xfer_amount = 0;
            batch_payment_state = this.calculate_row_data(batch_payment_state, 0);
            if (this._is_mounted) {
                this.setState({
                    batch_payment_detail_item: batch_payment_state
                }, () => { this.props.update_total() })
            }
        }
        
    }
    on_close_confirmation = (shortage_amount,yes_no_close=true) => {
       //var s = { total_chg_amt: 0, owed_amount: 0, pay_amount: 0, allow_amount: 0, adj_amounts: 0, xfer_amount:0,xfer_det_amounts};
        var batch_payment_state = { ...this.state.batch_payment_detail_item };
        if (yes_no_close) {
            batch_payment_state.pay_amount = batch_payment_state.owed_amount;
            batch_payment_state.xfer_amount = 0;
        } else {
            batch_payment_state = this.calculate_row_data(batch_payment_state, 0);
        }
        if (this.props.page_type == enum_page_type.batch_payments) {
            if (try_parse_float(batch_payment_state.xfer_amount) > 0) {
                batch_payment_state.xfer_ins_code_id = this.get_next_payer();
            } else {
                batch_payment_state.xfer_ins_code_id = "";
            }
        }
        if (this._is_mounted) {
            this.setState({
                batch_payment_detail_item: batch_payment_state
            }, () => { this.props.update_total() })
        }
    }
    on_ok_click = async (shortage_amount,yes_no_close = true) => {
        var batch_payment_state = { ...this.state.batch_payment_detail_item };
        batch_payment_state.pay_amount = batch_payment_state.pay_amount - (shortage_amount)
        batch_payment_state.xfer_amount = batch_payment_state.owed_amount - batch_payment_state.pay_amount;
        if (this.props.page_type == enum_page_type.batch_payments) {
            if (try_parse_float(batch_payment_state.xfer_amount) > 0) {
                batch_payment_state.xfer_ins_code_id = this.get_next_payer();
            } else {
                batch_payment_state.xfer_ins_code_id = "";
            }
        } 
        if (this._is_mounted) {
            this.setState({
                batch_payment_detail_item: batch_payment_state
            }, () => { this.props.update_total()})
        }
    }

    calculate_row_data = (batch_payment_state, index, row_count = null) => {
        let isBatchPayment = this.props.page_type == enum_page_type.batch_payments,
            isBatchAdjustment = this.props.page_type == enum_page_type.batch_adjustment,
            isGroup = this.state.posting_type == enum_posting_type.group,
            isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry,
            isStandardPayment = this.state.posting_type == enum_posting_type.standard_payment,
            adjustment_control = isBatchAdjustment && isGroup
                ? document.getElementById(`${enum_selection_type.payment_adjust_input}${index}`) as HTMLInputElement 
                : document.getElementById(`${enum_selection_type.adjustment_input}${index}_${row_count}`) as HTMLInputElement,
            payment_control = document.getElementById(`${enum_selection_type.payment_input}_${row_count}`) as HTMLInputElement,
            owed_amount = try_parse_float(batch_payment_state.owed_amount),
            allowed_amount = try_parse_float(batch_payment_state.allow_amount),
            payment_amount = payment_control
                ? try_parse_currency_float(payment_control.value)
                : try_parse_float(batch_payment_state.pay_amount),
            current_adjustment_amount = adjustment_control && adjustment_control.value != ''
                ? try_parse_currency_float(adjustment_control.value)
                : null,
            // Default expected adjustment value, see https://help.billing.webpt.com/article/604-standard-payment
            adjustment_amount = current_adjustment_amount == null 
                ? owed_amount - allowed_amount
                : current_adjustment_amount;

        // Adjustment logic
        if (isBatchPayment &&
            isStandardPayment &&
            allowed_amount == 0 &&
            payment_amount == 0
        ) {
            if (current_adjustment_amount != null) {
                adjustment_amount = current_adjustment_amount;
            } else {
                adjustment_amount = owed_amount;
            }
        }

        if (isGroup) {
            adjustment_amount = 0;
        }

        batch_payment_state.adj_amounts[index].amount = try_parse_float(adjustment_amount.toString()).toFixed(2);

        // Transfer logic
        let xfer_amt = owed_amount - payment_amount - adjustment_amount;

        if (isMultipleEntry) {
            let total_adj = 0;
            for (var i = 0; i < batch_payment_state.adj_amounts.length; i++) {
                total_adj += try_parse_float(batch_payment_state.adj_amounts[i].amount);
            }
            xfer_amt = owed_amount - payment_amount - total_adj;
        }
        
        batch_payment_state.xfer_amount = try_parse_float(xfer_amt.toString()).toFixed(2);
        
        if (isBatchPayment) {
            batch_payment_state.xfer_ins_code_id = "";

            if (try_parse_float(batch_payment_state.xfer_amount) > 0) {
                batch_payment_state.xfer_ins_code_id = this.get_next_payer();
            }
        }

        if (this._is_mounted) {
            this.setState({
                batch_payment_detail_item: batch_payment_state
            }, () => { this.props.update_total()})
        }

        return batch_payment_state;
    }

    check_empty_row_data = (index, row_count, type) => {
        var allowed_control = document.getElementById(`${enum_amount_type.allowed}_${index}`) as HTMLInputElement;
        var payment_control = document.getElementById(`${enum_amount_type.payment}_${index}`) as HTMLInputElement;
        var adjustment_control = document.getElementById(`${enum_amount_type.adjustment}${index}_${row_count}`) as HTMLInputElement;
        if (allowed_control.value + payment_control.value + adjustment_control.value == '') {
            this.reset_row_data(index, row_count, type);
            return true;
        }
        return false;
    }

    reset_row_data = (index, row_count, type) => {

        var batch_payment_state = { ...this.state.batch_payment_detail_item };

        if (this.state.posting_type == enum_posting_type.multiple_entry) {
            for (var i = 0; i < 5; i++) {
                batch_payment_state.adj_amounts[i].amount = '';
                batch_payment_state.xfer_det_amounts[i].amount = '';
                batch_payment_state.adj_code_reason_ids[i].value = '0';
                batch_payment_state.adj_code_reason_ids[i].title = 'No Adjustment Code';
                batch_payment_state.adj_qualifier_ids[i].value = ' ';
                batch_payment_state.adj_qualifier_ids[i].title = 'NA';
            }
        } else {
            batch_payment_state.adj_amounts[index].amount = '';
        }
        batch_payment_state.allow_amount = '';
        batch_payment_state.pay_amount = '';
        batch_payment_state.xfer_amount = '';

        delete batch_payment_state.adjustment;

        if (this._is_mounted) {
            this.setState({
                batch_payment_detail_item: batch_payment_state
            }, () => {
                this.props.update_total(false, row_count, 'focus', type);
            })
        }

    }

    on_blur = (evt, type, index, amount_feild = false, row_count = null) => {
        let value = evt.currentTarget.value;

        if (value == '') {
            if (this.check_empty_row_data(index, row_count, type) == true) {
                return;
            }
        }

        if (amount_feild && value.indexOf('$') > -1) {
            value = value.replace('$', '');
        }
        var batch_payment_state = { ...this.state.batch_payment_detail_item };

        batch_payment_state.pay_amount = try_parse_float(batch_payment_state.pay_amount);
        batch_payment_state.allow_amount = try_parse_float(batch_payment_state.allow_amount);
        if (this.state.posting_type == enum_posting_type.multiple_entry) {
            batch_payment_state.adj_amounts[0].amount = try_parse_float(batch_payment_state.adj_amounts[0].amount);
            batch_payment_state.adj_amounts[1].amount = try_parse_float(batch_payment_state.adj_amounts[1].amount);
            batch_payment_state.adj_amounts[2].amount = try_parse_float(batch_payment_state.adj_amounts[2].amount);
            batch_payment_state.adj_amounts[3].amount = try_parse_float(batch_payment_state.adj_amounts[3].amount);
            batch_payment_state.adj_amounts[4].amount = try_parse_float(batch_payment_state.adj_amounts[4].amount);
        } else {
            batch_payment_state.adj_amounts[index].amount = try_parse_float(batch_payment_state.adj_amounts[index].amount);
        }

        if (type === enum_amount_type.allowed) {
            batch_payment_state.allow_amount = (batch_payment_state.allow_amount.toString() == '' && value == "") ? batch_payment_state.allow_amount:try_parse_float(value);
            batch_payment_state.pay_amount = try_parse_float(batch_payment_state.pay_amount);
            if (this.state.posting_type === enum_posting_type.multiple_entry) {
                if (!(try_parse_float(batch_payment_state.allow_amount) == 0 && try_parse_float(batch_payment_state.pay_amount) == 0)) {
                    batch_payment_state.adj_amounts[1].amount = 0;
                    batch_payment_state.adj_amounts[2].amount = 0;
                    batch_payment_state.adj_amounts[3].amount = 0;
                    batch_payment_state.adj_amounts[4].amount = 0;
                }
            }
            batch_payment_state = this.calculate_row_data(batch_payment_state, index, row_count);
        } else if (type === enum_amount_type.payment) {
            var old_value = try_parse_float(batch_payment_state.pay_amount);

            batch_payment_state.pay_amount = (old_value.toString() =="" && value == "") ? batch_payment_state.pay_amount:try_parse_float(value);
            var diff_value = try_parse_float(value) - try_parse_float(old_value);

            if (this.state.posting_type == enum_posting_type.group && batch_payment_state.pay_amount > 0) {
                var shortage = try_parse_float((try_parse_float(this.props.charge_total.pay_amount) + diff_value)) - try_parse_float(this.props.parent_state.payment_amount);
                // This logic does not make sense, if got this far, then posting_type = group, cannot be multiple entry.
                if (this.state.posting_type == enum_posting_type.multiple_entry) {
                    if (!(try_parse_float(batch_payment_state.allow_amount) == 0 && try_parse_float(batch_payment_state.pay_amount)==0)) {
                        batch_payment_state.adj_amounts[1].amount = 0;
                        batch_payment_state.adj_amounts[2].amount = 0;
                        batch_payment_state.adj_amounts[3].amount = 0;
                        batch_payment_state.adj_amounts[4].amount = 0;
                    }
                }
                if (shortage > 0) {
                    this.props.show_yes_no_confirm_message_popup(shortage, this.props.row_count, false);

                    if (this._is_mounted) {
                        this.setState({
                            batch_payment_detail_item: batch_payment_state
                        })
                    }
                    return;
                }
            }

            if (this.state.posting_type === enum_posting_type.multiple_entry) {
                if (!(try_parse_float(batch_payment_state.allow_amount) == 0 && try_parse_float(batch_payment_state.pay_amount) == 0)) {
                    batch_payment_state.adj_amounts[1].amount = 0;
                    batch_payment_state.adj_amounts[2].amount = 0;
                    batch_payment_state.adj_amounts[3].amount = 0;
                    batch_payment_state.adj_amounts[4].amount = 0;
                }
            }

            batch_payment_state = this.calculate_row_data(batch_payment_state, index, row_count);
            batch_payment_state = this.reset_adjustment_qualifier(batch_payment_state, index, row_count);
        }
        else if (type === enum_amount_type.adjustment) {
            if (this.props.page_type == enum_page_type.batch_payments && this.state.posting_type == enum_posting_type.multiple_entry) {
                this.batch_payment_multiple_entry_logic(index, row_count, value);
            }

            batch_payment_state = this.calculate_row_data(batch_payment_state, index, row_count);
            batch_payment_state = this.reset_adjustment_qualifier(batch_payment_state, index, row_count);
        }
        else if (type === enum_amount_type.transfer_det) {
            batch_payment_state.xfer_det_amounts[index].amount = value == "" ? batch_payment_state.xfer_det_amounts[index].amount:try_parse_float(value);

            if (this.state.posting_type != enum_posting_type.multiple_entry) {
                batch_payment_state = this.calculate_row_data(batch_payment_state, index, row_count);
            }
        }      

        if (this._is_mounted) {
            this.setState({
                batch_payment_detail_item: batch_payment_state
            }, () => {
                this.props.update_total(false, row_count, 'focus', type);
                this.check_insurance_required();
            })
        }

    }

    batch_payment_multiple_entry_logic = (index, row_count, adjustmentAmount) => {
        if (row_count == null) {
            return;
        }

        let rowTransferDetailControlId = `${enum_selection_type.transfer_input}${index}_${row_count}`,
            rowTransferDetailControl = document.getElementById(rowTransferDetailControlId) as HTMLInputElement;

        if (!rowTransferDetailControl) {
            return;
        }

        let isAdjustmentAmountNotEmpty = try_parse_float(adjustmentAmount) !== 0;
        
        let batch_payment_state = { ...this.state.batch_payment_detail_item }

        // If adjustment amount is entered, disable that row's transfer detail input
        rowTransferDetailControl.disabled = isAdjustmentAmountNotEmpty;

        // And clean it up
        if (isAdjustmentAmountNotEmpty) {
            let transfer_object = batch_payment_state.xfer_det_amounts[index];
            
            transfer_object.amount = '';
            batch_payment_state.xfer_det_amounts[index] = transfer_object;
        }
        this.setState({
            batch_payment_detail_item: batch_payment_state
        });
    };

    reset_adjustment_qualifier = (batch_payment_state, index, row_count) => {
        if (row_count == null) {
            return batch_payment_state;
        }

        let rowAdjustmentQualifierControlId = `${enum_selection_type.adjustment_qual}${index}${row_count}`,
            rowAdjustmentQualifierControl = document.getElementById(rowAdjustmentQualifierControlId);

        if (!rowAdjustmentQualifierControl) {
            return batch_payment_state;
        }

        let isBatchPayment = this.props.page_type == enum_page_type.batch_payments,
            isBatchAdjustment = this.props.page_type == enum_page_type.batch_adjustment,
            isGroup = this.state.posting_type == enum_posting_type.group,
            rowPaymentAdjustControl = document.getElementById(`${enum_selection_type.payment_adjust_input}${index}`) as HTMLInputElement,
            adjustment_control = isBatchAdjustment && isGroup
                ? document.getElementById(`${enum_selection_type.payment_adjust_input}${index}`) as HTMLInputElement 
                : document.getElementById(`${enum_selection_type.adjustment_input}${index}_${row_count}`) as HTMLInputElement,
            payment_control = document.getElementById(`${enum_selection_type.payment_input}_${index}`) as HTMLInputElement,
            selectedValueBasedOnAmount = {},
            adjustmentQualifierOptionsIndex = 4,
            contractualObligationsIndex = 0;

        if (!adjustment_control) {
            return batch_payment_state;
        }

        let rawAdjustAmount = adjustment_control.value == '' ? batch_payment_state.adj_amounts[index].amount.toString() : adjustment_control.value;
        if (rawAdjustAmount.indexOf('$') > -1) {
            rawAdjustAmount = rawAdjustAmount.replace('$', '');
        }

        // Adjustment field when group and batch adjustment is not working properly as its not updating the value.
        if (isBatchAdjustment && isGroup && !rowPaymentAdjustControl) {
            return batch_payment_state;
        }

        let rawPaymentAdjustAmount = isBatchAdjustment && isGroup ? rowPaymentAdjustControl.value : '';
        if (rawPaymentAdjustAmount.indexOf('$') > -1) {
            rawPaymentAdjustAmount = rawPaymentAdjustAmount.replace('$', '');
        }

        if (!payment_control && isBatchPayment) {
            return batch_payment_state;
        }

        let rawPaymentAmount = isBatchPayment ? payment_control.value : '';
        if (rawPaymentAmount.indexOf('$') > -1) {
            rawPaymentAmount = rawPaymentAmount.replace('$', '');
        }

        // Reset control highlight if it was already highlighted
        rowAdjustmentQualifierControl.className = 'mar-t-0 ';
        // Get CO, hardcoding would be easier but meh
        let contractualObligationsObject = this.props.batch_payment_reference.batch_reference
                .data[adjustmentQualifierOptionsIndex]
                .refrences[contractualObligationsIndex],
            isAdjustmentAmountZero = isBatchAdjustment && isGroup ? try_parse_float(rawPaymentAdjustAmount) === 0 : try_parse_float(rawAdjustAmount) === 0,
            isPaymentAmountZero = try_parse_float(rawPaymentAmount) === 0;

        if (isBatchAdjustment) {
            // Is amount is 0, default it to NA, otherwise to CO.
            if (isAdjustmentAmountZero) {
                selectedValueBasedOnAmount = { sequence: 0, value: ' ', title: 'NA' };
            } else {
                if (!isGroup) {
                    // CO or Contractual Obligations should be selected for anything != 0, value should be = to title as its how the DDL its formed
                    selectedValueBasedOnAmount = { sequence: contractualObligationsObject.sequence, value: contractualObligationsObject.value, title: contractualObligationsObject.value };
                }
            }
        }

        if (isBatchPayment) {
            // Is payment is 0 and adjustment is 0, default it to NA, otherwise to CO.
            if (isPaymentAmountZero && isAdjustmentAmountZero) {
                selectedValueBasedOnAmount = { sequence: 0, value: ' ', title: 'NA' };
            } else {
                // CO or Contractual Obligations should be selected for anything != 0, value should be = to title as its how the DDL its formed
                selectedValueBasedOnAmount = { sequence: contractualObligationsObject.sequence, value: contractualObligationsObject.value, title: contractualObligationsObject.value };
            }
        }

        batch_payment_state.adj_qualifier_ids[index] = selectedValueBasedOnAmount;

        return batch_payment_state;
    };

    get_next_payer = () => {
        let next_payer = "";
        const ins_list = this.state.batch_payment_detail_item.charge_insurance_list;
        let cur_ins = ins_list.filter(ins => ins.insurance_code_id == this.state.batch_payment_detail_item.from_ins_code_id)[0];       
        if (cur_ins) {
            if (cur_ins.pst_coverage == "P") {
                let cur_ins = ins_list.filter(ins => ins.pst_coverage == 'S')[0];
                if (!cur_ins) {
                    cur_ins = ins_list.filter(ins => ins.pst_coverage == 'T')[0];
                }
                if (!cur_ins) {
                    cur_ins = ins_list.filter(ins => ins.pst_coverage == 'Z')[0];
                }
                if (cur_ins) {
                    next_payer = cur_ins.insurance_code_id;
                }
            }
            if (cur_ins.pst_coverage == "S") {
                let cur_ins = ins_list.filter(ins => ins.pst_coverage == 'T')[0];
                if (!cur_ins) {
                    cur_ins = ins_list.filter(ins => ins.pst_coverage == 'Z')[0];
                }
                if (cur_ins) {
                    next_payer = cur_ins.insurance_code_id;
                }
            }
            if (cur_ins.pst_coverage == "T") {
                let cur_ins = ins_list.filter(ins => ins.pst_coverage == 'Z')[0];                
                if (cur_ins) {
                    next_payer = cur_ins.insurance_code_id;
                }
            }
            if (cur_ins.pst_coverage == "Z") {
                next_payer = cur_ins.insurance_code_id;
            }
        }
        return next_payer.toString();
    }


    check_insurance_required = () => {
        if (try_parse_float(this.state.batch_payment_detail_item.xfer_amount) == 0) {
            if (document.getElementById('transfer_insurance' + this.props.row_count)) {
                document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0';
            }
        } else if (try_parse_float(this.state.batch_payment_detail_item.xfer_amount) != 0) {
            if (this.props.parent_state && this.props.parent_state.is_submitted) {
                if (this.state.batch_payment_detail_item.xfer_ins_code_id.trim() == '') {
                    if (document.getElementById('transfer_insurance' + this.props.row_count)) {
                        document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0 dropdown-options-wrap req-background-inp ';
                    }
                } else {
                    if (document.getElementById('transfer_insurance' + this.props.row_count)) {
                        document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0';
                    }
                }
            }
        }
    }
    
    on_change_handler = (event, type, index, update_sibling_dropdown=false) => {
        var data = { ...this.state.batch_payment_detail_item };
        const { value } = event.target;
        let selected_text = event.target.selectedOptions
          ? event.target.selectedOptions[0].outerText
          : event.target.options[event.target.selectedIndex].text;
        let reason = { sequence: index, id: value, text: selected_text };

        let prop_name = '';
        let prop_text = '';
        let prop_value = '';
        var multi_row = false;
        let current_row_index = this.props.row_count;

        // Adjustment Code
        if (type == enum_selection_type.adjustment_code) {
            data.adj_code_reason_ids[index] = { "sequence": index, "value": value, title: selected_text };
            prop_name = 'adj_code_reason_ids';
            prop_value = data.adj_code_reason_ids[index];
            multi_row = true;
        } 
        // Transaction Code
        else if (type == enum_selection_type.transaction_code) {
            data.transfer_code_id = value;
            data.transfer_code_text = selected_text;

            prop_name = 'transfer_code_id';
            prop_value = data.transfer_code_id;
            prop_text = 'transfer_code_text';
        }
        // Adjustment Qualifier
        else if (type == enum_selection_type.adjustment_qual) {
            data.adj_qualifier_ids[index] = { sequence: index, value: value, title: selected_text };

           // data = this.qualifiers_validation(data, index);

            prop_name = 'adj_qualifier_ids';
            prop_value = data.adj_qualifier_ids[index];
            multi_row = true;
        }
        // Transfer to Insurance
        else if (type == enum_selection_type.transfer_insurance) {
            data.xfer_ins_code_id = value;
            data.xfer_ins_code_text = selected_text;
            if (this.props.parent_state && this.props.parent_state.is_submitted) {
                if (value.trim() == '') {
                    if (document.getElementById('transfer_insurance' + this.props.row_count)) {
                        if (try_parse_float(data.xfer_amount) != 0) {
                            document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0 dropdown-options-wrap req-background-inp ';
                        } else {
                            document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0';
                        }
                    }
                } else {
                    if (document.getElementById('transfer_insurance' + this.props.row_count)) {
                        document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0';
                    }
                }
            } else {
                if (document.getElementById('transfer_insurance' + this.props.row_count)) {
                    document.getElementById('transfer_insurance' + this.props.row_count).className = 'mar-t-0';
                }
            }

            prop_name = 'xfer_ins_code_id';
            prop_value = data.xfer_ins_code_id;
            prop_text = 'xfer_ins_code_text';
            //data.xfer_ins_code_id_selected = false;
        }
        // Paid By Insurance
        else if (type == enum_selection_type.from_insurance) {
            data.from_ins_code_id = value;
            data.from_ins_code_text = selected_text;
        }

        if (this._is_mounted) {
            this.setState({
            batch_payment_detail_item: data
            }, () => {
                if (this.state.posting_type != enum_posting_type.multiple_entry) {
                    if (update_sibling_dropdown) {
                        if (this.props.update_below_sibling_dropdown) {
                            this.props.update_below_sibling_dropdown(prop_name, prop_text, this.state.batch_payment_detail_item, current_row_index, index, multi_row);
                        }
                    }
                }
                });
        }
        //console.log(value, type);
    };

    transaction_code_master_data_update = (source) => {
        var target = [];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];

                target.push({
                    "title": item.value + ' - ' + item.title,
                    "value":  item.value,
                    "key": item.title
                })
            }
        }

        return target;
    }
    amount_change = (value, type, index) => {

    }

    on_key_down = (e, count, elem, val, adjustment_count, total_count) => {
        let required_elem_id = '',
            isGroup = this.state.posting_type == enum_posting_type.group,
            isBatchPayment = this.props.page_type == enum_page_type.batch_payments,
            isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry;
   

        if (e.which == 9 ) {
            required_elem_id = this.find_element(elem, val, count, e.shiftKey ? 'prev' : 'next', adjustment_count, total_count)
        }

        if ((e.which == 40 || e.which == 38) && !isGroup && (payment_field_type.indexOf(elem) != -1)) {
            if (e.which == 40) {
                if (elem == 'adjustment' || elem == 'transfer') {
                    if (isMultipleEntry) {
                        required_elem_id = (parseInt(val) == (parseInt(adjustment_count) - 1)) ? elem + '0_' + (count + 1).toString() : elem + (parseInt(val) + 1).toString() + "_" + count;
                    } else {
                        required_elem_id = elem + val + "_" + (count + 1).toString();
                    }
                } else {
                    required_elem_id = elem + "_" + (count + 1).toString();
                }
            } else if (e.which == 38) {
                if (elem == 'adjustment' || elem == 'transfer') {
                    if (isMultipleEntry) {
                        required_elem_id = (count == 0 && val == 0) ? null : val == 0 ? elem + (parseInt(adjustment_count) - 1).toString() + "_" + (count - 1).toString() : elem + (parseInt(val) - 1).toString() + "_" + count ;
                    } else {
                        required_elem_id = elem + val + "_" + (count - 1).toString();
                    }
                } else {
                    required_elem_id = elem + "_" + (count - 1).toString();
                }
            }
        }

        if ((e.which == 37 || e.which == 39) && !isGroup && payment_field_type.indexOf(elem) != -1) {
            required_elem_id = this.find_element_for_left_right_keys(elem, val, count, e.which);
        }

        if (required_elem_id && document.getElementById(required_elem_id)) {
            document.getElementById(required_elem_id).focus();
            e.preventDefault();
        }
        
        if (elem === enum_amount_type.allowed && (isBatchPayment && !isGroup)) {
            let adjustment_control = document.getElementById(`${enum_selection_type.adjustment_input}${val}_${count}`) as HTMLInputElement;

            if (adjustment_control) {
                adjustment_control.value = '';
            }
        }
    }

    find_element_for_left_right_keys = (currentElem, val, count, ele) => {
        let req_element = '',
            leftKey = 37,
            rightKey = 39,
            adjustmentElement = 'adjustment' + val + "_" + count,
            paymentElement = 'payment_' + count,
            transferElement = 'transfer' + val + "_" + count;

        if (currentElem === 'allowed') {
            req_element = ele === 37
                ? null
                : this.props.page_type === enum_page_type.batch_payments
                    ? paymentElement
                    : adjustmentElement;
        }

        if (currentElem === 'payment') {
            if (this.props.page_type === enum_page_type.batch_payments) {
                req_element = ele === rightKey ? adjustmentElement : paymentElement;
            }
        }
        if (currentElem === 'adjustment') {
            if (this.state.posting_type == enum_posting_type.multiple_entry) {
                req_element = ele === leftKey
                    ? val === 0
                        ? paymentElement
                        : null
                    : transferElement;
            } else {
                req_element = ele === rightKey
                    ? null
                    : this.props.page_type === enum_page_type.batch_payments
                        ? paymentElement
                        : adjustmentElement;
            }
        }
        if (currentElem === 'transfer') {
            req_element = ele === rightKey
                ? null
                : this.state.posting_type === enum_posting_type.multiple_entry
                    ? adjustmentElement
                    : null;
        }
        return req_element
    }

    find_element = (currentElem, val, count, type, total_adjustment_count, total_count) => {
        var nextElem = '';
        var prevElem = '';
        let isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry,
            adjustment_control = document.getElementById(`${enum_selection_type.adjustment_input}${count}_${val}`) as HTMLInputElement,
            adjustment_amount = adjustment_control ? try_parse_currency_float(adjustment_control.value) : 0,
            transferDetailControl = null,
            isTransferDetailDisabled = false,
            transferInsuranceElement = 'transfer_insurance' + count.toString(),
            paymentElement = 'payment_' + count.toString(),
            adjustmentElement = 'adjustment' + val + '_' + count,
            transferElement = 'transfer' + val + '_' + count,
            insuranceElement = 'insurance_' + count.toString(),
            allowedElement = `allowed_${count+1}`;

        if (isMultipleEntry) {
            this.batch_payment_multiple_entry_logic(val, count, adjustment_amount);
            transferDetailControl = document.getElementById(`${enum_selection_type.transfer_input}${val}_${count}`) as HTMLSelectElement;
            isTransferDetailDisabled = transferDetailControl ? transferDetailControl.disabled : false;
        }

        if (currentElem == 'adjustment') {
            // Next element
            if (this.state.posting_type == enum_posting_type.standard_payment) {
                nextElem = count === 0
                    ? transferInsuranceElement
                    : count !== (total_count - 1)
                        ? allowedElement 
                        : 'export_report_button';
            } else {
                if (isMultipleEntry && isTransferDetailDisabled) {
                    nextElem = count === 0
                        ? transferInsuranceElement
                        : count !== (total_count - 1)
                            ? allowedElement
                            : 'export_report_button';
                } else {
                    nextElem = transferElement;
                }
            }

            // Previous element
            prevElem = val === 0 ? paymentElement : 'adjustment_code' + (parseInt(val) - 1).toString() + count.toString();
        }
        else if (currentElem == 'transfer') {

            nextElem = val === 0 ? transferInsuranceElement : 'adjustment_qual' + val + count;
            prevElem = adjustmentElement;
        }
        else if (currentElem === 'transfer_insurance') {
            //nextElem = val == 0 ? transferInsuranceElement : 'adjustment_qual' + val + count;
            if (isMultipleEntry && isTransferDetailDisabled) {
                prevElem = adjustmentElement;
            } else {
                prevElem = transferElement;
            }
        }
        else if (currentElem === 'adjustment_qual') {
            if (this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage)
            {
                prevElem = 'txt_crossover_' + count;
                nextElem = 'adjustment_code' + val + count;
            }
            else
            {
                prevElem = val === 0 ? transferInsuranceElement : transferElement;
                nextElem = 'adjustment_code' + val + count;
            }
            
        }
        else if (currentElem === 'adjustment_code') {
            prevElem = 'adjustment_qual' + val + count;
            nextElem = val < total_adjustment_count ? 'adjustment' + (parseInt(val) + 1).toString() + '_' + count : null;
        }
        else if (currentElem === 'payment') {
            if (this.state.posting_type == enum_posting_type.multiple_entry) {
                prevElem = paymentElement;
            } else {
                prevElem = count === 0 ? paymentElement : null;
            }
            nextElem = adjustmentElement;
        }
        else if (currentElem === 'trans_code') {
            prevElem = count === 0 ? 'adjustment_code' + val + count : null;
            nextElem = count >= 0 ? allowedElement : null;
        }
        else if (currentElem == 'crossover' && this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage)
        {
            prevElem = val == 0 ? transferInsuranceElement : transferElement;
        }

        if (currentElem === 'allowed') {
            if (this.state.posting_type === enum_posting_type.multiple_entry) {
                prevElem = insuranceElement;
                nextElem = paymentElement;
            } else {
                prevElem = count === 0
                    ? insuranceElement
                    : count === 1
                        ? 'trans_code' + (count - 1).toString()
                        : 'adjustment' + val + '_' + (count - 1).toString();
                nextElem = this.props.page_type === enum_page_type.batch_payments
                    ? paymentElement
                    : adjustmentElement;
            }
        }
        if (type == 'next') {
            return nextElem;
        }
        else {
            return prevElem;
        }
    }
    render() {
        var charge_state = this.state.batch_payment_detail_item;
        var total_adjustments_count = charge_state.total_adjustment;
        let isPayments = this.props.page_type == enum_page_type.batch_payments,
            isGroup = this.state.posting_type == enum_posting_type.group;

        // Transfer amount logic
        let xfer_amount = (charge_state.xfer_amount != undefined && charge_state.xfer_amount.toString() != '')
                          ? currency_formatter(charge_state.xfer_amount)
                          : currency_formatter(charge_state.owed_amount);

        return (
            <Table.Row key={this.props.row_count}>
                <Table.Cell>{charge_state.charge_id}</Table.Cell>
                <Table.Cell>{charge_state.date_of_service}</Table.Cell>
                <Table.Cell>{charge_state.proc_code}</Table.Cell>
                {this.props.page_type == enum_page_type.batch_payments ? <Table.Cell className="text-right">{charge_state.units}</Table.Cell> : null}
                <Table.Cell className="text-right">{currency_formatter(charge_state.total_chg_amt)}</Table.Cell>
                <Table.Cell className="text-right">{currency_formatter(charge_state.owed_amount)}</Table.Cell>
                <Table.Cell>
                    {
                        this.state.posting_type == enum_posting_type.group ?
                            charge_state.from_ins_code_text :
                            <Selection
                                style='mar-t-0'
                                id={'insurance_' + this.props.row_count}
                                defaultValue={charge_state.from_ins_code_id}
                                options={charge_state.charge_insurance_list_from}                               
                                onChange={(value, e) => this.on_change_handler(e,"from_insurance", 0)}

                            />
                    }
                </Table.Cell>
                {
                    this.state.posting_type == enum_posting_type.group ?
                        <Table.Cell>
                            <Form.Input fluid className="group_yes_no"
                                autoComplete="new-password"
                                name='txt_yes_no' value={charge_state.yes_no || 'N'}
                                onKeyDown={e => { this.on_text_change(e) }} id={"yes_no_txt_" + this.props.row_count}  maxLength={1}
                                onFocus={e => { this.select_on_focus(e) }}
                            />
                        </Table.Cell> : null}
                {
                    this.state.posting_type == enum_posting_type.group ?
                        <Table.Cell>
                            <Form.Field>
                                <CurrencyFormatter
                                    className="text-right"
                                    onChange={(values) => { this.amount_change(values, "payment", 0) }}
                                    prefix='$'
                                    id={'payment_adjust' + this.props.row_count}
                                    maxLength={15}
                                    allowNegative={true}
                                    defaultValue={charge_state.pay_amount != undefined ? charge_state.pay_amount : ''}
                                    onBlur={(value) => { this.on_blur(value, "payment", 0, true, this.props.row_count) }}

                                />
                            </Form.Field>
                        </Table.Cell> : null
                }
                {
                    this.state.posting_type != enum_posting_type.group
                        ? <Table.Cell>
                            <Form.Field>
                                <CurrencyFormatter
                                    className="text-right"
                                    onChange={(values) => { this.amount_change(values, "allowed", 0) }}
                                    prefix='$'
                                    id={'allowed_' + this.props.row_count}
                                    maxLength={15}
                                    allowNegative={true}
                                    defaultValue={charge_state.allow_amount != undefined? charge_state.allow_amount.toString() : ''}
                                    onBlur={(value) => { this.on_blur(value, "allowed", 0, true, this.props.row_count) }}
                                    onKeyDown={(e) => { this.on_key_down(e, this.props.row_count, 'allowed', 0, total_adjustments_count, this.props.total_count) }}
                                    onFocus= {(e) => { this.select_on_focus(e, enum_amount_type.allowed, 0, this.props.row_count) } }
                                />

                            </Form.Field>
                          </Table.Cell>
                        : null
                }
                {
                    (this.props.page_type == enum_page_type.batch_payments &&  this.state.posting_type != enum_posting_type.group )?
                    <Table.Cell>
                        <Form.Field>
                            <CurrencyFormatter
                                className="text-right"
                                onChange={(values) => { this.amount_change(values, "payment", 0) }}
                                prefix='$'
                                id={'payment_' + this.props.row_count}
                                maxLength={15}
                                allowNegative={true}
                                defaultValue={charge_state.pay_amount != undefined ? charge_state.pay_amount.toString() : ''}
                                onBlur={(value) => { this.on_blur(value, "payment", 0, true, this.props.row_count) }}
                               
                            />

                        </Form.Field>
                    </Table.Cell>
                     : null
                }
                {
                    this.state.posting_type != enum_posting_type.group ?
                        <Table.Cell>
                            <Form.Field >{
                                Array.apply(null, Array(total_adjustments_count)).map((indx, val) =>

                                    <CurrencyFormatter className="batch_multiple  text-right"
                                        key={'adjustment' + val + '_' + this.props.row_count}
                                        onChange={(values) => { this.amount_change(values, "adjustment", val) }}
                                        prefix='$'
                                        id={'adjustment' + val + '_' + this.props.row_count}
                                        maxLength={15}
                                        allowNegative={true}

                                        defaultValue={charge_state.adj_amounts[val] && ((charge_state.adj_amounts[val].amount != undefined && charge_state.adj_amounts[val].amount.toString() != '') ? charge_state.adj_amounts[val].amount.toString() : '')}
                                        onBlur={(value) => { this.on_blur(value, "adjustment", val, true, this.props.row_count) }}
                                        onKeyDown={(e) => { this.on_key_down(e, this.props.row_count, 'adjustment', val, total_adjustments_count, this.props.total_count) }}
                                        onFocus={(e) => { this.select_on_focus(e, enum_amount_type.adjustment, val, this.props.row_count) } } 
                                    />
                                )
                            }
                            </Form.Field>
                        </Table.Cell>
                        : null
                }

                <Table.Cell className="text-right">{xfer_amount}</Table.Cell>
                {
                    this.state.posting_type == enum_posting_type.multiple_entry ?
                        <Table.Cell>
                            <Form.Field>{
                                Array.apply(null, Array(total_adjustments_count)).map((indx, val) =>
                                    <CurrencyFormatter className="batch_multiple  text-right"
                                        key={'transfer_' + val + '_' + this.props.row_count}
                                        onChange={(values) => { this.amount_change(values, "transfer_det", val) }}
                                        prefix='$'
                                        id={'transfer' + val + '_' + this.props.row_count}
                                        maxLength={15}
                                        allowNegative={true}                                        
                                        defaultValue={charge_state.xfer_det_amounts[val] && (charge_state.xfer_det_amounts[val].amount != undefined && charge_state.xfer_det_amounts[val].amount != '') ? charge_state.xfer_det_amounts[val].amount : ''}
                                        onBlur={(value) => { this.on_blur(value, "transfer_det", val, true) }}
                                        onKeyDown={(e) => { this.on_key_down(e, this.props.row_count, 'transfer', val, total_adjustments_count, this.props.total_count) }}
                                        onFocus={(e) => { this.select_on_focus(e) }}
                                        disabled={try_parse_float(charge_state.adj_amounts[val].amount) !== 0}
                                    />
                                )
                            }
                            </Form.Field>
                        </Table.Cell>
                        : null
                }
                <Table.Cell>

                    <Selection 
                        style='mar-t-0'
                        id={'transfer_insurance' + this.props.row_count}
                        defaultValue={charge_state.xfer_ins_code_id || ' '}
                        options={charge_state.charge_insurance_list_to_transfer}
                        onChange={(value, e) => this.on_change_handler(e, "transfer_insurance", 0,true)}
                        onKeyDown={(e) => { this.on_key_down(e, this.props.row_count, 'transfer_insurance', 0, total_adjustments_count, this.props.total_count) }}
                    />
                </Table.Cell>
                {
                    this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage ?
                    < Table.Cell >
                            <Form.Input fluid className="group_yes_no"
                                autoComplete="new-password"
                                name='txt_crossover'
                                value={charge_state.crossover || 'N'}
                                onKeyDown={e => { this.on_crossover_text_change(e) }}
                                id={"crossover_txt_" + this.props.row_count}
                                maxLength={1}
                            />
                        </Table.Cell> : null}
                {
                    isPayments && isGroup
                    ? null
                    : <Table.Cell>
                    {Array.apply(null, Array(total_adjustments_count)).map((indx, val) =>

                        <Selection style='mar-t-0'
                            key={'adjustment_qual' + val + this.props.row_count}
                            id={'adjustment_qual' + val + this.props.row_count}
                            defaultValue={charge_state.adj_qualifier_ids[val].value || 'CO'}
                            options={constants.option_formater(
                                this.props.batch_payment_reference.batch_reference.data[4].refrences,
                                'value',
                                'value',
                                true, // to add blank object
                                true, // to shorted object
                                'NA'
                            )}
                            onChange={(value, e) => this.on_change_handler(e, "adjustment_qual", val,true)}
                            onKeyDown={(e) => { this.on_key_down(e, this.props.row_count, 'adjustment_qual', val, total_adjustments_count, this.props.total_count) }}
                        />

                    )
                    }
                </Table.Cell>}
                {
                    isPayments && isGroup
                    ? null
                    : <Table.Cell>
                    {Array.apply(null, Array(total_adjustments_count)).map((indx, val) =>

                        <Selection style='mar-t-0 select_advance_reason'
                            key={'adjustment_code' + val + this.props.row_count}
                            id={'adjustment_code' + val + this.props.row_count}
                            defaultValue={charge_state.adj_code_reason_ids[val].value || '45'}
                            options={this.state.adjustment_code}
                            onChange={(value, e) => this.on_change_handler(e, "adjustment_code", val, true)}
                            onKeyDown={(e) => { this.on_key_down(e, this.props.row_count, 'adjustment_code', val, total_adjustments_count, this.props.total_count) }}
                        />
                    )
                    }                    
                </Table.Cell>}
                <Table.Cell>{charge_state.prov_name}</Table.Cell>
                {
                    this.state.posting_type != enum_posting_type.multiple_entry ?
                        <Table.Cell>
                            <Selection style='mar-t-0'
                                id={'trans_code' + this.props.row_count}
                                defaultValue={charge_state.transfer_code_id || '3'}
                                options={constants.option_formater(
                                    this.transaction_code_master_data_update(this.props.batch_payment_reference.batch_reference.data[2].refrences),
                                    'title',
                                    'value',
                                    false, // to add blank object
                                    false // to shorted object
                                )}

                                onChange={(value, e) => this.on_change_handler(e, "transaction_code", 0,true)}
                                {...(this.state.posting_type == enum_posting_type.standard_payment ? { onKeyDown: (e) => { this.on_key_down(e, this.props.row_count, 'trans_code', 0, total_adjustments_count, this.props.total_count) } } : {})}
                            />
                        </Table.Cell> : null
                }
            </Table.Row>
            )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        batch_payment_reference: state.batch_payment_reference,
        features: {
            krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage: get_lauch_darkley_key_value(state.launch_darkly, kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page)
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchPaymentRowDetailComponent));