import { render_inner_grid, printPDF, downloadCSV } from "../reports/util/export_print_utility";
import { get_link_value } from "../reports/util/report_data_formatter";
import * as global_constants from '../global_constants';
import * as dateFormat from 'dateformat';
import { print_html_data_to_pdf } from "../shared/utility";
import { saveAs } from '../shared/file-saver.min';
export const prepare_dropdown_data = (source, is_case_listing) => {
    var target = is_case_listing
        ? [
              {
                  text: '-- Select a Case --',
                  value: global_constants.constants.dropdown_default_info.value_string,
                  key: '-- Select a Case --'
              }
          ]
        : [
              {
                  text: '-- Please Select a Preauthorization --',
                  value: global_constants.constants.dropdown_default_info.value_string,
                  key: '-- Please Select a Preauthorization'
              }
          ];
    var source_length = source.length;

    if (source && source_length > 0) {
        for (let y = 0; y < source_length; y++) {
            let item = source[y];

            target.push({
                "text": item.description || item.display_auth,
                "value": item.id || item.value,
                "key": item.description || item.display_auth
            })
        }
    }

    return target;

}

/**
 * Case dropdown list data
 * @param cases
 */
export const prepare_case_options = (cases=[], type = '') => {
    let list = [{}]
    if (type && type == 'error_corrections' && cases && cases.length > 0){
        list = [{
            label: 'ALL',
            text: 'ALL',
            value: -1,// global_constants.constants.dropdown_default_info.value_int,
            key: 'ALL'
        }];
    }else{
        list = [{
            label: '-- Select a Case --',
            text: '-- Select a Case --',
            value: global_constants.constants.dropdown_default_info.value_string,
            key: '-- Select a Case --'
        }];
    }
    
    if (cases && cases.length > 0) {
        for (let i = 0; i < cases.length; i++) {
            let item = cases[i];
            list.push({
                label: item.description || '',
                text: item.description || '',
                value: item.id,
                key: item.id
            })
        }
    }
    return list;
}

export const custom_date_format = (date_value, format) => {
    if (date_value) {
        return dateFormat(date_value, format);
    }
    return "";
}
export const is_special_char = (code) => {
    var reg = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (code && reg.test(code)) {
        return true;
    }
    return false;
}

/**
 *  Print button functionality.
 * @param grid_ref
 * @param title
 */
export const hierarchy_grid_print_data = (grid_ref: { api: any; columnApi?: any }, title: string) => {
    let row_api = grid_ref.api;
    let col_api = grid_ref.columnApi;
    let header_data = col_api.getAllColumns();
    let first_col_width: number;
    let printContent = "";
    printContent += '<div class="reportPrint">';
    printContent += title;
    printContent += '<table id="mainGrid">';
    printContent += "<thead>";
    printContent += "<tr>";
    header_data.map((colData: { colDef: { width: any; hide: any; field: string; headerName: any } }, index: number) => {
        if (!colData.colDef.hide && colData.colDef.field !== "group") {
            if (index == 1) {
                first_col_width = Math.round(colData.colDef.width);
                printContent += `<th style="width: ${first_col_width}px;">${colData.colDef.headerName}</th>`;
            } else {
                printContent += `<th>${colData.colDef.headerName}</th>`;
            }
        }
    });
    printContent += "</tr></thead>";
    printContent += "<tbody>";
    if (row_api.getModel().getRowCount() > 0) {
        row_api.forEachNodeAfterFilterAndSort((node, index) => {
            node.data = {
                ...node.data
            };
            if (node.group) {
                printContent += `<tr>`;
                header_data.map((colData, index) => {
                    if (!colData.colDef.hide && colData.colDef.field !== "group") {
                        if (colData.colDef.type == "hyper_link") {
                            printContent += `<td>${get_link_value(node.data[colData.colDef.field])}</td>`;
                        } else if (node.data[colData.colDef.field]) {
                            printContent += `<td>${node.data[colData.colDef.field]}</td>`;
                        } else {
                            printContent += `<td></td>`;
                        }
                    }
                });
                printContent += "</tr>";
            } else {
                printContent += "</tr>";
                if (node.parent.expanded && node.data.updated_charge_details && node.data.updated_charge_details.length > 0) {
                    printContent += `
            <tr>
              <td colspan='${header_data.length}' style="padding:0;border-top:0;border-bottom:0;">
                ${render_inner_grid(node.data.inner_cols, node.data.updated_charge_details, first_col_width)}
              </td>
            </tr>
            `;
                }
            }
        });
    } else {
        printContent +=
            '<tr><td colspan="100%" class="no_records">' + global_constants.constants.no_records_found_message + "</td></tr>";
    }

    printContent += "</tbody>";
    printContent += "</table>";
    printContent += "</div>";

    print_html_data_to_pdf(printContent, "print_summary_button", title)
};

 


// Function calls on click of Export button

export const export_updated_charge_summary = (grid_ref, report_name: string) => {
    let csv = [];
    let row_api = grid_ref.api;
    let col_api = grid_ref.columnApi;
    let header_data = col_api.getAllColumns();
    let table_header = [];
    let table_rows = [];

    header_data.map((colData, index) => {
        if (!colData.colDef.hide && colData.colDef.field !== "group") {
            if (index == header_data.length - 1) {
                table_header.push(colData.colDef.headerName + "\n");
            } else {
                table_header.push(colData.colDef.headerName);
            }
        }
    });
    csv.push(table_header);
    if (row_api.getModel().getRowCount() > 0) {
        row_api.forEachNodeAfterFilterAndSort((data) => {
            data.data = {
                ...data.data
            };
            if (data.group) {
                header_data.map((colData, index) => {
                    if (!colData.colDef.hide && colData.colDef.field !== "group") {
                        if (data.data[colData.colDef.field]) {
                            if (index == header_data.length - 1) {
                                table_rows.push(data.data[colData.colDef.field] + "\n");
                            } else {
                                table_rows.push(data.data[colData.colDef.field]);
                            }
                        } else {
                            if (index == header_data.length - 1) {
                                table_rows.push("\n");
                            } else {
                                table_rows.push("");
                            }
                        }
                    }
                });
                csv.push(table_rows);
                table_rows = [];
            } else {
                if (data.parent.expanded && data.data.updated_charge_details) {
                    let sub_header = [];
                    sub_header.push(
                        "CPT Code",
                        "Charge ID",
                        "Charge Status",
                        "Item Name",
                        "Previous",
                        "New\n"
                    );
                    csv.push(sub_header);
                    data.data.updated_charge_details.map((value) => {
                        let sub_row = [];
                        sub_row.push(
                            `${value.cpt_code || ""}`,
                            `${value.charge_id || ""}`,
                            `${value.charge_status || ""}`,
                            `${value.item_name || ""}`,
                            `${value.previous_value || ""}`,
                            `${value.new_value || ""}\n`
                        );
                        csv.push(sub_row);
                    });
                }
            }
        });
    }

    let csvContent: any = csv.map((e) => {
        e = e.map((row) => {
            if (row && row != "" && row.toString().indexOf(",") > -1) {
                row = row.replace(",", " ");
            }
            return row;
        });
        return e.join(",");
    });

    let blob = new Blob(csvContent, {
        type: "text/csv;charset=utf-8"
    });

    //Blob.js
    saveAs(blob, report_name + ".csv");
};

// Function calls on click of print button
export const print_charge_detail = (print_data, title, report_name) => {
    let print_content = `<p class="review-charge-h4">${report_name}</p>`;
    print_content += `<p>${title}</p>`;
    print_content += print_data.map((value) => {
        return `<table cellpadding="4" cellspacing="0">
            <tbody>
                <tr>
                    <td>
                        <table cellpadding="4" cellspacing="0">
                            <thead>
                                <tr>
                                    ${
                                        value.header.map((item) => {
                                            return `<th ${item.align ? `class="${item.align}"` : ''}>${item.name}</th>`;
                                        }).join("")
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                ${
                                    value.header.map((item) => {
                                        return `<td ${item.align ? `class="${item.align}"` : ''}>${value[item.field]}</td>`;
                                    }).join("")
                                }                                    
                                </tr>
                            </tbody>
                        </table>
                        <table cellpadding="4" cellspacing="0">
                            <thead>
                                <tr>
                                    ${
                                        value.subHeader.map((subItem) => {
                                            return `<th ${subItem.align ? `class="${subItem.align}"` : ''}>${subItem.name}</th>`;
                                        }).join("")
                                    }
                                </tr>
                            </thead>
                            <tbody>                                 
                                ${
                                    value.charges.map((chargeValue) => {
                                            return `<tr>
                                                ${value.subHeader.map((item) => {
                                                        return `<td ${item.align ? `class="${item.align}"` : ''}>${chargeValue[item.field]}</td>`;
                                                }).join("")} 
                                            </tr>`;
                                    }).join("")
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>`;
    }).join("");

    // Print functionlity start
    print_html_data_to_pdf(print_content, "charge_print_btn", report_name)
};

// Function calls on click of Export button
export const export_charge_detail = (export_data, title: string, report_name: string) => {
    let csv = [];
    let table_header = [];
    let table_rows = [];
    let table_sub_rows = [];
    let title_row = [];
    title_row.push(title);
    csv.push(title_row);

    export_data.map((value) => {
        // Header data
        value.header.map((item) => {
            table_header.push(`"${item.name}"`);
        });
        csv.push(table_header);
        table_header = [];

        // First level row Data
        value.header.map((item) => {
            table_rows.push(`"${value[item.field]}"`);
        });
        csv.push(table_rows);
        table_rows = [];

        // Second level header Data
        value.subHeader.map((subItem) => {
            table_rows.push(`"${subItem.name}"`);
        });
        csv.push(table_rows);
        table_rows = [];

        // Second level row Data
        value.charges.map((chargeValue) => {
            value.subHeader.map((item) => {
                table_rows.push(`"${chargeValue[item.field]}"`);
            });
            csv.push(table_rows)
            table_rows = [];
        }).join('');
    });
    // Download CSV File
    downloadCSV(csv, report_name);
};