import * as dateFormat from 'dateformat';
import React from 'react';
import * as moment from 'moment';
import { toastr } from 'react-redux-toastr';
import * as global_constants from '../global_constants';
import * as local_storage from './local_storage_utility';
import * as session_storage from './session_storage_utility';
import { setTimeout } from 'timers';
import LaunchDarkley from './launch_darkley/launch_darkley';
import { decompressFromUTF16 } from './lz-string';
import { refresh_token, set_active_patient, get_master_data, get_master_data_by_type } from '../login/action/login_action';
import { MasterData } from '../login/login_constants';
import { remove_r2_url_from_store } from './action/shared_action';
var pendoInit = require('../../../config/pendo_init');

// getClasses (e.g. element.classList)
export const getClasses = (element: { className: string }) => {
  return element.className.split(/\s+/);
};

export const hasClass = (elem, className) => {
  return new RegExp(" " + className + " ").test(" " + elem.className + " ");
};

// containsClass (e.g. element.classList.contains())
export const containsClass = (element: { className: any }, className: string) => {
  return element.className.indexOf(className) > -1;
};

// addClass (e.g. element.classList.add())
export const addClass = (elem, className) => {
  if (!hasClass(elem, className)) {
    elem.className += " " + className;
  }
};

// removeClass (e.g. element.classList.remove())
export const removeClass = (elem, className) => {
  var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
  if (hasClass(elem, className)) {
    while (newClass.indexOf(" " + className + " ") >= 0) {
      newClass = newClass.replace(" " + className + " ", " ");
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, "");
  }
};

// toggleClass (e.g. element.classList.toggle())
export const toggleClass = (elem, className) => {
  var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
  if (hasClass(elem, className)) {
    while (newClass.indexOf(" " + className + " ") >= 0) {
      newClass = newClass.replace(" " + className + " ", " ");
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, "");
  } else {
    elem.className += " " + className;
  }
};

export const checkboxElementById = (id: string) => {
    return {
        [`checkbox_${id}`]: document.querySelector(`.ag-cell-label-container.checkbox #${id}`) as HTMLInputElement,
        [`checked_${id}`]: document.querySelector(`.ag-cell-label-container.checkbox #checked_${id}`) as HTMLElement,
        [`unchecked_${id}`]: document.querySelector(
            `.ag-cell-label-container.checkbox #unchecked_${id}`
        ) as HTMLElement,
        [`indeterminate_${id}`]: document.querySelector(`.ag-cell-label-container.checkbox #indeterminate_${id}`)
    };
};

// toggleClass (e.g. element.classList.toggle())
export const replaceClass = (element: { className: any }, className: string, newClassName: string) => {
    return containsClass(element, className) ? removeClass(element, className) : addClass(element, newClassName);
};

// getAge function takes one argument as dateString (string format like "1922-12-31T00:00:00") and return age of a patient like 38 etc.~~
export const get_age = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const get_age_months = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let birthDate_month = birthDate.getMonth() + 1;
    let today_month = today.getMonth() + 1;

    if (today_month >= birthDate_month) {
        return (today_month - birthDate_month);
    }
    else {
        return (12 + today_month - birthDate_month);
    }
}

// formatDate function takes two argument first date (string format like "1922-12-31T00:00:00") and second isTimeInclude (boolean) Optional.
// Return date as MM / DD / YYYY format. if isTimeInclude = true it will also return time.
export const format_date = (date, isTimeInclude = false, isdashFormat = false) => {
    var finaldate = '';
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var month = date.getMonth() + 1;
    var day = date.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    if (isTimeInclude) { finaldate = month + (isdashFormat ? "-" : "/") + day + (isdashFormat ? "-" : "/") + date.getFullYear() + "  " + strTime; }
    else { finaldate = month + (isdashFormat ? "-" : "/") + day + (isdashFormat ? "-" : "/") + date.getFullYear() }
    return finaldate;
}

// phone_format is used to format fax and phone number
// phone_format function takes one argument 'number'
// Returns data in the format '(353) - 353-5353'.
export const phone_format = (number) => {
    var updateNumber = ("" + number).replace(/\D/g, '');
    var isFormated = updateNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return ((!isFormated) ? number : "(" + isFormated[1] + ") " + isFormated[2] + "-" + isFormated[3]);
}

//format Address 
export const address_format = (address, isIncludeAddress2, master_data) => {
    isIncludeAddress2 = (isIncludeAddress2 == undefined || isIncludeAddress2 == null) ? true : isIncludeAddress2;

    // Find the current state from master data
    var selected_state = '';
    if (address.state_id) {
        var selected_state_obj = master_data.states.find(i => i.id === address.state_id)
        if (selected_state_obj) {
            selected_state = selected_state_obj.name;
        }
    }

    // Find the current country from master data
    var selected_country = '';
    if (address.country_id) {
        var selected_country_obj = master_data.country.find(i => i.id === address.country_id)
        if (selected_country_obj) {
            selected_country = `${selected_country_obj.name}`;
        }
    }

    var fullAddress = ''
    if (address != null) {
        fullAddress += address.address1 ? address.address1 : '';
        fullAddress += ' ';
        if (isIncludeAddress2) {
            fullAddress += address.address2 ? address.address2 : '';
            fullAddress += ' ';
        }
        fullAddress += address.city ? address.city + ', ' : '';
        if (address.country_id && address.country_id.toString() == '1') {
            fullAddress += selected_state;
            fullAddress += ' ';
            fullAddress += address.zip ? address.zip + ', ' : '';
        }
        else {
            fullAddress += address.province ? address.province + ', ' : '';
            fullAddress += ' ';
            fullAddress += address.postal_code ? address.postal_code + ', ' : '';
        }

        fullAddress += selected_country
    }
    return fullAddress;
}


export const custom_date_format = (date_value, format) => {
    if (date_value) {
        return dateFormat(date_value, format);
    }
    return "";
}

export const date_format_with_no_time = (date_value) => {
    if (date_value) {
        // Using parseZone prevents the library from calculating the timezone difference before sending the date to the server.
        return moment.parseZone(date_value).startOf('day');
    }
    return null;
}

export const date_format_with_no_time_return_string_or_null = (date_value) => {
    if (date_value) {
        // Using parseZone prevents the library from calculating the timezone difference before sending the date to the server.
        return moment.parseZone(date_value).toISOString().split('T')[0];
    }
    return null;
}

// phone_format is used to format fax and phone number
export const format_dashes_number = (number) => {
    var new_number = number;
    if (number.length > 2) {
        // matches: 123 || 123-4 || 123-45
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
            // matches: 123-4 || 123-45
            new_number += number.substr(3);
        }
        else if (number.length > 5) {
            // matches: 123-456 || 123-456-7 || 123-456-789
            new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
            // matches: 123-456-7 || 123-456-789 || 123-456-7890
            new_number += number.substring(6);
        }
        return new_number;
    }
}

// Patient case formate 
export const caseFormat = (caseHeader) => {
    if (caseHeader.start_of_care_date != null && (caseHeader.description != null && caseHeader.description.trim() != '')) {
        return format_date(new Date(caseHeader.start_of_care_date)) + " - " + caseHeader.description;
    }
    else if (caseHeader.start_of_care_date != null && (caseHeader.description == null || caseHeader.description.trim() == '')) {
        return format_date(new Date(caseHeader.start_of_care_date));
    }
    else if (caseHeader.start_of_care_date == null && (caseHeader.description != null && caseHeader.description.trim() != '')) {
        return caseHeader.description;
    }
    else {
        if (caseHeader.case_effective_date) {
            return "[EffectiveDate -" + format_date(new Date(caseHeader.case_effective_date)) + "]";
        }
        return "[EffectiveDate -]";
    }
}

export const get_all_error = (errors) => {
    var messages: any = "";
    if (errors) {
        if (errors.messages != undefined || errors.message != null) {
            messages = errors.messages != undefined ? errors.messages : errors.message;
        }
        else {
            if (errors.data) {
                messages = errors.data.messages;
            }
        }
    }
    var totalError = "";
    var messages_length = messages.length
    if (messages != "" && messages != undefined && messages_length > 0) {
        for (let k = 0; k < messages_length; k++) {
            var value = messages[k];
            var errorValue = value.message.split(':');
            totalError += messages.length == 1 ? errorValue[errorValue.length - 1] : '<ul><li>' + errorValue[errorValue.length - 1] + '</li></ul>';
        }
        return totalError;
    }

};
export const get_multiple_errors = function (errors) {

    var total_error = "";
    if (errors != "" && errors != undefined && errors.length > 0) {
        if (errors.length == 1) {
            total_error = errors[0];
        }
        else {
            for (let k = 0; k < errors.length; k++) {
                var error_value = errors[k];
                total_error += '<ul><li>' + error_value + '</li></ul>';
               
            }
            
        }
        return total_error;
    }

};
// Unmask Phone and SSN
export const unmask = (phone) => {
    var valueWithoutMaskChars = phone.replace(/\D+/g, '')
    return valueWithoutMaskChars;
}

// SSN Format
export const fixSSN = (str) => {
    var str: any = new String(str);
    str = str.replace(/[A-Za-z$-()\.]/g, '');
    str = str.replace(/[^0-9]/g, '');
    str = str.replace(/[^\d]/g, '');
    str = str.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    //alert(str);
    return str;
}

// patient formatted name 
export const format_patient_name = (name) => {

    return (name.first_name == null ? '' : name.first_name) + (name.middle_initial == null ? '' : ' ' + name.middle_initial) + (name.last_name == null ? '' : ' ' + name.last_name) + (name.suffix == null ? '' : ' ' + name.suffix);
}


// Get current Patient primary phone type from master data
export const phone_primary = (primary, types) => {
    var selected_type = types.find(i => i.id === primary)
    if (selected_type) {
        return selected_type.name;
    }

    return '';
}

// Get current Patient reminder type from master data
export const reminder_type = (reminder_type, reminder_types) => {
    var selected_type = reminder_types.find(i => i.id === reminder_type)
    if (selected_type) {
        return selected_type.name;
    }

    return '';
}

// Get current Patient gender format from master data
export const format_gender = (gender, genders) => {
    var selected_gender = genders.find(i => i.id === gender)
    if (selected_gender) {
        return selected_gender.name;
    }

    return '';

}

// Get current marital status from master data
export const format_marital_status = (status, statuses) => {
    var selected_status = statuses.find(i => i.id == status)
    if (selected_status) {
        return selected_status.name;
    }

    return '';

}

// Get currentlangauge from master data
export const format_spoken_language = (language, languages) => {
    var selected_language = languages.find(i => i.id === language)
    if (selected_language) {
        return selected_language.name;
    }

    return '';
}


export const format_patient_medicare_cap_speciality = (specialty_id, med_cap_specialty) => {
    var medicare_cap_speciality = med_cap_specialty.find(i => i.id === specialty_id.toString())
    if (medicare_cap_speciality) {
        return medicare_cap_speciality.name;
    }

    return '';
}
function formatNumber(number) {
    // this puts commas into the number eg 1000 goes to 1,000,
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    //Math.floor(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const currency_formatter = (params) => {
    var format_number = '';
    if (!(params == null || params == undefined) && !isNaN(params)) {
        params = Number(params);
        if (params < 0) {
            format_number = '($' + formatNumber((params * (-1)).toFixed(2)) + ')';
        }
        else {
            format_number = '$' + formatNumber(params.toFixed(2));
        }
    }
    return format_number;
    //TO DO for negative values
}

export const amount_formatter = (params) => {
    var format_number = '';
    if (params !== null && params !== undefined) {
        if (params < 0) {
            return format_number = `-$${formatNumber((params * (-1)).toFixed(2))}`;
        }
        return format_number = `$${formatNumber(params.toFixed(2))}`;
    }
}
export const amount_formatter_without_dollar_sign = (params) => {
    var format_number = '';
    if (params !== null && params !== undefined) {
        if (params < 0) {
            return format_number = `-${formatNumber((params * (-1)).toFixed(2))}`;
        }
        return format_number = `${formatNumber(params.toFixed(2))}`;
    }
}
export const amount_unformatter = (params) => {
    var format = '';
    if (params) {
        params = params.toString();
        format = params.replace(/[$,(,)]/g, '');
    }
    return format;
}
// to check whether viewport is device or desktop
export const is_mobile = () => {

    // Handling check Apple Devices
    // checks if window.orientation exists, 
    // because desktop computers and laptops didn't have it 
    // returns true on mobile devices
    if(navigator && /Mac|iPhone|iPod|iPad/i.test(navigator.platform)) {
       return (typeof window.orientation !== "undefined");
    }

    // handling mobile check for rest of the devices
    let is_mobile_device: boolean = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        is_mobile_device = true;
    } else {
        is_mobile_device = false;
    }
    return is_mobile_device;
}

// Get Content Area Dimension on page resize
export const getContentAreaDimension = () => {
    let appHeaderHeight = 0;
    let appPageTitleStickyHeight = 0;
    let appPatientHeaderStickyHeight = 0;
    let appFooterStickyHeight = 0;
    let viewPortHeight = 0;
    let appScrollOffsetHeight = 0;
    let appScrollableArea = document.getElementById("scrollable-content-area")

    // Get viewPort's height (windows or mobile device)
    if (is_mobile()) {
        //viewPortHeight = screen.height;
        viewPortHeight = window.innerHeight;
    } else {
        viewPortHeight = window.innerHeight;
    }

    // Get dimension for sticky section in the application

    let appFooterSticky = document.getElementById("applicationFooterSticky");
    if (appFooterSticky)
        appFooterStickyHeight = document.getElementById("applicationFooterSticky").scrollHeight;

    document.getElementById("scrollable-content-area") ? appScrollOffsetHeight = document.getElementById("scrollable-content-area").offsetTop : "";

    // calculate all sticky sections height
    let appScrollableContentHeight = viewPortHeight - (appScrollOffsetHeight + appFooterStickyHeight);

    // update scrollable area height
    document.getElementById("scrollable-content-area") ? (document.getElementById("scrollable-content-area").style.height = appScrollableContentHeight + "px") : "";
    //var forIPad = appScrollOffsetHeight.toString() + " < Offset ViewPort> " + viewPortHeight.toString() + " ScrollabeArea >" + appScrollableContentHeight.toString()
    return appScrollableContentHeight;
}

// get available content area dimension on ag-grid resize
export const get_dimension_container = () => {
    let appHeaderHeight = 0;
    let appPageTitleStickyHeight = 0;
    let appPatientHeaderStickyHeight = 0;
    let appFooterStickyHeight = 0;
    let viewPortHeight = 0;
    let appScrollOffsetHeight = 0;
    let appScrollableArea = document.getElementById("scrollable-content-area")

    // Get viewPort's height (windows or mobile device)
    if (is_mobile()) {
        //viewPortHeight = screen.height;
        viewPortHeight = window.innerHeight;
    } else {
        viewPortHeight = window.innerHeight;
    }

    // Get dimension for sticky section in the application

    let appFooterSticky = document.getElementById("applicationFooterSticky");
    if (appFooterSticky)
        appFooterStickyHeight = document.getElementById("applicationFooterSticky").scrollHeight;

    document.getElementById("scrollable-content-area") ? appScrollOffsetHeight = document.getElementById("scrollable-content-area").offsetTop : "";
    // calculate all sticky sections height
    let appScrollableContentHeight = viewPortHeight - (appScrollOffsetHeight + appFooterStickyHeight);
    //var forIPad = appScrollOffsetHeight.toString() + " < Offset ViewPort> " + viewPortHeight.toString() + " ScrollabeArea >" + appScrollableContentHeight.toString()
    //alert(screen.height + " SH  WIH " + window.innerHeight + " screenAvailHeight " + screen.availHeight + " WinOutHeight " + window.outerHeight);
    return appScrollableContentHeight;
}

// convert R2 URL for creating R6 URL
export const r2_decode_url = (r2_url, selected_patient = undefined, selected_company = undefined) => {
    // add selected patient and company id
    if (selected_patient) {
        r2_url = r2_url.replace("{SelectedPatient}", selected_patient.toString());
    }
    if (selected_company) {
        r2_url = r2_url.replace("{SelectedCompany}", selected_company.toString());
    }
    r2_url = r2_url.split("=")[1] // As there are some R2 embed urls in db as 'redirectUrl' or 'RedirectURL'
    //r2_url = r2_url.replace(/RedirectUrl=/g, '');
    r2_url = r2_url.replace(/%2b/g, "+");
    r2_url = r2_url.replace(/%2f/g, "/");
    r2_url = r2_url.replace(/%3d/g, "=");
    r2_url = r2_url.replace(/%3f/g, "?");
    r2_url = r2_url.replace(/%2e/g, ".");
    r2_url = r2_url.replace(/%26/g, "&");
    return r2_url;
}

// Form url pathname by replacing Blank spaces to underscore 
export const r2_form_path = (name) => {
    return name.replace(/ /g, "_").toLowerCase();
}

export const coming_soon = () => {
    toastr.info('', 'Coming soon');
}

export const check_valid_npi = (npi) => {
    if (npi == null)
        return false;

    var sum = 0;

    var length = npi.length;
    if ((length == 15) && (npi.indexOf("80840", 0, 5) == 0)) sum = 0;
    else if (length == 10) sum = 24;
    else {
        return false;
    };

    var currentDigit = 0;
    var currentPos = length;
    var j = 0;

    while (currentPos != 0) {
        if (isNaN(npi[currentPos - 1]))
            return false;

        currentDigit = npi[currentPos - 1] - 0;

        if ((j++ % 2) != 0) {
            if ((currentDigit <<= 1) > 9) {
                currentDigit -= 10;
                currentDigit++;
            }
        }
        sum += currentDigit;
        currentPos--;
    }

    var isValid = (sum % 10) == 0;
    if (isValid == true && length == 10) {
        return true;
    }
    else {
        return false;
    }

};
export const format_zip_code = (zip_code) => {
    if (zip_code) {
        var zip_length = zip_code.length;
        var final_zip_code = zip_code;
        if (zip_code != "" && zip_code != undefined && zip_length > 0) {
            if (zip_length > 5) {
                final_zip_code = zip_code.substring(0, 5) + '-' + zip_code.substring(5, 9);
            }

        }
    }
    return final_zip_code;
}
export const zip_code_formation = (zip_code) => {
    if (zip_code) {
        var zip_length = zip_code.length;
        var final_zip_code = zip_code;
        if (zip_code != "" && zip_code != undefined && zip_length > 0) {
            if (zip_length > 5 && zip_length <= 9) {
                final_zip_code = zip_code.substring(0, 5) + '-' + zip_code.substring(5, zip_length);
            }

        }
    }
    return final_zip_code;
}
export const generate_guid = () => {
    var d = new Date().getTime();
    var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return guid;
};

//check to show or hide patient header
export const show_patient_header_on_current_page = (current_path) => {
    var show_patient_header = true;

    //not show patient header in R2 emaded page
    if (current_path.indexOf(global_constants.end_points.base_emaded_url) >= 0) {
        show_patient_header = false;
        //show patient header in R2 pages
        global_constants.r2_page_with_patient_header.map((path, index) => {
            if (current_path.indexOf(path) >= 0) {
                show_patient_header = true;
                return show_patient_header;
            }
        });       
        return show_patient_header;
    }
    //not showing patient header in admin pages.
    global_constants.payments_page_with_no_patient_header.map((path, index) => {
        if (current_path.indexOf(path) >= 0) {
            show_patient_header = false;
            return show_patient_header;
        }
    });

    for (let item of global_constants.no_patient_header) {
        if (global_constants.end_points.base_app_url + item == current_path) {
            show_patient_header = false;
            break;
        }
    }
    return show_patient_header;
}
export const show_header = (current_path) => {
    var show_header = true;
    for (let item of global_constants.no_header) {
        if (global_constants.end_points.base_app_url + item == current_path) {
            show_header = false;
            break;
        }
    }
    return show_header;
}
//check to show empty patient header on R2 embed pages
export const show_empty_patient_header_on_current_page = (current_path) => {
    var show_patient_header = false;

    global_constants.show_empty_patient_header.map((path, index) => {
        if (current_path.indexOf(path) >= 0) {
            show_patient_header = true;
            return show_patient_header;
        }
    });
    return show_patient_header;

}

//check to clear patient id/info
export const clear_patient_header_info = (current_path) => {
    var clear_patient_id = false;
    for (let item of global_constants.clear_patient_header) {
        if (global_constants.end_points.base_app_url + item == current_path) {
            clear_patient_id = true;
            break;
        }
    }
    return clear_patient_id;

}

//check to to navigate on patient view if patient changes from header 
export const nevigate_to_patient_view = (current_path) => {
    var nevigate_to_patient = true;
    for (let item of global_constants.not_navigate_patient_view) {
        if (global_constants.end_points.base_app_url + item == current_path) {
            nevigate_to_patient = false;
            break;
        }
    }
    return nevigate_to_patient;

}
////To validate the policy number in medicare for ch class
//export const valid_policy_number_ch = function (policy_no) {
//    var value = false;
//    var numeric_regxp_nine = /^([a-zA-Z0-9_-]){9,9}$/;
//    var numeric_regxp_eleven = /^([a-zA-Z0-9_-]){11,11}$/;
//    value = numeric_regxp_nine.test(policy_no);
//    if (!value) {
//        value = numeric_regxp_eleven.test(policy_no);
//    }
//    return value;
//}
export const is_valid_value = function (key) {
    var is_valid = false;
    is_valid = !(key == undefined || key == null || key == '');
    return is_valid;
}
//To validate the policy number in medicare
export const valid_policy_number_mc = function (policy_no) {
    var value = false;
    if (policy_no.length >= 10) {
        var numeric_digits = policy_no.substring(0, 9);
        var numeric_regxp = /^([0-9_-]){9,11}$/;
        var alpha_betic_regxp = /^[A-z]+$/;
        if (numeric_regxp.test(numeric_digits)) {
            var alpha_betic_value = policy_no.substring(9, 10);
            value = alpha_betic_regxp.test(alpha_betic_value);
        }
    } else {
        value = false;
    }
    return value;
}
//To validate the policy number in medicare new
export const valid_policy_number_new = function (policy_no) {
    var value = true;
    if (policy_no && policy_no.length > 0) {
        if (policy_no.length == 11) {
            //var regxp = /^([1-9]{1}(?:(?=[^SLOIBZ])[A-Z]){1}(?:(?=[^SLOIBZ])[A-Z0-9]){1}[0-9]{1}(?:(?=[^SLOIBZ])[A-Z]){1}(?:(?=[^SLOIBZ])[A-Z0-9]){1}[0-9]{1}(?:(?=[^SLOIBZ])[A-Z]){2}[0-9]{2})$/;
            var regxp = /^[1-9]{1}(?:(?=[^SLOIBZ])[A-Z]){1}(?:(?=[^SLOIBZ])[A-Z0-9]){1}[0-9]{1}(?:(?=[^SLOIBZ])[A-Z]){1}(?:(?=[^SLOIBZ])[A-Z0-9]){1}[0-9](?:(?=[^SLOIBZ])[A-Z]){2}[0-9]{2}$/;
            value = regxp.test(policy_no);
        }
        else
            value = false;
    }
    else {
        value = false;

    }

    return value;
}

//To validate the policy number in CH
export const valid_policy_number_ch = (policy_no) => {
    var value = true;
    if (policy_no) {
        var tricare_format_regxp = /^[a-zA-Z0-9]{9}([a-zA-Z0-9]{2})?$/;
        if (!tricare_format_regxp.test(policy_no)) {
            value = false;
        }
    }
    return value;
}

////To validate the policy number in medicare for rail road class type
//export const valid_policy_number_rr = function (policy_no) {
//    var value = false;
//    if (policy_no.length >= 10) {
//        var alpha_betic_regxp = /^[A-z]+$/;
//        var numeric_regxp = /^([a-zA-Z0-9_-]){9,11}$/;
//        var alpha_betic_value = policy_no.substring(0, 1);
//        if (alpha_betic_regxp.test(alpha_betic_value)) {
//            var numeric_digits = policy_no.substring(1, 10);
//            value = numeric_regxp.test(numeric_digits);
//        }
//    } else {
//        value = false;
//    }
//    return
//    value;
//}

//To validate the policy number in medicare for rail road class type
export const valid_policy_number_rr = function (policy_no) {
    var validate = true;
    if (!is_null_or_white_space(policy_no)) {
        if (policy_no.length >= 7 && policy_no.length <= 12) {
            if (!is_all_letters(policy_no.substring(0, 4)) && (is_all_letters(policy_no.substring(0, 3)) || is_all_letters(policy_no.substring(0, 2)) || is_all_letters(policy_no.substring(0, 1)))) {
                var suffix = "";

                if (is_all_letters(policy_no.substring(0, 3))) suffix = policy_no.substring(3, policy_no.length);
                else if (is_all_letters(policy_no.substring(0, 2))) suffix = policy_no.substring(2, policy_no.length);
                else if (is_all_letters(policy_no.substring(0, 1))) suffix = policy_no.substring(1, policy_no.length);
                else suffix = null;

                if (suffix != null && parseInt(suffix) != 0 && (suffix.length == 6 || suffix.length == 9)) {
                    if (suffix.length == 6) validate = true;
                    else if (suffix.length == 9) validate = true;
                }
                else validate = false;
            }
            else validate = false;
        }
        else validate = false;
    }
    return validate;
}

export const check_allowed_special_chars = (value) => {
    if (value != undefined || value != null) {
        var not_allowed_spl_chars = "~!@#$%^&*()_+`={}|[]\\:\";<>?,./";
        for (var i = 0; i < value.length; i++) {
            if (not_allowed_spl_chars.indexOf(value.charAt(i)) != -1) {
                return true;
            }
        }
    }

    return false;
}

export const is_null_or_white_space = (input) => {

    if (typeof input === 'undefined' || input == null) return true;

    return input.toString().replace(/\s/g, '').length < 1;
}

export const is_all_letters = (input) => {

    if (typeof input === 'undefined' || input == null) return false;
    var alpha_betic_regxp = /^[A-z]+$/;
    return alpha_betic_regxp.test(input);
}

export const get_access_token = () => {
    var auth_data = local_storage.get("auth_data");
    if (auth_data) {
        var token = local_storage.get("auth_data").token_details;
        if (token) {
            return token.access_token;
        }
    }
    return "";
}

export const get_html_content_toaster = (msg) => {
    return {
        component: () => (
            <div>
                <div dangerouslySetInnerHTML={{ __html: msg }} />
            </div>
        ), timeOut: global_constants.toastr_options.toastr_time_out,
        preventDuplicates: true
    }
}

export const unformat_zip_code = (zip_code) => {
    var final_zip_code = zip_code;
    if (zip_code) {
        var zip_length = zip_code.length;
        if (zip_code != "" && zip_code != undefined && zip_length > 0) {
            final_zip_code = zip_code.replace('-', '');
        }
    }
    return final_zip_code;
}
export const is_all_numbers = (input) => {

    if (typeof input === 'undefined' || input == null) return false;
    var numeric_regxp = /^[0-9]*$/;
    return numeric_regxp.test(input);
}

/**
 * method returns if the running environment is test environment.
 * */
export const is_testing = () => {
    return process.env.NODE_ENV == 'test'
}

export const camelize = (str) => {
    return str.replace(/\W+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

// Hide Modal on print action
export const modal_hide_on_print = (data) => {
    if (document.getElementById("printElement")) {
        document.getElementById("printElement").remove();
        document.body.style.overflow = null
    }
        
    let printElement: any = document.createElement("div");
    let modal = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>
    let draggableModal = document.getElementsByClassName('react-draggable') as HTMLCollectionOf<HTMLElement>
    document.body.appendChild(printElement);
    document.body.setAttribute('style', 'overflow:visible!important');
    printElement.setAttribute("id", "printElement");
    printElement.innerHTML = data;
    
    for (let i = 0; i < modal.length; i++) {
      modal[i].setAttribute("style", "display:none!important");
      if (draggableModal && draggableModal.length > 0) {
        addClass(draggableModal[i], "hide_on_print");
      }
    }
}

// Show Modal after print action completed
export const modal_show_after_print = () => {
    let print_report_button = document.getElementById('printButton');
    let printElement = document.getElementById('printElement');
    let modal = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>
    let draggableModal = document.getElementsByClassName('react-draggable') as HTMLCollectionOf<HTMLElement>
    let ua = window.navigator.userAgent;

    if (ua.indexOf("Trident/7.0") > -1) {
        printElement &&  document.body.removeChild(printElement);
    } else {
        printElement && printElement.remove();
    }
    document.body.style.overflow = null;
    print_report_button.blur();
      
    for (var i = 0; i < modal.length; i++) {
      modal[i].removeAttribute("style");
      if (draggableModal && draggableModal.length > 0) {
        removeClass(draggableModal[i], "hide_on_print");
      }
    }
}

// Show Modal after print action completed -- IOS Device
export const modal_show_after_print_ios = () => {
    let print_report_button = document.getElementById('printButton');
    let printElement = document.getElementById('printElement');
    let modal = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>
    let draggableModal = document.getElementsByClassName('react-draggable') as HTMLCollectionOf<HTMLElement>
    document.body.style.overflow = null;
    printElement &&  printElement.remove();
    print_report_button.blur();
    
    for (var i = 0; i < modal.length; i++) {
      modal[i].removeAttribute("style");
      if (draggableModal && draggableModal.length > 0) {
        removeClass(draggableModal[i], "hidden");
        removeClass(draggableModal[i], "print");
      }
    }
}

/**
 * Detect Mac Safari
 */
export const is_mac_safari = () => {
    const ua = navigator.userAgent.toLowerCase();
    var is_mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    //var is_mac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
    if (is_mac && ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
        return true;
    }
    return false;
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export const detect_ie = () => {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

export const detect_ie_11 = () => {
    let is_ie = detect_ie();
    if ( is_ie && is_ie < 12 ) {
        return true;
    }
    return false;
}

/*
 * purpose of this method is to open new tab
 * without losing the current session of the user
 */
export const open_new_tab = (dashboard_ff_enable:false) => {
    // should only open open tabs must be less than maximum number of tab count
    if (can_we_open_new_tab()) {

        let newWindowObject: any;
        let url = dashboard_ff_enable ? "/dashboard":"/patient_search";
        var isInternetExplorer = detect_ie();
        local_storage.remove("wait_for_master_data_update");

        if (!isInternetExplorer) {
            // opens up a new tab of patient search page
            newWindowObject = window.open(url, "_blank");
        } else {
            newWindowObject = window;
        }

        let windowSessionStorage : any = { ...newWindowObject.sessionStorage };

        let sessionId = window.sessionStorage["sessionId"];
        let sso_user = window.sessionStorage['sso_user_login'];
        newWindowObject.sessionStorage.clear();
        newWindowObject.sessionStorage.is_main_window = false;
        newWindowObject.sessionStorage.sessionId = sessionId;
        if (sso_user) {
            newWindowObject.sessionStorage.sso_user_login = true;
        }

        if (isInternetExplorer) {
            newWindowObject.open(url, '_blank');
            window.sessionStorage.clear();
            Object.keys(windowSessionStorage).forEach(function (key) {
                window.sessionStorage[key] = windowSessionStorage[key];
            });
        }
    }
}


export const  listOfCookiesOnTabId = () => {
    var theCookies = document.cookie.split(';');
    var keyCount = 0;
    for (var i = 1; i <= theCookies.length; i++) {
        var arr = theCookies[i - 1].toString().split('=');
        var key = arr[0].toString().trim();
        var value = arr[1].toString().trim().substring(0, arr[1].length - 1);

        if (key.substring(0, 5) == 'tabId') {
            if (value.toString() == sessionStorage["sessionId"]) {
                keyCount++;
            }
        }
    }

    return keyCount;
}

export const can_we_open_new_tab = () => {

    let open_tab_count = 0;

    var isInternetExplorer = detect_ie();

    // for IE browser/ and their all versions we are picking up tabIds from cookies
    if (isInternetExplorer) {
        open_tab_count = parseInt(local_storage.get("open_tab_count"))
    } else {
        // fetch open_tab_count from sum of tabId in localStorage
        open_tab_count = local_storage.keyCountUsingKeyNameMatch("tabId");
    }

    // get max tab count
    let max_tab_count = global_constants.MAX_TAB_COUNT; 

    return open_tab_count < max_tab_count;
}
export const remove_tab_id = () => {
    var tabId = session_storage.get_raw("tabId");
    localStorage.removeItem('tabId' + tabId);
    session_storage.remove('tabId');

    // for all IE browsers we are deleting tabid cookie
    if (detect_ie()) {
        var name = 'tabId' + tabId;
        delete_cookie(name);
    }
}

export const event_listener_register_on_unload = function(e){
    var tabId = session_storage.get_raw("tabId");
    localStorage.removeItem('tabId' + tabId);
    session_storage.remove('tabId');

    // for all IE browsers we are deleting tabid cookie
    if (detect_ie()) {
        var name = 'tabId' + tabId;
        window.removeEventListener(before_unload_event_name(), event_listener_register_on_unload,true);
        var count = parseInt(local_storage.get("open_tab_count"));
        if(count > 0){
            local_storage.set("open_tab_count", count - 1);
        }
        delete_cookie(name);
    }
};
export const before_unload_event_name = function(){ 
    var isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    var eventName = isOnIOS ? "pagehide" : "beforeunload";
    return eventName;
}

/*
 * Purpose of this function is to bind an event i.e beforeunload that could listen to 
 * close of tab event, so that localstorage could be cleared for that tab, also garbage shouldnot 
 * be remain in client browser when application is closed by him.
 * */
export const handle_event_on_closing_tab = () => {
    window.addEventListener(before_unload_event_name(), event_listener_register_on_unload);
};

export const setup_tabId_in_localStorage = () => {
    let tabId: any = session_storage.get_raw("tabId");
    let sessionId: any = session_storage.get_raw("sessionId");

    if ( ! localStorage.getItem('tabId' + tabId)) {
        // setting the tab id in local storage 
        localStorage.setItem('tabId' + tabId, sessionId);

        // for all IE browsers setting up tabid in cookie
        if (detect_ie()) {
            var name = 'tabId' + tabId;
            document.cookie = name + "=" + sessionId + 0 + "; path=/";
        }
    }
}

export const delete_cookie = (name) => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const check_browser = () => {
    let c = navigator.userAgent.search("Chrome");
    let f = navigator.userAgent.search("Firefox");
    let m8 = navigator.userAgent.search("MSIE 8.0");
    let m9 = navigator.userAgent.search("MSIE 9.0");
    let browser;

    if (c > -1) {
        browser = "Chrome";
    } else if (f > -1) {
        browser = "Firefox";
    } else if (m9 > -1) {
        browser = "MSIE 9.0";
    } else if (m8 > -1) {
        browser = "MSIE 8.0";
    }
    return browser;
}

export const is_firefox = () => {
    return check_browser() == "Firefox";
}

export const element_closest = (window) => {
    const ElementPrototype = window.Element.prototype;

    if (typeof ElementPrototype.matches !== 'function') {
        ElementPrototype.matches = ElementPrototype.msMatchesSelector || ElementPrototype.mozMatchesSelector || ElementPrototype.webkitMatchesSelector || function matches(selector) {
            let element = this;
            const elements = (element.document || element.ownerDocument).querySelectorAll(selector);
            let index = 0;

            while (elements[index] && elements[index] !== element) {
                ++index;
            }

            return Boolean(elements[index]);
        };
    }

    if (typeof ElementPrototype.closest !== 'function') {
        ElementPrototype.closest = function closest(selector) {
            let element = this;

            while (element && element.nodeType === 1) {
                if (element.matches(selector)) {
                    return element;
                }

                element = element.parentNode;
            }

            return null;
        };
    }
}

// function used to caluculate max width of the string in given column
export const get_text_max_width:any = (rows, property_name = null, font = "16px ProximaNova-Regular") => {
    var canvas = get_text_max_width.canvas || (get_text_max_width.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var maxLength = 0;
    if (rows) {
        if (property_name) {
            rows.map((item, idx) => {
                var text = item[property_name];
                let matrics = context.measureText(text)
                maxLength = maxLength < matrics.width ? matrics.width : maxLength
            })
        } else {
            rows.map((item, idx) => {
                let matrics = context.measureText(item)
                maxLength = maxLength < matrics.width ? matrics.width : maxLength
            })
        }
    }
    // canvas.remove();
    // adding addition some pixels to give space from edge of columns
    return maxLength + 25;
}

export const set_column_data_width = (rows, columns) => {
    let total_column_width = 0;
    columns.map((data) => {
        var max_len_text = get_text_max_width(rows, data['field'])
        let header_max_text_len = get_header_max_text(data.headerName)
        data.width = (max_len_text > header_max_text_len && max_len_text > data.minWidth ) ? max_len_text : data.minWidth;
        total_column_width += data.width;
    })
    return total_column_width;
};

export const set_column_data_width_ledger_visit = (rows, columns) => {
    let total_column_width = 0;
    var max_len_text= 0
    columns.map((data) => {
        if(data['field'] == 'stringCol1'){
            max_len_text = 140
            let header_max_text_len = 140
            data.width = (max_len_text > header_max_text_len && max_len_text > data.minWidth ) ? max_len_text : data.minWidth;
            total_column_width += data.width;
        }else{
            max_len_text = get_text_max_width(rows, data['field'])
            let header_max_text_len = get_header_max_text(data.headerName)
            data.width = (max_len_text > header_max_text_len && max_len_text > data.minWidth ) ? max_len_text : data.minWidth;
            total_column_width += data.width;
        }
    })
    return total_column_width;
};

export const get_header_max_text = header => {
    let header_array = header.split('<br>');
    return get_text_max_width(header_array);
};

/**
 * Grid column wrapping Start
 */
export const strip = (value) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText || "";
}

// function used to caluculate max width of the string in given column
export const get_containt_width:any = (rows, column = null, font = "16px ProximaNova-Regular") => {
    var canvas = get_containt_width.canvas || (get_containt_width.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var maxLength = 0;
    if (column) {
        if (column.type && column.type === 'hyper_link') {
            rows.map((item, idx) => {
                let text = strip(item[column.field]);
                let matrics = context.measureText(text)
                maxLength = maxLength < matrics.width ? matrics.width : maxLength
            })
        } else {
            rows.map((item, idx) => {
                let text = strip(item[column.field]);
                let matrics = context.measureText(text)
                maxLength = maxLength < matrics.width ? matrics.width : maxLength
            });
        }
    } else {
        rows.map((item, idx) => {
            let matrics = context.measureText(item)
            maxLength = maxLength < matrics.width ? matrics.width : maxLength
        })
    }
    // canvas.remove();
    // adding addition some pixels to give space from edge of columns
    return maxLength + 25;
}

const UI_WIDTH_FILTER_SORT = 38;// Additional space used for filter icon, sort icon
export const get_columns = (rows, columns, footer_row = null) => {
    let total_column_width = 0;
    let header_height = 35;
    let footer = footer_row ? get_footer_col_width(footer_row, columns) : {};

    columns.map(data => {
      if(!data.hide){
        var max_len_text = get_containt_width(rows, data);
        if (footer_row) {
            let footer_width = footer[data.field] || 0;
            max_len_text = max_len_text > footer_width ? max_len_text : footer_width;
        }
        let header_words = data.headerName ? data.headerName.split(' ') : [];
        let header_max_text_len = get_containt_width(header_words) + 38;
        if (data.maxWidth) { // Column max-width should not exceed if specified.
            max_len_text = data.maxWidth < max_len_text ? data.maxWidth : max_len_text;
            header_max_text_len = data.maxWidth < header_max_text_len ? data.maxWidth : header_max_text_len;
        }
        data.minWidth = header_max_text_len;
        data.width = max_len_text > header_max_text_len ? max_len_text : header_max_text_len;
        total_column_width += data.width;
        let height = (header_words.length - 1) * 10 + 32;
        header_height = height > header_height ? height : header_height;
      }
    });
    return { total_column_width, header_height };
};

export const get_footer_col_width = (row, columns) => {
    let footer = {};
    columns.map((col) => {
      if(!col.hide){
        footer[col.field] = get_footer_content_width(row[col.field])
      }
    });
    return footer;
}

export const get_footer_content_width:any = (text='', font = "16px ProximaNova-Regular") => {
    var canvas = get_footer_content_width.canvas || (get_footer_content_width.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;    
    let matrics = context.measureText(text)
    return matrics.width+25;
}
/**
 * Grid column wrapping END
 */

// to verify the image format
export const loadMime = (file, callback) => {
    //List of known mimes
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xff, 0xd8, 0xff],
            mask: [0xff, 0xff, 0xff]
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4e, 0x47],
            mask: [0xff, 0xff, 0xff, 0xff]
        },
        {
            mime: 'image/gif',
            pattern: [0x47, 0x49, 0x46, 0x38],
            mask: [0xff, 0xff, 0xff, 0xff]
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];
    function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file
    var reader = new FileReader();

    reader.onloadend = (e: any) => {
        if (e.target.readyState === FileReader.DONE) {
            var bytes = new Uint8Array(e.target.result);
            for (var i = 0, l = mimes.length; i < l; ++i) {
                if (check(bytes, mimes[i]))
                    return callback(
                        'Mime: ' + mimes[i].mime + ' <br> Browser:' + file.type,
                        true,
                        `data:${mimes[i].mime};base64`,
                        file
                    );
            }
            return callback('Mime: unknown <br> Browser:' + file.type, false, 'unknown', file);
        }
    };
    reader.readAsArrayBuffer(blob);
};
export const trim_nested_json_obj_values = (obj) => {
    if (!Array.isArray(obj) && typeof obj != 'object') return obj;
    var new_obj =
        Object.keys(obj).reduce(function (acc, key) {
            if (obj[key] == null || moment.isMoment(obj[key]) || Object.prototype.toString.call(obj[key]) === '[object Date]') {
                acc[key] = obj[key];
            }          
            else if (typeof obj[key] == 'object') {
                acc[key] = trim_nested_json_obj_values(obj[key])
            }
            else {
            acc[key] = typeof obj[key] == 'string' ? obj[key].trim() : obj[key];
            }          
            return acc;
        }, Array.isArray(obj) ? [] : {});
    return new_obj;    
}


// Charge Audit Summary internel table
// function used to caluculate max width of the string in given column
export const charge_get_containt_width = (rows, column = null, font = "16px ProximaNova-Regular") => {
    var canvas = get_containt_width.canvas || (get_containt_width.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var maxLength = 0;
    if (column) {
            rows.map((item, idx) => {
                let text = item[column.field];
                let matrics = context.measureText(text)
                maxLength = maxLength < matrics.width ? matrics.width : maxLength
            });
    } else {
        rows.map((item, idx) => {
            let matrics = context.measureText(item)
            maxLength = maxLength < matrics.width ? matrics.width : maxLength
        })
    }
    return maxLength + 11;
}

export const charge_get_columns = (rows, columns) => {
  let total_column_width = 0;
  let header_height = 35;
  columns.map(data => {
    var max_len_text = charge_get_containt_width(rows, data);
    let header_words = data.headerName ? data.headerName.split(' ') : [];
    let header_max_text_len = charge_get_containt_width(header_words);
    data.minWidth = Math.round(header_max_text_len);
    data.width = max_len_text > header_max_text_len ? Math.round(max_len_text) : Math.round(header_max_text_len);
    total_column_width += data.width;
    let height = (header_words.length - 1) * 5 + 32;
    header_height = height > header_height ? height : header_height;
  });
  return { total_column_width, header_height };
};

export const is_safari = () => {
  
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

    return isSafari;    
}

export const isSafariMobileDevice = () => {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    return iOSSafari;
}
export const execute_print = () => {

    if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
        if(isSafariMobileDevice()) {
            document.execCommand('print')
        } else {
            window.print();
        }
    } else {
        if(is_safari()) {
            document.execCommand('print')

        } else {
            window.print();
        }
    }   
}

export const try_parse_float = (str, defaultValue = 0.00, default_decimal_places=2) => {
    var retValue = defaultValue;
    if (str != null && str != '' && str != undefined) {
        if (typeof str === "string" && str.indexOf(',') > -1) {
            str = str.replace(/,/g, '');
        }
        if (!isNaN(str)) {
            retValue = parseFloat(parseFloat(str).toFixed(default_decimal_places));
        }
    }
    return retValue;
}

export const try_parse_currency_float = (str, defaultValue = 0.00, default_decimal_places=2) => {
    if (str && str.indexOf('$') > -1) {
        str = str.replace('$', '');
    }

    return try_parse_float(str, defaultValue, default_decimal_places);
}

export const set_order_data = (data, prop) => {
        let prop_value = prop;
        data = data.sort((obj1, obj2) => {
            var nameA = obj1[prop_value].toLowerCase(),
                nameB = obj2[prop_value].toLowerCase();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        return data;
}

export const get_lauch_darkley_key_value = (launch_darkly_state, key) => {

    if (!global_constants.launch_darkley.use_ld_feature) {
        return global_constants.launch_darkley.default_ld_value;
    }
    return launch_darkly_state[key];

}

// get sso token value from window wrl based
export const get_sso_token_value_from_url = (url_string,key) => {
    let token_value = null;
    if (url_string && key) {
        if (detect_ie) {
            if (url_string.indexOf(key) > -1) {
                let arr = url_string.split("=");
                if (arr.length==2) {
                    token_value = arr[1];
                }
            }
        } else {
            let url = new URL(url_string);
            token_value = url.searchParams ? url.searchParams.get(key) : null;
        }
    }
    return token_value;
}

// Print HTML Data
export const print_html_data_to_pdf = (
  print_content,
  print_button_id = 'print_report_button',
  report_name = 'WebPT Billing'
) => {
  let printElement: any = document.createElement('div');
  let print_button = document.getElementById(print_button_id);
  let ua = window.navigator.userAgent;
  let doc_title = document.title;

  if (document.getElementById('printElement')) {
    document.getElementById('printElement').remove();
    document.body.style.overflow = null;
  }
  printElement.setAttribute('id', 'printElement');
  printElement.innerHTML = print_content;
  document.body.setAttribute('style', 'overflow:visible!important');
  document.body.appendChild(printElement);
  hide_modals_on_print();

  // Print functionlity start
  if (window.print) {
    document.title = report_name;
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      setTimeout(() => {
        execute_print();
      }, 100);

      setTimeout(() => {
        document.body.style.overflow = null;
        printElement.remove();
        print_button.blur();
        show_modals_after_print();
        document.title = doc_title;
      }, 4000);
    } else {
      if (window.matchMedia) {
        let mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function (mql) {
          if (mql.matches) {
          } else {
            afterPrint();
          }
        });
      }
      let afterPrint = () => {
        if (ua.indexOf('Trident/7.0') > -1) {
          document.body.removeChild(printElement);
        } else {
          printElement.remove();
        }
        document.body.style.overflow = null;
        print_button.blur();
        show_modals_after_print();
        document.title = doc_title;
      };
      // These are for Firefox
      window.onafterprint = afterPrint;
      execute_print();
    }
  }
};


// Hide Modal on print action
const hide_modals_on_print = () => {
    let draggableModalDimmer = document.getElementsByClassName('super-dimmer') as HTMLCollectionOf<HTMLElement>;
    let draggableModal = document.getElementsByClassName('react-draggable') as HTMLCollectionOf<HTMLElement>;
    let modal = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>;
    let drawer = document.getElementsByClassName('bp3-portal') as HTMLCollectionOf<HTMLElement>; //drawer

    if (draggableModalDimmer && draggableModalDimmer.length > 0) {
        if (draggableModalDimmer && draggableModalDimmer.length > 0) {
            addClass(draggableModalDimmer[0], 'hide_on_print');
        }
    } else {
        if (draggableModal && draggableModal.length > 0) {
            for (let i = 0; i < draggableModal.length; i++) {
                addClass(draggableModal[i], 'hide_on_print');
            }
        }
    }
    if (modal && modal.length > 0) {
        for (let i = 0; i < modal.length; i++) {
            modal[i].setAttribute('style', 'display:none!important');
        }
    }
    if (drawer && drawer.length > 0) {
        for (let i = 0; i < drawer.length; i++) {
            drawer[i].setAttribute('style', 'display:none!important');
        }
    }
};

// Show Modal after print action completed
const show_modals_after_print = () => {
    let draggableModalDimmer = document.getElementsByClassName('super-dimmer') as HTMLCollectionOf<HTMLElement>;
    let draggableModal = document.getElementsByClassName('react-draggable') as HTMLCollectionOf<HTMLElement>;
    let modal = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>;
    let drawer = document.getElementsByClassName('bp3-portal') as HTMLCollectionOf<HTMLElement>; //drawer

    if (draggableModalDimmer && draggableModalDimmer.length > 0) {
        if (draggableModalDimmer && draggableModalDimmer.length > 0) {
            removeClass(draggableModalDimmer[0], 'hide_on_print');
        }
    } else {
        if (draggableModal && draggableModal.length > 0) {
            for (let i = 0; i < draggableModal.length; i++) {
                removeClass(draggableModal[i], 'hide_on_print');
            }
        }
    }
    if (modal && modal.length > 0) {
        for (let i = 0; i < modal.length; i++) {
            modal[i].removeAttribute('style');
        }
    }
    if (drawer && drawer.length > 0) {
        for (let i = 0; i < drawer.length; i++) {
            drawer[i].removeAttribute('style');
        }
    }
};

export const update_company_details = (new_company_data, run_close_chat=true) => {
    const old_user_data = local_storage.get("auth_data");
    console.log("Start update_company_details, refreshing token,==auth data =>", old_user_data);

    if (old_user_data) {
        let auth_data = { ...old_user_data };
        auth_data.company_id = new_company_data.id;
        auth_data.company_name = new_company_data.name;
        auth_data.gpms_code = new_company_data.gpms_code;
        auth_data.accrual_accounting = new_company_data.accrual_accounting;
        auth_data.company_service_type = new_company_data.company_service_type;
        auth_data.email_required = new_company_data.email_required ? new_company_data.email_required : false;
        auth_data.open_month = new_company_data.open_month;
        auth_data.company_tax_id = new_company_data.company_tax_id;
        auth_data.company_contact = new_company_data.company_contact;
        auth_data.company_email = new_company_data.company_email;
        auth_data.phone = new_company_data.phone;
        auth_data.hub_location = new_company_data.hub_location;
        auth_data.company_type_id = new_company_data.company_type_id;
        auth_data.statement_header_info = new_company_data.statement_header_info;
        auth_data.clearing_house_website = new_company_data.clearing_house_website;
        auth_data.allow_live_chat = new_company_data.allow_live_chat;
        local_storage.set("auth_data", auth_data);
        console.log("END update_company_details, refreshing token auth data =>", auth_data);
	}
	if (run_close_chat) {
		close_chat();
	}
    LaunchDarkley.update();
    pendoInit();
    local_storage.set('should_queue_request', 'true');
   
}

export const is_user_partner_customer_and_company_revEquip_revServe = (company_service_type, current_user_type) => {
    return ((company_service_type == global_constants.CompanyServiceType.RevEquip || company_service_type == global_constants.CompanyServiceType.RevServ) && (current_user_type == global_constants.enum_user_type_r3_id.Partner
            || current_user_type == global_constants.enum_user_type_r3_id.Customer))
};

export const close_chat = () => {
    if (document.querySelector('#chatFrame')
        && document.querySelector('#chatFrame')["contentWindow"]
        && document.querySelector('#chatFrame')["contentWindow"]['embedded_svc']
        && document.querySelector('#chatFrame')["contentWindow"]['embedded_svc']['liveAgentAPI']) {
        //if (document.querySelector('#chatFrame')["contentWindow"].document.querySelector('.embeddedServiceSidebar')) {            
           document.querySelector('#chatFrame')["contentWindow"]['embedded_svc']['liveAgentAPI'].endChat();
           document.querySelector('#chatFrame')["contentWindow"]['embedded_svc']['liveAgentAPI'].clearSession();
        //}
    }
}

export const option_formater_for_multiple_column = (
    array,
    text1,
    text2,
    value,
    add_blank = false,
    sorted = true,
    blank_text = ' '
) => {
    // let options = array
    let options = array.map(item => {
        return {
            text: item[text1] + ' - ' + item[text2],
            value: item[value]
        };
    });

    if (sorted) {
        options = options.sort(function (obj1, obj2) {
            var nameA = obj1.text.toLowerCase(),
                nameB = obj2.text.toLowerCase();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    }

    if (add_blank) {
        options.unshift({
            text: blank_text,
            value: ' '
        });
    }
    return options;
};

const format_singleDate = (index) => {
    if (index == 0) return "";
    else{
        if (index > 3 && index < 21) return index + 'th';
        else {
            switch (index % 10) {
                case 1:
                    return index + 'st';
                case 2:
                    return index + 'nd';
                case 3:
                    return index + 'rd';
                default:
                    return index + 'th';
            }
        } 
    }
}

export const format_dates = () => {
    let formattedDateList = [];
    for (var index = 0; index <= 31; index++) {
      let val_obj = {
        text: format_singleDate(index),
        value: index,
        key: index
      };
      formattedDateList.push(val_obj);
    }
    return formattedDateList;
 };

 export const create_endpoint = (global_base, end_points:string[])=> {
    end_points = end_points.map(item=>{
        return global_constants.end_points[global_base][item];
     })
     end_points.unshift(global_constants.base_end_points);
     return end_points.join('/');
 }

 export const check_object_properties_not_empty = (obj) => {
   for (var key in obj) {
     if (obj[key]) return true;
   }
   return false;
};

// #region Toaster html content Helper methods
export const show_html_content_toaster = (msg: string) => {

    return {
        component: () => (
            <div>
                <div dangerouslySetInnerHTML={{ __html: msg }} />
            </div>
        ),
        timeOut: global_constants.toastr_options.toastr_time_out,
        preventDuplicates: true
    };
}
// #endregion Toaster Helper methods

export const clear_sotrage_after_company_change_on_main_page = () => {
    //window.location.reload();
    window.setTimeout(() => { 
        if (local_storage.get('company_change_on_same_tab')) {
            local_storage.remove('company_change_on_same_tab')
            //local_storage.remove("wait_for_master_data_update");
            local_storage.remove('report_search_criteria');
            const report_navigation = local_storage.get('report_navigation');
            if (report_navigation) {
                let patient_id = local_storage.get('report_navigation_active_patient');
                const report_search_criteria = local_storage.get('report_search_criteria')
                if (report_search_criteria) {
                    session_storage.set('report_search_criteria', report_search_criteria);
                    local_storage.remove('report_search_criteria');
                }
                if (patient_id) {
                    session_storage.set('active_patient', patient_id);
					if(this && this.props && this.props.set_active_patient){
                        this.props.set_active_patient(patient_id)
					}
                    local_storage.remove('report_navigation_active_patient')
                }
                session_storage.set('patient_criteria', '');
                local_storage.remove('report_navigation');
            }
        }
    }, 100)
}

export const remove_duplicates_from_array = (input: any[]) => {
    let inputArray = input.concat();

    for(var i=0; i<inputArray.length; ++i) {
        for(var j=i+1; j<inputArray.length; ++j) {
            if(inputArray[i] === inputArray[j])
                inputArray.splice(j--, 1);
        }
    }

    return inputArray;
};
export const convert_db_datetime_to_local_string = (date: string) => {
	//date is ALWAYS pacific in db, convert to local via utc
	let dbDateString = moment(date).format("MM/DD/YYYY") + " " + moment(date).format("hh:mm:ss A");

	//console.log('=== New Date Conversion ===');
	//console.log('DB Time ' + dbDateString);

	// just use PST only for now
	return dbDateString;

	let pstDateString = moment(dbDateString).format("YYYY-MM-DD") + "T" + moment(dbDateString).format("HH:mm:ss-0700")
	console.log('Pacific Time (America/Los_Angeles) ' + pstDateString);
	let localDate = new Date(pstDateString);
	let localDateString = moment(localDate).format("MM/DD/YYYY") + " " + moment(localDate).format("hh:mm:ss A");
	let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	console.log('Local Time (' + tz + ") " + localDateString);
	return localDateString;
};

export const format_AMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const is_valid_int32 = (input) => {
    return !is_null_or_white_space(input)
    && is_all_numbers(input)
    && input >= -2147483648 && input <= 2147483648;
};

export const remove_expired_session_data = () => {
    for (const key in localStorage) {
        if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
            if (key.startsWith('auth_data')) {
                const is_json_string = isJsonString(localStorage.getItem(key));
                if (is_json_string) {
                    localStorage.clear();
                } else {
                    const sessionId = key.substring(9, key.length);
                    const decompressedValue = JSON.parse(decompressFromUTF16(localStorage.getItem(key)));
                    if (
                        decompressedValue['token_details'] &&
                        decompressedValue['token_details']['expires_time'] &&
                        moment(decompressedValue['token_details']['expires_time']) &&
                        moment(decompressedValue['token_details']['expires_time']).add(1, 'm') < moment()
                    ) {
                        localStorage.removeItem(`auth_data${sessionId}`);
                        localStorage.removeItem(`open_tab_count${sessionId}`);
                        localStorage.removeItem(`persist:root${sessionId}`);
                    }
                }
            }
        }
    }
};

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const setDefaultAuthData = ()=> {
    const data = {
        token_details: {
            access_token: '',
            expires_in: 900,
            refresh_token: '',
            token_guid: '',
            expires_time: new Date().toISOString()
        },
        session_details: {
            result: true,
            session_id: null,
            message: null
        }
    };
    local_storage.set(`auth_data`, data);
    return;
}

export const toBoolean = (value: any)=> {
    if (value != null || value != undefined) {
        return value == true || value == 1 || value == 'true';
    }

    return value;
};

export const nullifyString = (value: string)=> {
    return value == undefined || is_null_or_white_space(value)
        ? null
        : value;
};

export const getMomentAtStartOfDay = (value: any) => {
    let date = moment(value);
    date.set({hour:0,minute:0,second:0,millisecond:0});
    date.format('YYYY-MM-DDTHH:mm:ss');

    return date;
};

export const getMomentAtEndOfDay = (value: any) => {
    let date = moment(value);
    date.set({hour:23,minute:59,second:59,millisecond:0});
    date.format('YYYY-MM-DDTHH:mm:ss');

    return date;
};

/**
 * Checks the files are of certain type
 * @param event upload event containing files (input type="file")
 * @param types 
 * @returns string[] error list if any
 */
export const isSupportedMimeType = (event: any, types: string[]) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // loop access array
    for (var x = 0; x < files.length; x++) {
        // compare file type find doesn't matach
        if (types && Array.isArray(types) && types.length > 0 && types.every(type => files[x].type !== type)) {
            // create error message and assign to container
            err[x] = files[x].type + ' is not a supported format\n';
        }
    };

    for (var z = 0; z < err.length; z++) {// if message not same old that mean has error
        event.target.value = null;
    }
    
    return err;
};

/**
 * Checks an upload event files and returns true if the files are not bigger than maxSize
 * @param file
 * @param checkEmptyFile if true, checks if the file is empty 
 * @param maxSize expressed in Mb
 * @returns true if the files are not bigger than maxSize
 */
export const isFilesizeWithinLimits = (file: File, checkEmptyFile: boolean, maxSize: number) => {
    let filesize = file.size,
        filesizeInMb = filesize / 1024 / 1024,
        isEmptyFile = checkEmptyFile && filesize === 0,
        isOverMaxSize = filesizeInMb > maxSize;

    if (isEmptyFile || isOverMaxSize) {
        return false;
    }

    return true;
};

export const areStringsEqualCaseInsensitive = (str1: string, str2: string) => {
    if (str1 === null || str2 === null) {
        return false;
    }

    return str1.toLowerCase() === str2.toLowerCase();
};
